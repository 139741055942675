import { useEffect } from 'react';

import Datafeed from './api/datafeed.js';
import './index.css';

// https://github.com/sonnh58/TradingView/wiki/Widget-Constructor
interface TVChartContainerProps {
  symbol?: string;
  interval?: string;
  fullscreen?: boolean;
  container?: string;
  datafeed?: any;
  library_path?: string;

  debug?: boolean;
  locale?: string;
  disabled_features?: string[];
  enabled_features?: string[];
  charts_storage_url?: string;
  charts_storage_api_version?: string;
  client_id?: string;
  user_id?: string;
  autosize?: boolean;
  studies_overrides?: any;
  overrides?: any;
  timeframe?: any;
  toolbar_bg?: string;
  favorites?: any;
  time_frames?: any;
  theme?: 'Light' | 'Dark';
}

const defaultProps: TVChartContainerProps = {
  // samples
  //symbol: 'Coinbase:BTC/USD',
  //symbol: 'Bitstamp:BTC/USD',
  //symbol: 'Bitfinex:BTC/USD',
  symbol: 'Bitstamp:BTC/USD',
  interval: '1D',
  fullscreen: false,
  container: 'tv_chart_container',
  datafeed: Datafeed,
  library_path: '/charting_library/',
  timeframe: '30D',
  toolbar_bg: '#2a2e39',
  time_frames: [
    { text: '1y', resolution: '12M', description: '1 Year' },
    { text: '6m', resolution: '6M', description: '6 Months' },
    { text: '3m', resolution: '3M', description: '3 Months' },
    { text: '1m', resolution: '1M', description: '1 Month' },
    { text: '7d', resolution: '7D', description: '1 Week' },
    { text: '1d', resolution: '1D', description: '1 Day' },
    { text: '15min', resolution: '15', description: '1 Min' },
  ],
  theme: 'Dark',

  debug: false,
  locale: 'en',
  disabled_features: ['use_localstorage_for_settings'],
  enabled_features: ['study_templates'],
  charts_storage_url: 'https://saveload.tradingview.com',
  charts_storage_api_version: '1.1',
  client_id: 'tradingview.com',
  user_id: 'public_user_id',
  autosize: true,
  studies_overrides: {},

  overrides: {
    'paneProperties.background': '#131722',
    'paneProperties.vertGridProperties.color': '#363c4e',
    'paneProperties.horzGridProperties.color': '#363c4e',
    'symbolWatermarkProperties.transparency': 90,
    'scalesProperties.textColor': '#AAA',
    'mainSeriesProperties.candleStyle.wickUpColor': '#336854',
    'mainSeriesProperties.candleStyle.wickDownColor': '#7f323f',
  },
};

export const TVChartContainer = (props: TVChartContainerProps) => {
  useEffect(() => {
    //@ts-ignore
    const widget = (window.tvWidget = new window.TradingView.widget({
      ...defaultProps,
      ...props,
    }));

    widget.onChartReady(() => {
      console.log('Chart has loaded!');
    });
  }, [props]);

  return (
    <div
      id={props.container || defaultProps.container}
      className={'TVChartContainer'}
    />
  );
};
