import React, { useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { BN } from 'utils/bigNumber';
import { block } from 'utils/classname';

import { useAccount } from 'hooks/useAccount';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import { Widget, Button, CurrencyInput } from 'components';

import { ReceiveSUSDNotes } from '../ReceiveSUSDNotes';
import { useFormState } from './useForm';

import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('recieve-susd-form');

function Form() {
  const { enqueueSnackbar } = useSnackbar();
  const { connect, areRequestsLocked } = useAccount();
  const { busdAPI } = useWeb3();
  const { addTransaction } = useTransaction();
  const {
    isDirty,
    isValid,
    errors,
    amount,
    defaultAmount,
    handleAmountChange,
    clearAmount,
    createSubmitHandler,
  } = useFormState();

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleFormSubmit = createSubmitHandler(() => {
    if (areRequestsLocked) {
      connect();
    } else {
      Promise.resolve()
        .then(() => setSubmitting(true))
        .then(() =>
          amount
            ? receiveSUSD(amount)
            : new Promise((_, reject) =>
                reject(Error('Amount is not provided')),
              ),
        )
        .then(tx => {
          addTransaction(tx, `Receive BUSD`);
        })
        .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => setSubmitting(false));
    }
  });
  const receiveSUSD = (value: BN) => busdAPI.receiveSUSD(value);

  const amountInputRightPart = useMemo(
    () => <CurrencyInput.Simple currency="busd" />,
    [],
  );

  return (
    <div className={b()}>
      <form onSubmit={handleFormSubmit}>
        <Widget.SubSection>
          <CurrencyInput.Component
            label="Amount"
            value={
              isDirty
                ? amount?.toString() ?? null
                : defaultAmount?.toString() ?? null
            }
            error={isDirty ? errors.amount : undefined}
            onChange={handleAmountChange}
            onClear={clearAmount}
            rightPart={amountInputRightPart}
          />
        </Widget.SubSection>
        <Widget.SubSection>
          <ReceiveSUSDNotes
            list={[
              '1000 BUSD maximum per request',
              '100,000 BUSD maximum per month',
            ]}
          />
        </Widget.SubSection>
        <Widget.SubSection>
          <Button
            variant="success"
            type="submit"
            disabled={isSubmitting || !isValid}
            className={b('submit-button')}
          >
            {isSubmitting ? <CircularProgress size={20} /> : 'Receive BUSD'}
          </Button>
        </Widget.SubSection>
      </form>
    </div>
  );
}

const Component = React.memo(Form);
export { Component as Form };
