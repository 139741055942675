import { Typography } from '@material-ui/core';

import block from 'bem-cn-lite';
import { TwitterFollowButton } from 'react-twitter-embed';

import './style.scss';

const b = block('leverage-yield-farm');

export const LeverageYieldFarm = () => (
  <div className={b()}>
    <Typography variant="h4">Coming soon</Typography>
    <p className={b('follow-link')}>
      Stay up to date: <TwitterFollowButton screenName="StripsFinance" />
    </p>
  </div>
);
