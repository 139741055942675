import { Button, Widget } from 'components';

import block from 'bem-cn-lite';

const b = block('receive-bnb');

export const ReceiveBNBWidget = () => (
  <Widget.Component className={b()}>
    <Widget.SubSection>
      To be able send transactions you need to receive BNB, follow this link:
    </Widget.SubSection>
    <Widget.SubSection>
      <Button variant="success" block onClick={openCurrencyReceiver}>
        Receive BNB
      </Button>
    </Widget.SubSection>
  </Widget.Component>
);

function openCurrencyReceiver() {
  window.open('https://testnet.binance.org/faucet-smart', '_blank');
}
