import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';

const SERVER_URL = 'https://strip-finance.hasura.app/v1/graphql';

const client = new ApolloClient({
  link: new HttpLink({
    uri: SERVER_URL,
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    fetch,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export const GetUser = gql`
  query get_feedbackcontestvw($wallet: String!) {
    feedbackcontestvw(
      where: {
        _or: [{ twallet: { _eq: $wallet } }, { dwallet: { _eq: $wallet } }]
      }
    ) {
      dwallet
      twallet
      twitterhandle
      discordhandle
    }
  }
`;

export const GetIsTwitterHandleUsed = gql`
  query GetIsTwitterHandleUsed($twitterHandle: String!) {
    feedbackcontestvw(where: { twitterhandle: { _eq: $twitterHandle } }) {
      twallet
    }
  }
`;

export const GetIsDiscordHandleUsed = gql`
  query GetIsDiscordHandleUsed($discordHandle: String!) {
    feedbackcontestvw(where: { discordhandle: { _eq: $discordHandle } }) {
      dwallet
    }
  }
`;

export const AddTw = gql`
  mutation insert_feedbackcontesttw($wallet: String!, $twitterHandle: String) {
    insert_feedbackcontesttw(
      objects: { wallet: $wallet, twitterhandle: $twitterHandle }
    ) {
      affected_rows
      returning {
        wallet
      }
    }
  }
`;

export const AddDiscord = gql`
  mutation insert_feedbackcontest(
    $wallet: String!
    $twitterHandle: String
    $discordHandle: String
  ) {
    insert_feedbackcontestdiscord(
      objects: { wallet: $wallet, discordhandle: $discordHandle }
    ) {
      affected_rows
      returning {
        wallet
      }
    }
  }
`;

export const UpdateUser = gql`
  mutation update_feedbackcontest(
    $wallet: String!
    $twitterHandle: String
    $discordHandle: String
  ) {
    update_feedbackcontest(
      _set: {
        wallet: $wallet
        twitterHandle: $twitterHandle
        discordHandle: $discordHandle
      }
      where: { wallet: { _eq: $wallet } }
    ) {
      affected_rows
      returning {
        wallet
        twitterHandle
        discordHandle
      }
    }
  }
`;

export default client;
