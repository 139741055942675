import { useCallback, useEffect, useRef, useState } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { from256, to256, EBNToBN } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';
import { calcGas } from 'utils/gas';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';

import type { SUSD } from '../types';
import { SUSD__factory } from '../types';

import BN from 'bignumber.js';
import { ethers } from 'ethers';

function createContract(address: string, web3: Web3Provider, account: string) {
  return getContract<SUSD>(SUSD__factory, address, web3, account);
}

export function useBusdAPI(
  web3: Web3Provider,
  address: string,
  account: string,
) {
  const { approvedState, isTokenApproved, updateTokenApproved } = useAccount();
  const { showError } = useErrorHandler(web3);
  const saveAccount = useRef(account);

  const [contract, setContract] = useState<SUSD>(
    createContract(address, web3, account),
  );

  useEffect(() => {
    setContract(createContract(address, web3, account));
  }, [web3, address]);

  useEffect(() => {
    saveAccount.current = account;
  }, [account]);

  const approve = useCallback(
    async (spender: string, amount: BN): Promise<string | null> => {
      if (isTokenApproved(saveAccount.current, spender)) {
        return '';
      }
      const gasLimitEstimated = await contract.estimateGas.approve(
        spender,
        ethers.constants.MaxUint256,
      );
      const gasLimit = calcGas(gasLimitEstimated);
      const tx = await contract.approve(spender, ethers.constants.MaxUint256, {
        gasLimit,
      });
      updateTokenApproved(saveAccount.current, spender, true);
      return tx.hash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract, approvedState],
  );

  const receiveSUSD = useCallback(
    async (amount: BN) => {
      const gasLimitEstimated = await contract.estimateGas.receiveSUSD(
        to256(amount).toString(),
      );
      const gasLimit = calcGas(gasLimitEstimated);
      const tx = await contract.receiveSUSD(to256(amount).toString(), {
        gasLimit,
      });
      return tx.hash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const getBalance = useCallback(
    async (account: string) => {
      try {
        const ret = await contract?.balanceOf(account);
        return from256(EBNToBN(ret));
      } catch (error: any) {
        showError(error);
        return new BN(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  return {
    isReady: !!contract.provider,
    approve,
    getBalance,
    receiveSUSD,
  } as const;
}

export type BusdAPI = ReturnType<typeof useBusdAPI>;
