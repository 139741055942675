import { useCallback, useEffect, useRef, useState } from 'react';

import { useAccount } from 'hooks/useAccount';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import VestingClaimedSTRP from './views/VesingClaimedSTRP';
import VestingAvailableSTRP from './views/VestingAvailableSTRP';
import VestingClaimButton from './views/VestingClaimButton';
import VestingDates from './views/VestingDates';
import VestingPendingSTRP from './views/VestingPendingSTRP';
import { IVestingObject } from 'contracts/apiHooks';
import { UserBlock } from 'pages/Layout/Header/UserBlock';

import block from 'bem-cn-lite';

import './style.scss';

const initialVestingObject: IVestingObject = {
  isActive: false,

  nextReleaseDate: '-',
  vestingEndDate: '-',

  strpTotal: -1,
  strpAvailable: -1,
  strpReleased: -1,
};

export const VestingClaimCardClass = 'vesting-claim-card';

export default function VestingClaimCard() {
  const { account, status } = useAccount();
  const { vestingAPI } = useWeb3();

  const { addTransaction } = useTransaction();

  const [vestingData, setVestingData] =
    useState<IVestingObject>(initialVestingObject);
  const [isLoadingVestingData, setIsLoadingVestingData] =
    useState<boolean>(false);

  const saveAccount = useRef(account);

  // On account change load checkVestingAvailable, wait 1ms till saveAccount updates inside vestingAPI
  useEffect(() => {
    if (account && saveAccount.current !== account && vestingAPI.isReady) {
      setIsLoadingVestingData(true);

      if (vestingData !== initialVestingObject) {
        setVestingData(initialVestingObject);
      }

      setTimeout(async () => {
        saveAccount.current = account;
        try {
          await vestingAPI.checkVestingAvailable();

          const response = await vestingAPI.getVestingData(account);

          await setVestingData(response);
          setIsLoadingVestingData(false);
        } catch (err: any) {
          setIsLoadingVestingData(false);
        }
      }, 1);
    }
  }, [account, vestingData, vestingAPI.isReady]);

  const releaseVesting = useCallback(() => {
    setIsLoadingVestingData(true);

    const showTransaction = tx => {
      addTransaction(tx, 'Start vesting release');
    };

    vestingAPI
      .releaseVesting(showTransaction)
      .then(() => {
        const newVestingData: IVestingObject = {
          ...vestingData,
          strpAvailable: 0,
          strpReleased: vestingData?.strpReleased + vestingData?.strpAvailable,
        };
        setVestingData(newVestingData);
      })
      .finally(() => setIsLoadingVestingData(false));
  }, [vestingData]);

  const bem = block(VestingClaimCardClass);
  return (
    <div className={bem()}>
      <div className={bem('preview')}>
        <VestingAvailableSTRP strpAvailable={vestingData?.strpAvailable} />
        {status === 'connected' ? (
          <VestingClaimButton
            isLoading={isLoadingVestingData}
            isVestingEnabled={vestingData.isActive}
            isClaimEnabled={vestingData?.strpAvailable > 0}
            onClaim={releaseVesting}
          />
        ) : (
          <UserBlock />
        )}
      </div>
      <div className={bem('body')}>
        <div className={bem('row', { pending: true })}>
          <VestingPendingSTRP
            strpTotal={vestingData?.strpTotal}
            strpReleased={vestingData?.strpReleased}
          />
          <VestingClaimedSTRP strpReleased={vestingData?.strpReleased} />
        </div>
        <VestingDates
          nextReleaseDate={vestingData?.nextReleaseDate}
          vestingEndDate={vestingData?.vestingEndDate}
        />
      </div>
    </div>
  );
}

export function showVestingValue(value: string | number): string | number {
  if (typeof value === 'number' && value < 0) {
    return '-';
  }
  return value;
}
