import { ChainId } from '../model';
import configurationObject from './config.json';
import featuresObject from './features.json';
import rewardsProofs from './rewardsProofs.json';

import { RateTypes } from 'enums';

export enum ComponentsName {
  Overview,
  ExchangePro,
  FeedbackContest,
  VestingClaim,
}

type Config = {
  chainID: ChainId;
  graphAPIUrl: string;
  nodeUrl: string;
  views: {
    overview: boolean;
    exchange: boolean;
    liquidity: boolean;
    insurance: boolean;
    receiveSUSD: boolean;
    feedbackContest: boolean;
    leverageYieldFarm: boolean;
    farm: boolean;
  };
  network: {
    networkName: string;
    url: string;
    symbol: string;
    explorerUrl: string;
  };
  contracts: {
    susd: string;
    strips: string;
    insurance: string;
    vesting: string;
    merkle: string;
  };
  markets: {
    [address: string]: {
      address: string;
      title: string;
      platform: string;
      assetSymbol: string;
      type: RateTypes;
    };
  };
  features: {
    defaultComponent: ComponentsName;
    showSidebar: boolean;
    enableRewardBuyingWidget: boolean;
    enableClainRewardWidget: boolean;
  };
};

const configuration = getConfigWithLowerCasedIDs(configurationObject);
const features = getConfigWithLowerCasedIDs(featuresObject);

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

// if (typeof CHAIN_ID === 'undefined') {
//   throw new Error(`REACT_APP_CHAIN_ID must be a defined environment variable`);
// }

const chainID = (Number(CHAIN_ID) as ChainId) ?? 1;

export const config = ((): Config => ({
  chainID,
  network: {
    networkName: configuration.network[chainID].networkName,
    url: configuration.network[chainID].url,
    symbol: configuration.network[chainID].symbol,
    explorerUrl: configuration.network[chainID].explorerUrl,
  },
  graphAPIUrl: configuration.graphAPIUrl,
  nodeUrl: configuration.nodeUrl,
  contracts: {
    susd: configuration.contracts.susd[chainID],
    strips: configuration.contracts.strips[chainID],
    insurance: configuration.contracts.insurance[chainID],
    vesting: configuration.contracts.vesting[chainID],
    merkle: configuration.contracts.merkle[chainID],
  },
  markets: configuration.markets[chainID],
  views: {
    insurance: features.views.insurance ?? true,
    liquidity: features.views.liquidity ?? true,
    overview: features.views.overview ?? true,
    exchange: features.views.exchange ?? true,
    receiveSUSD: features.views.receiveSUSD ?? true,
    feedbackContest: features.views.feedbackContest ?? true,
    leverageYieldFarm: features.views.leverageYieldFarm ?? true,
    farm: features.views.farm ?? true,
  },
  features: {
    defaultComponent: features.features.defaultComponent,
    showSidebar: features.features.showSidebar,
    enableRewardBuyingWidget: features.features.enableRewardBuyingWidget,
    enableClainRewardWidget: features.features.enableClainRewardWidget,
  },
}))();

function getConfigWithLowerCasedIDs(configObject: object) {
  const entries: [string, any][] = Object.entries(configObject).map(
    ([key, value]) => {
      const isID = (value: unknown) => {
        return typeof value === 'string' && value.startsWith('0x');
      };
      const newKey = isID(key) ? key.toLowerCase() : key;
      const newValue =
        value && typeof value === 'object'
          ? getConfigWithLowerCasedIDs(value)
          : isID(value)
          ? value.toLowerCase()
          : value;
      return [newKey, newValue];
    },
  );
  return Object.fromEntries(entries);
}

export type RewardProofInfo = {
  account: string;
  total: string;
  index: number;
  proof: string[];
};
export const getRewardsProof = (
  account: string,
): RewardProofInfo | undefined => {
  return rewardsProofs.find(
    r => r.account.toLowerCase() === account.toLowerCase(),
  );
};
