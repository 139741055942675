import {
  faExchangeAlt,
  faTachometerAlt,
  faTint,
  faUmbrella,
  faHandHoldingUsd,
  faTrophy,
  faTractor,
} from '@fortawesome/free-solid-svg-icons';

import { useAccount } from 'hooks/useAccount';

import VestingClaimPage from './pages/VestingClaim';
import { routes } from './pages/routes';
import { ExchangeLite } from 'pages/ExchangeLite';
import { ExchangePro } from 'pages/ExchangePro';
import { Farm } from 'pages/Farm';
import { FeedbackContest } from 'pages/FeedbackContest';
import Insurance from 'pages/Insurance';
import { Layout } from 'pages/Layout';
import { LeverageYieldFarm } from 'pages/LeverageYieldFarm';
import LiquidityView from 'pages/Liquidity';
import NotFound from 'pages/NotFound';
import { Overview } from 'pages/Overview';
import { ReceiveCurrency } from 'pages/ReceiveCurrency';

import { config, ComponentsName } from 'config';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Switch } from 'react-router';

const IDLE_TIMEOUT = 60 * 60_000;

const GetDeafultComponent = () => {
  switch (config.features.defaultComponent) {
    case ComponentsName.ExchangePro:
      return ExchangePro;
    case ComponentsName.FeedbackContest:
      return FeedbackContest;
    case ComponentsName.VestingClaim:
      return VestingClaimPage;
    default:
      return Overview;
  }
};

function App() {
  const { disconnect } = useAccount();

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'beforeunload',
    ],
    onIdle: () => disconnect(),
  });

  return (
    <Layout
      navItems={[
        ...(config.views.overview
          ? [
              {
                text: 'Overview',
                icon: faTachometerAlt,
                to: routes.overview.getRoutePath(),
              },
            ]
          : []),
        ...(config.views.exchange
          ? [
              {
                text: 'Exchange Pro',
                icon: faExchangeAlt,
                to: routes['exchange-pro'].getRoutePath(),
              },
            ]
          : []),
        ...(config.views.exchange
          ? [
              {
                text: 'Exchange Lite',
                icon: faExchangeAlt,
                to: routes['exchange-lite'].getRoutePath(),
              },
            ]
          : []),
        ...(config.views.liquidity
          ? [
              {
                text: 'Liquidity',
                icon: faTint,
                to: routes.liquidity.getRoutePath(),
              },
            ]
          : []),
        ...(config.views.insurance
          ? [
              {
                text: 'Insurance',
                icon: faUmbrella,
                to: routes.insurance.getRoutePath(),
              },
            ]
          : []),
        ...(config.views.receiveSUSD
          ? [
              {
                text: 'Receive Currency',
                icon: faHandHoldingUsd,
                to: routes['receive-currency'].getRoutePath(),
              },
            ]
          : []),
        ...(config.views.feedbackContest
          ? [
              {
                text: 'Strips Feedback Contest',
                icon: faTrophy,
                to: routes['feedback-contest'].getRoutePath(),
              },
            ]
          : []),
        ...(config.views.leverageYieldFarm
          ? [
              {
                text: 'Leverage Yield Farm',
                icon: faTractor,
                to: routes['leverage-yield-farm'].getRoutePath(),
              },
            ]
          : []),
        ...(config.views.farm
          ? [
              {
                text: 'Farm',
                icon: faTractor,
                to: routes.farm.getRoutePath(),
              },
            ]
          : []),
      ]}
    >
      <Switch>
        {config.views.overview && (
          <Route
            path={routes.overview.getRoutePath()}
            component={Overview}
            exact
          />
        )}
        {config.views.exchange && (
          <Route
            path={routes['exchange-pro'].getRoutePath()}
            component={ExchangePro}
            exact
          />
        )}
        {config.views.exchange && (
          <Route
            path={routes['exchange-lite'].getRoutePath()}
            component={ExchangeLite}
            exact
          />
        )}
        {config.views.liquidity && (
          <Route
            path={routes.liquidity.getRoutePath()}
            component={LiquidityView}
            exact
          />
        )}
        {config.views.insurance && (
          <Route
            path={routes.insurance.getRoutePath()}
            component={Insurance}
            exact
          />
        )}
        {config.views.receiveSUSD && (
          <Route
            path={routes['receive-currency'].getRoutePath()}
            component={ReceiveCurrency}
            exact
          />
        )}
        {config.views.feedbackContest && (
          <Route
            path={routes['feedback-contest'].getRoutePath()}
            component={FeedbackContest}
            exact
          />
        )}
        {config.views.leverageYieldFarm && (
          <Route
            path={routes['leverage-yield-farm'].getRoutePath()}
            component={LeverageYieldFarm}
            exact
          />
        )}
        {config.views.farm && (
          <Route path={routes.farm.getRoutePath()} component={Farm} exact />
        )}
        <Route path="/" component={GetDeafultComponent()} exact />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
}

export default App;
