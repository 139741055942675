import block from 'bem-cn-lite';
import {IVestingObject} from 'contracts/apiHooks';
import {Text} from 'components';
import {showVestingValue, VestingClaimCardClass} from '../index';

export default function VestingPendingSTRP(props: Pick<IVestingObject, 'strpTotal' | 'strpReleased'>) {
    const bem = block(VestingClaimCardClass);
    const calcPendingValue = props.strpTotal > 0 ? props.strpTotal - props.strpReleased : props.strpTotal;
    return (
        <div className={bem('column', {'accent-background': true})}>
            <Text
                className={bem('label')}
                as='span'
                align='center'
            >
                STRP Pending
            </Text>
            <Text
                className={bem('value')}
                as='span'
                align='center'
            >
                {showVestingValue(calcPendingValue)}
            </Text>
        </div>
    );
}
