import { MarketBoard } from './MarketBoard';

import block from 'bem-cn-lite';
import { useMarkets } from 'graphqlAPI';

import './style.scss';

const b = block('market-boards-list');

export const MarketBoardsList = () => {
  const { markets } = useMarkets();

  return (
    <div className={b()}>
      {markets.map(market => (
        <div className={b('item')}>
          <MarketBoard market={market} />
        </div>
      ))}
    </div>
  );
};
