import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faTelegram,
  faDiscord,
  faMedium,
} from '@fortawesome/free-brands-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from 'components';

import block from 'bem-cn-lite';

import './style.scss';

type SocialMediaItem = {
  name: string;
  icon: IconProp;
  url: string;
};

const socialMediaItems: SocialMediaItem[] = [
  {
    name: 'twitter',
    icon: faTwitter,
    url: 'https://twitter.com/StripsFinance',
  },
  {
    name: 'telegram',
    icon: faTelegram,
    url: 'http://t.me/stripsfi',
  },
  {
    name: 'discord',
    icon: faDiscord,
    url: 'http://discord.gg/xuvGUdC6Cb',
  },
  {
    name: 'medium',
    icon: faMedium,
    url: ' http://strips-finance.medium.com',
  },
  {
    name: 'docs',
    icon: faBook,
    url: 'https://docs.strips.finance',
  },
];

const SocialLinks = () => {
  const bem = block('social-links');
  return (
    <div className={bem()}>
      <Text> Community: </Text>
      <div className={bem('links-container')}>
        {socialMediaItems.map(x => (
          <a
            className={bem('link', { color: x.name })}
            key={x.url}
            href={x.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={x.icon} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialLinks;
