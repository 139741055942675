import React from 'react';

import { zero } from 'utils/bigNumber';
import { block } from 'utils/classname';

import { useAccount } from 'hooks/useAccount';

import { Widget } from 'components';

import { AddLiquidity, YourLiquidity } from './views';
import { useAccountData } from 'graphqlAPI/graphAPIHooks/useAccountData';

import './style.scss';

const b = block('liquidity');

function Liquidity() {
  const { account } = useAccount();
  const { marketStakes } = useAccountData();
  const userLiquidity = Object.values(marketStakes).reduce(
    (acc, market) => acc.plus(market.slpTotal),
    zero,
  );
  return (
    <div className={b('container')}>
      <Widget.Component className={b()}>
        <AddLiquidity />
        {account && userLiquidity.isGreaterThan(0) && (
          <>
            <div className={b('divider')} />
            <YourLiquidity />
          </>
        )}
      </Widget.Component>
    </div>
  );
}

const Component = React.memo(Liquidity);

export { Component as Liquidity };
