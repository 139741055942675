import { createContext } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import type {
  BusdAPI,
  StripsAPI,
  MarketAPI,
  InsuranceAPI,
  SLPTokenAPI,
  VestingAPI,
  MerkleAPI,
} from 'contracts/apiHooks';

export type Web3API = {
  busdAPI: BusdAPI;
  stripsAPI: StripsAPI;
  marketAPI: MarketAPI;
  insuranceAPI: InsuranceAPI;
  slpTokensAPI: SLPTokenAPI;
  vestingAPI: VestingAPI;
  merkleAPI: MerkleAPI;
  account: any;
  web3: Web3Provider;
};

const Web3APIContext = createContext<Web3API | null>(null);

export { Web3APIContext };
