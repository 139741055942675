import { useMemo } from 'react';

import { useWeb3React } from '@web3-react/core';

import { Web3APIContext } from 'contexts/Web3APIContext';
import {
  useBusdAPI,
  useStripsAPI,
  useMarketAPI,
  useInsuranceAPI,
  useSLPTokenAPI,
  useVestingAPI,
  useMerkleAPI,
} from 'contracts/apiHooks';

import { config } from 'config';

const busdAddress = config.contracts.susd;
const stripsAddress = config.contracts.strips;
const insuranceAddress = config.contracts.insurance;
const vestingAddress = config.contracts.vesting;
const merkleAddress = config.contracts.merkle;

export const Web3APIProvider: React.FC = ({ children }) => {
  const { account, library } = useWeb3React();

  const busdAPI = useBusdAPI(library, busdAddress, account ?? '');
  const stripsAPI = useStripsAPI(library, stripsAddress, account ?? '');
  const insuranceAPI = useInsuranceAPI(
    library,
    insuranceAddress,
    account ?? '',
  );
  const slpTokensAPI = useSLPTokenAPI(library);
  const marketAPI = useMarketAPI(library, account ?? '');
  const vestingAPI = useVestingAPI(library, vestingAddress, account ?? '');
  const merkleAPI = useMerkleAPI(library, merkleAddress, account ?? '');

  const value = useMemo(
    () => ({
      account,
      busdAPI,
      stripsAPI,
      marketAPI,
      insuranceAPI,
      slpTokensAPI,
      vestingAPI,
      merkleAPI,
      web3: library,
    }),
    [
      account,
      busdAPI,
      insuranceAPI,
      marketAPI,
      slpTokensAPI,
      stripsAPI,
      vestingAPI,
      merkleAPI,
      library,
    ],
  );

  return (
    <Web3APIContext.Provider value={value}>{children}</Web3APIContext.Provider>
  );
};
