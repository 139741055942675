import React from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';
import { useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import { useAccount } from 'hooks';
import useModal from 'hooks/useModal';

import { Button } from 'components';
import Modal from 'components/Modal';
import { Text } from 'components/Text';

import metamaskLogo from 'assets/assetLogos/metamask.svg';
import walletconnectLogo from 'assets/assetLogos/walletconnect.svg';
import { connectorsByName } from 'constants/connectorsByName';

import { injected, walletconnect } from 'connectors';
import styled from 'styled-components';

const Body = styled.div`
  padding: 24px;
`;

const Options = styled.ul`
  padding: 0;
  margin: 0;
`;

const Option = styled.li`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: rgb(44, 47, 54);
  border: 1px solid rgb(64, 68, 79);
  border-radius: 12px;
  pointer-events: ${({ active }) => active && 'none'};
  :hover {
    cursor: pointer;
    border: 1px solid rgb(33, 114, 229);
  }
  :first-of-type {
    margin-bottom: 10px;
  }
  opacity: ${({ active }) => (active ? 0.4 : 1)};
`;

const OptionImg = styled.img`
  width: 24px;
  height: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

function WalletModal() {
  const modal = useModal();
  const { connector } = useWeb3React();
  const { connect, disconnect, areRequestsLocked } = useAccount();

  const openProviderInstallationPage = () => {
    window.open('https://metamask.io/download.html', '_blank');
  };

  const connectWallet = async (connector: AbstractConnector) => {
    try {
      // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
      if (
        connector instanceof WalletConnectConnector &&
        connector.walletConnectProvider
      ) {
        connector.walletConnectProvider = undefined;
      }

      await connect(connector);
    } finally {
      modal.pop();
    }
  };

  const isInjectedProviderMetamask = (): boolean => {
    return !!window.ethereum?.isMetaMask;
  };

  return (
    <Modal title="Select a Wallet" size="small">
      <Body>
        {!areRequestsLocked && (
          <Header>
            <Text as="h3" typography="text-m">
              Connected with{' '}
              {connector === connectorsByName.WalletConnect
                ? 'WalletConnect'
                : 'MetaMask'}
            </Text>
            <Button variant="danger" size="small" onClick={disconnect}>
              Disconnect
            </Button>
          </Header>
        )}
        <Options>
          <Option
            onClick={
              isInjectedProviderMetamask()
                ? () => connectWallet(injected)
                : openProviderInstallationPage
            }
            active={
              !areRequestsLocked && connector === connectorsByName.Injected
            }
          >
            <span>
              {isInjectedProviderMetamask() ? 'Metamask' : 'Install Metamask'}
            </span>
            <OptionImg alt="Metamask" src={metamaskLogo} />
          </Option>
          <Option
            onClick={() => connectWallet(walletconnect)}
            active={
              !areRequestsLocked && connector === connectorsByName.WalletConnect
            }
          >
            <span>Walletconnect</span>
            <OptionImg alt="Walletconnect" src={walletconnectLogo} />
          </Option>
        </Options>
      </Body>
    </Modal>
  );
}

const Component = React.memo(WalletModal);
export { Component as WalletModal };
