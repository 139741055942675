import { useRef, useState } from 'react';

import { Icon, Button } from '@material-ui/core';
import { Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import {
  FileCopyOutlined,
  ExitToAppOutlined,
  KeyboardArrowDownOutlined,
  AccountBalanceWalletOutlined,
} from '@material-ui/icons';

import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';

import MetamaskIcon from 'assets/assetLogos/metamask.svg';
import { WalletModal } from 'features/modals/walletSelectModal';

import block from 'bem-cn-lite';
import CopyTyClipboard from 'react-copy-to-clipboard';

import './style.scss';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const b = block('wallet-menu');

export const WalletMenu: React.FC = () => {
  const ref = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const { account, disconnect } = useAccount();
  const modal = useModal();

  const handleSelectWallet = () => {
    modal.present(<WalletModal />);
    setOpen(false);
  };

  return (
    <div className={b()}>
      <Button
        ref={ref}
        classes={{
          root: b('button'),
        }}
        startIcon={
          <Icon>
            <img style={{ display: 'block' }} src={MetamaskIcon} alt="" />
          </Icon>
        }
        endIcon={<KeyboardArrowDownOutlined />}
        onClick={() => setOpen(true)}
      >
        {truncateAccount(account ?? '')}
      </Button>
      <Menu
        classes={{
          paper: b('menu'),
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
      >
        <CopyTyClipboard text={account}>
          <MenuItem
            classes={{
              root: b('menu-item'),
            }}
            onClick={() => setOpen(false)}
          >
            <FileCopyOutlined />
            Copy address
          </MenuItem>
        </CopyTyClipboard>
        <MenuItem
          classes={{
            root: b('menu-item'),
          }}
          onClick={() => {
            disconnect();
            setOpen(false);
          }}
        >
          <ExitToAppOutlined />
          Disconnect
        </MenuItem>
        <MenuItem
          classes={{
            root: b('menu-item'),
          }}
          onClick={handleSelectWallet}
        >
          <AccountBalanceWalletOutlined />
          {account ? 'Change Wallet' : 'Select a Wallet'}
        </MenuItem>
      </Menu>
    </div>
  );
};

function truncateAccount(account: string) {
  const first4 = account.substr(0, 4);
  const last4 = account.substr(-4);
  return `${first4}...${last4}`;
}
