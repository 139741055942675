import { createContext } from 'react';

import { TransactionReceipt } from '@ethersproject/providers';

export type Transaction = {
  transactions: object;
  addTransaction: (tx: any, summary: string) => void;
  finishTransaction: (tx: any, receipt: TransactionReceipt) => void;
};

const TransactionContext = createContext<Transaction | null>(null);

export { TransactionContext };
