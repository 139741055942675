import { format } from 'utils/bigNumber';
import { toCurrency } from 'utils/formatters';

import {
  Widget,
  Text,
  ValueCard,
  NumericValue,
  NumberTooltip,
} from 'components';

import block from 'bem-cn-lite';
import { usePortfolioData } from 'graphqlAPI';

import './style.scss';

const b = block('portfolio');

function CurrencyCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="currency"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

function PercentsCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="percents"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

function RatioCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="ratio"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

export function Portfolio() {
  const { totalPortfolioValue, totalMarginRatio, leverage, totalExposure } =
    usePortfolioData();
  return (
    <Widget.Component className={b()}>
      <div className={b('left')}>
        <div className={b('title')}>
          <h1 className={b('header')}>Portfolio Value:</h1>
          <span className={b('portfolio-value')}>
            <NumberTooltip.Component
              title={
                <Text typography="heading-l">
                  {totalPortfolioValue?.toString() ?? ''}
                </Text>
              }
            >
              ${toCurrency(format(totalPortfolioValue, 2))}
            </NumberTooltip.Component>
          </span>
        </div>
        <div className={b('cards')}>
          <div className={b('cards-line')}>
            <div className={b('card')}>
              <ValueCard.Component
                label="Margin Ratio Estimate"
                Value={PercentsCardValue}
                data={totalMarginRatio.toString()}
                tooltipData={totalMarginRatio.toString()}
              />
            </div>
            <div className={b('card')}>
              <ValueCard.Component
                label="Total Exposure"
                Value={CurrencyCardValue}
                data={totalExposure.toString()}
                tooltipData={totalExposure.toString()}
              />
            </div>
            <div className={b('card')}>
              <ValueCard.Component
                label="Leverage"
                Value={RatioCardValue}
                data={format(leverage, 2)}
                tooltipData={leverage.toString()}
              />
            </div>
          </div>
        </div>
      </div>
    </Widget.Component>
  );
}
