import React from 'react';

import { block } from 'utils/classname';

import preloaderSrc from './preloader.svg';

import './style.scss';

const b = block('preloader');

function Preloader() {
  return <img src={preloaderSrc} className={b()} alt="preloader" />;
}

const Component = React.memo(Preloader);

export { Component as Preloader };
