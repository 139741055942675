import React, { useCallback, useEffect, useState } from 'react';

import { BN } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';

import { useWeb3API } from 'contracts/apiHooks';

import { useQuery } from 'react-query';

const validate = (
  insurance: BN | null,
  balance: BN | null,
): string | undefined => {
  if (insurance === null || insurance.isLessThanOrEqualTo(0)) {
    return 'Field is required';
  }

  if (balance && insurance.isGreaterThan(balance)) {
    return 'Not enough balance';
  }

  return undefined;
};

export function useForm() {
  const { account } = useAccount();
  const { busdAPI } = useWeb3API();
  const { data: balance = null } = useQuery(
    ['balance', account],
    () => busdAPI.getBalance(account!),
    { refetchInterval: 5000, enabled: account !== null },
  );
  const [insuranceStake, setInsuranceStake] = useState<BN | null>(null);
  const [error, setError] = useState<string | undefined>();
  const [dirty, setDirty] = useState<boolean>(false);

  const isValid = !Boolean(error);

  const changeInsurance = (value: string | null) => {
    const valueBN = value ? new BN(value) : null;
    if (balance && valueBN) {
      setInsuranceStake(
        balance.isLessThanOrEqualTo(valueBN) ? new BN(balance) : valueBN,
      );
    } else {
      setInsuranceStake(valueBN);
    }
    setDirty(true);
  };

  const setMaxBalance = () => balance && setInsuranceStake(balance);

  const clearAmount = useCallback(() => setInsuranceStake(null), []);

  const makeSubmitHandler =
    (onSubmit: (insuranceStake: BN | null) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit(insuranceStake);
    };

  useEffect(() => {
    setError(validate(insuranceStake, balance));
  }, [insuranceStake]);

  return {
    insuranceStake,
    balance,
    error,
    dirty,
    isValid,
    setMaxBalance,
    clearAmount,
    changeInsurance,
    makeSubmitHandler,
  } as const;
}
