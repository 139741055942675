import React from 'react';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from '../Text';
import { Button } from '../index';

import debounce from 'awesome-debounce-promise';
import block from 'bem-cn-lite';
import NumberFormat from 'react-number-format';

import './style.scss';

const b = block('currency-input');

type Props = {
  value?: string | null;
  onChange?: (value: string | null) => void;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string | null;
  label?: string;
  readOnly?: boolean;
  rightPart?: JSX.Element;
  debounceTimeout?: number;
  allowNegative?: boolean;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  Exclude<
    keyof React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'type' | 'value' | 'onChange'
  >
>;

function CurrencyInput(props: Props) {
  const {
    label,
    value,
    onChange,
    onClear,
    rightPart,
    readOnly,
    error,
    debounceTimeout,
    disabled,
    allowNegative = false,
    ...rest
  } = props;

  const handleClearButtonClick = () => {
    onClear?.();
  };

  const changeValue = value => onChange?.(value ?? null);
  const changeValueDebounced = debounce(changeValue, debounceTimeout ?? 0);

  const handleValueChange = ({ value }: { value: string }) => {
    if (debounceTimeout) {
      changeValueDebounced(value);
    } else {
      changeValue(value);
    }
  };

  return (
    <div className={b({ invalid: Boolean(error) })}>
      <div className={b('content')}>
        <div className={b('input-and-label')}>
          {label && (
            <Text typography="label-m" color="secondary">
              {label}
            </Text>
          )}
          <div className={b('input-container')}>
            <NumberFormat
              className={b('input')}
              decimalScale={18}
              value={value === null ? '' : value}
              onValueChange={handleValueChange}
              thousandSeparator
              isNumericString
              allowNegative={allowNegative}
              readOnly={readOnly}
              disabled={disabled}
              {...rest}
            />
            {onClear && (
              <Button
                variant="clear"
                type="button"
                onClick={handleClearButtonClick}
                className={b('clear-button')}
                isVisible={!!value && !disabled}
              >
                <FontAwesomeIcon
                  className={b('clear-button-icon')}
                  icon={faTimesCircle}
                  size="1x"
                />
              </Button>
            )}
          </div>
        </div>
        {rightPart && (
          <div className={b('right-part-container')}>{rightPart}</div>
        )}
      </div>
      {!readOnly && (
        <Text
          className={b('error', { hidden: !error })}
          typography="text-s"
          color="error"
        >
          {error}
        </Text>
      )}
    </div>
  );
}

export { Limiter, Simple } from './rightParts';
export const Component = React.memo(CurrencyInput);
