import { format, BN } from 'utils/bigNumber';
import { formatCurrency, fractionToPercent } from 'utils/formatters';

import useModal from 'hooks/useModal';

import {
  Preloader,
  Widget,
  MarketName,
  Button,
  DataGrid,
  Text,
  NumberTooltip,
} from 'components';
import Currency from 'components/LegacyCurrency';

import { useModel } from './model';
import { ChangePositionCollateral } from 'features/changePositionCollateral';
import { ConfirmCloseModal } from 'features/modals';

import { Position } from 'graphqlAPI';

import style from './style.module.scss';

export function Positions() {
  const { positions, realTimeData, arePositionsLoading, refetchPositions } =
    useModel();
  const modal = useModal();

  const handleCloseButtonClick = (position: Position) => {
    modal.present(<ConfirmCloseModal position={position} />);
  };

  const handleChangeMarginRatioButtonClick = (position: Position) => {
    modal.present(<ChangePositionCollateral position={position} />);
  };

  const getRate = (type: 'fixed' | 'floating', marketAddress: string) => {
    const field = type === 'fixed' ? 'fixedRate' : 'floatingRate';
    const value = realTimeData?.priceData[marketAddress]?.[field];
    return (
      <NumberTooltip.Component
        title={
          <Text typography="text-s">
            {fractionToPercent(value, { fractionDigits: 'all' })}
          </Text>
        }
        placement="left"
      >
        {fractionToPercent(value)}
      </NumberTooltip.Component>
    );
  };

  const getPNL = () => {
    const value = realTimeData?.pnlData.pnl;
    return value ? format(value) : '';
  };

  const getMarginRation = () => {
    const value = realTimeData?.pnlData.marginRatio;
    return (
      <NumberTooltip.Component
        title={
          <Text typography="text-s">
            {fractionToPercent(value, { fractionDigits: 'all' })}
          </Text>
        }
        placement="left"
      >
        {fractionToPercent(value)}
      </NumberTooltip.Component>
    );
  };

  const isMarginRationCloseToMinimum = () => {
    const marginRatio = realTimeData?.pnlData[0]?.marginRatio;
    const currentMinimumMargin = new BN(0.035); // TODO найти где взять и заменить
    const MARGIN_RATIO_RED_ZONE = 1.2;
    return marginRatio?.isLessThan(
      currentMinimumMargin.multipliedBy(MARGIN_RATIO_RED_ZONE),
    );
  };

  return (
    <Widget.Component>
      <div>
        <div className={style.header}>
          <h2 className={style.heading}>
            Open Positions
            <small>({positions?.length})</small>
          </h2>
          <Button
            variant="primary"
            disabled={arePositionsLoading}
            onClick={refetchPositions}
          >
            {arePositionsLoading ? <Preloader /> : 'Refresh'}
          </Button>
        </div>
        <DataGrid
          columns={[
            [
              { label: 'Market' },
              {
                label: 'Position',
                sortable: true,
                helpText:
                  'This is your total notional exposure (collateral * leverage).',
              },
              { label: 'Leverage', sortable: true },
              { label: 'Fixed Rate', sortable: true },
              { label: 'Floating Rate', sortable: true },
              { label: 'Margin Ratio', sortable: true },
              { label: 'Avg entry price', sortable: true },
              { label: 'P&L', sortable: true },
              { label: '' },
            ],
          ]}
          data={(positions || []).map((position: Position) => [
            <MarketName marketID={position.marketAddress} />,
            <Currency
              amount={format(
                position.positionType === 'long'
                  ? position.notional
                  : position.notional.negated(),
              )}
              code="BUSD"
              showChange
            />,
            <NumberTooltip.Component
              title={<Text typography="text-s">{position.leverage}</Text>}
              placement="left"
            >
              {`${formatCurrency(position.leverage)}x`}
            </NumberTooltip.Component>,
            getRate('fixed', position.marketAddress),
            getRate('floating', position.marketAddress),
            <div className={style.marginRatio}>
              <Text
                typography="text-m"
                color={isMarginRationCloseToMinimum() ? 'error' : 'primary'}
              >
                {getMarginRation()}
              </Text>
              <Button
                className={style.marginRatioChangeButton}
                size="small"
                onClick={() => handleChangeMarginRatioButtonClick(position)}
              >
                Change
              </Button>
            </div>,
            <NumberTooltip.Component
              title={
                <Text typography="text-s">
                  {fractionToPercent(position.initialPrice, {
                    fractionDigits: 'all',
                  })}
                </Text>
              }
              placement="left"
            >
              {fractionToPercent(position.initialPrice)}
            </NumberTooltip.Component>,
            <Currency amount={getPNL()} code="BUSD" showChange />,
            <Button
              size="small"
              onClick={() => handleCloseButtonClick(position)}
            >
              Close
            </Button>,
          ])}
        />
      </div>
    </Widget.Component>
  );
}
