import { useAccount } from 'hooks/useAccount';

import { Button, Preloader, Text } from 'components';

import { useFeatureStore } from '../../context/context';
import { useDiscordAuth } from './useDiscordAuth';

import block from 'bem-cn-lite';

import './style.scss';

const b = block('discord-connector');

export const ConnectDiscord: React.FC = () => {
  const { account } = useAccount();
  const { user, isUserLoading } = useFeatureStore();
  const { isConnecting, authWithDiscord } = useDiscordAuth();
  const buttonText = user?.discordHandle ? 'change' : 'connect discord';
  return (
    <div className={b()}>
      <Button
        disabled={isUserLoading || isConnecting || account === null}
        variant={user !== null && user.discordHandle ? 'success' : 'primary'}
        onClick={authWithDiscord}
      >
        {isConnecting || isUserLoading ? <Preloader /> : buttonText}
      </Button>
      {user?.discordHandle && (
        <Text typography="text-l">{user.discordHandle}</Text>
      )}
    </div>
  );
};
