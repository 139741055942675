import { BigNumber } from '@ethersproject/bignumber';

export const defaultGas = '2000000';
export const estimationRate = 150;

export function calcGas(estimation: BigNumber | undefined) {
  console.log('Gas Estimation:', estimation);

  if (estimation === undefined) return defaultGas;

  return estimation.mul(estimationRate).div(100);
}
