import block from 'bem-cn-lite';
import VestingClaimCard from './views/VestingClaimCard';

import './style.scss';

export default function VestingClaimPage() {
    const bem = block('vesting-claim-page');
    return (
        <div className={bem()}>
            <VestingClaimCard />
        </div>
    );
}
