import React, { useCallback, useEffect, useRef, useState } from 'react';

import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { block } from 'utils/classname';

import './style.scss';

const b = block('expansion-panel');

export type HeaderProps<T> = {
  data: T;
};

export type ContentProps<T> = {
  data: T;
};

type Props<T> = {
  data: T;
  initiallyExpanded?: boolean;
  Header: React.ComponentType<HeaderProps<T>>;
  Content: React.ComponentType<ContentProps<T>>;
};

function ExpansionPanel<T>(props: Props<T>) {
  const { Content, Header, data, initiallyExpanded = false } = props;

  const [height, setHeight] = useState<number>();
  const [initial, setInitial] = useState(true);
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const handleHeaderContainerClick = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(contentRef.current?.clientHeight);
    setInitial(false);
  }, []);

  return (
    <div className={b({ expanded })}>
      <div
        className={b('header-container')}
        onClick={handleHeaderContainerClick}
      >
        <Header data={data} />
        <FontAwesomeIcon icon={faAngleUp} className={b('icon')} size="lg" />
      </div>
      <div
        className={b('content', { initial })}
        style={{ height: expanded ? height : undefined }}
        ref={contentRef}
      >
        <Content data={data} />
      </div>
    </div>
  );
}

export const Component = React.memo(ExpansionPanel) as typeof ExpansionPanel;
