import React from 'react';

import { AccountProvider } from './AccountProvider';
import { ApolloProvider } from './ApolloProvider';
import { ModalPresenter } from './ModalPresenter';
import { QueryProvider } from './QueryProvider';
import { TransactionProvider } from './TransactionProvider';
import { Web3APIProvider } from './Web3Provider';

import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

export const Providers: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        hideIconVariant
        preventDuplicate={true}
      >
        <AccountProvider>
          <ApolloProvider>
            <QueryProvider>
              <Web3APIProvider>
                <TransactionProvider>
                  <ModalPresenter>{children}</ModalPresenter>
                </TransactionProvider>
              </Web3APIProvider>
            </QueryProvider>
          </ApolloProvider>
        </AccountProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};
