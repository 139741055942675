import React, { useMemo, useCallback, useState } from 'react';

import { zero, BN } from 'utils/bigNumber';
import { block } from 'utils/classname';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import { CurrencyInput, ExpansionPanel, Preloader, Text } from 'components';

import {
  ExpansionPanelHeader,
  makeExpansionPanelContent,
  MarketWithStakes,
} from './ExpansionPanel';

import debounce from 'awesome-debounce-promise';
import { useMarkets, useAccountData } from 'graphqlAPI';

import './style.scss';

const b = block('your-liquidity');

function YourLiquidity() {
  const { stripsAPI } = useWeb3();
  const { account } = useAccount();
  const { markets, loading } = useMarkets();
  const { marketStakes } = useAccountData();
  const [profitEarned, setProfitEarned] = useState(new BN(0));

  const ExpansionPanelContent = useMemo(() => makeExpansionPanelContent(), []);

  const debouncedProfitEarned = useCallback(
    debounce(stripsAPI.profitEarned, 600),
    [stripsAPI],
  );

  (async () => {
    try {
      if (account) {
        const r = await debouncedProfitEarned(account?.toString());
        if (r) {
          setProfitEarned(r);
        }
      }
    } catch (e) {}
  })();

  const marketsWithAccountData = useMemo(
    () =>
      markets.map<MarketWithStakes>(x => ({
        ...x,
        stakedLiquidity: marketStakes[x.id]?.collateralTotal ?? zero,
        slpTotal: marketStakes[x.id]?.slpTotal ?? zero,
        initialStakedPnl: marketStakes[x.id]?.initialStakedPnl ?? zero,
      })),
    [markets, marketStakes],
  );

  if (loading || markets.length > 0) {
    return (
      <div className={b()}>
        <header className={b('section-header')}>
          <Text as="h2" typography="heading-l">
            Your liquidity
          </Text>
        </header>
        {loading ? (
          <div className={b('preloader')}>
            <Preloader />
          </div>
        ) : (
          <>
            <div className={b('profit')}>
              <CurrencyInput.Component
                value={profitEarned?.toString()}
                label="Profit earned"
                readOnly
                allowNegative
              />
            </div>

            <div className={b('liquidity-list')}>
              {marketsWithAccountData.map(x => {
                return (
                  <div key={x.id} className={b('liquidity')}>
                    <ExpansionPanel.Component<MarketWithStakes>
                      Header={ExpansionPanelHeader}
                      Content={ExpansionPanelContent}
                      data={x}
                      initiallyExpanded
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
}

const Component = React.memo(YourLiquidity);

export { Component as YourLiquidity };
