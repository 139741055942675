import { MarketSummaryItem } from './MarketSummaryItem';

import styled from 'styled-components';

const MarketSummaryStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 48px 24px 48px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  background: #262338;
  margin: 0 0 0 24px;
`;

export const MarketSummary = () => {
  return (
    <MarketSummaryStyled className="market-summary">
      <MarketSummaryItem
        value={'$24.11k'}
        label={'Floating Rate'}
        variation={4.75}
      />
      <MarketSummaryItem value={'$33.2m'} label={'TVL'} variation={4.75} />
      <MarketSummaryItem
        value={'$325.11m'}
        label={'24h Volume'}
        variation={-4.75}
      />
    </MarketSummaryStyled>
  );
};
