import React from 'react';

import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { block } from 'utils/classname';

import { NumericValue, Tooltip, ValueCard } from 'components';

import './style.scss';

const b = block('insurance-cards');

type Props = {
  liquidity: string;
};

function APRCardValue() {
  return (
    <div className={b('card-value')}>
      <NumericValue.Component kind="percents" value="43.6" />
      <FontAwesomeIcon icon={faCalculator} />
    </div>
  );
}

function LiquidityCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <div className={b('card-value')}>
      <NumericValue.Component
        kind="currency"
        value={data}
        fontWeight={fontWeight}
        fractionDigits={fractionDigits}
      />
    </div>
  );
}

function LiquidationsCardValue() {
  return (
    <div className={b('card-value')}>
      <NumericValue.Component kind="currency" value="10000" />
      <div className={b('card-value-tooltip')}>
        <Tooltip text="24h Liquidations tooltip" />
      </div>
    </div>
  );
}

function Cards({ liquidity }: Props) {
  return (
    <div className={b()}>
      {/* <ValueCard.Component
        data="43.6"
        Value={APRCardValue}
        label="APR"
      /> */}
      <ValueCard.Component
        data={liquidity}
        Value={LiquidityCardValue}
        label="Liquidity"
        tooltipData={liquidity.toString()}
      />
      {/* <ValueCard.Component
        data="43.6"
        Value={LiquidationsCardValue}
        label="24h Liquidations"
      /> */}
    </div>
  );
}

const Component = React.memo(Cards);

export { Component as Cards };
