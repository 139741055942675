import React, { useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { BN } from 'utils/bigNumber';
import { block } from 'utils/classname';
import { formatCurrency } from 'utils/formatters';

import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import {
  Widget,
  Button,
  CurrencyInput,
  DataGrid,
  Text,
  Currency,
  DataGridColumn,
  NumberTooltip,
} from 'components';

import { RatioButtons } from './RatioButtons';
import { useFormState } from './useForm';

import { Position } from 'graphqlAPI';
import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('close-position-form');

type Props = {
  position: Position;
};

function Form({ position }: Props) {
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { connect, areRequestsLocked } = useAccount();
  const { stripsAPI, busdAPI } = useWeb3();
  const {
    isDirty,
    isValid,
    errors,
    positionSize,
    amount,
    closeRatio,
    closeParams,
    collateralRemaining,
    pnlReturned,
    totalReturned,
    fee,
    areCloseParamsCalculating,
    handleAmountChange,
    setAmount,
    clearAmount,
    createSubmitHandler,
    FEE_APPROVEMENT_MULTIPLIER,
  } = useFormState(position);

  const [isSubmitting, setSubmitting] = useState(false);
  const { addTransaction } = useTransaction();

  const handleFormSubmit = createSubmitHandler(() => {
    if (areRequestsLocked) {
      connect();
    } else {
      Promise.resolve()
        .then(() => setSubmitting(true))
        .then(() =>
          position && closeParams
            ? closePosition(
                position.marketAddress,
                closeRatio,
                closeParams.fee.multipliedBy(FEE_APPROVEMENT_MULTIPLIER),
              )
            : new Promise((_, reject) =>
                reject(Error('Position or closeParams is not provided')),
              ),
        )
        .then(tx => {
          addTransaction(tx, `Close position`);
        })
        .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => setSubmitting(false))
        .finally(() => modal.pop());
    }
  });

  const closePosition = (marketAddress: string, closeRatio: BN, fee: BN) =>
    busdAPI
      .approve(stripsAPI.address, fee.plus(position.collateral))
      .then(tx => {
        tx !== '' && addTransaction(tx, `Approving BUSD`);
      })
      .then(() => stripsAPI.closePositionPartially(marketAddress, closeRatio));

  const submitButtonText = areRequestsLocked ? 'CONNECT' : 'CLOSE POSITION';

  const gridColumns: DataGridColumn[] = useMemo(() => {
    return [
      [
        { label: '1', minWidth: 'auto' },
        { label: '2', minWidth: '100px' },
        { label: '3', minWidth: 'auto', align: 'end', disablePadding: 'right' },
      ],
    ];
  }, []);

  const gridData = useMemo(() => {
    const data = [
      ['Fee (paid)', fee.toString(), 'busd'],
      ['Collateral remaining', collateralRemaining.toString(), 'busd'],
      ['Pnl returned', pnlReturned.toString(), 'busd'],
      ['Total returned', totalReturned.toString(), 'busd'],
    ] as const;
    return data.map(([label, value, currency]) => [
      <Text typography="heading-l" color="secondary">
        {label}
      </Text>,
      areCloseParamsCalculating ? (
        <CircularProgress size={14} />
      ) : (
        <NumberTooltip.Component
          title={<Text typography="text-s">{value}</Text>}
          placement="left"
        >
          <Text typography="text-m" color="secondary">
            {formatCurrency(value)}
          </Text>
        </NumberTooltip.Component>
      ),
      <Currency.Component currency={currency} />,
    ]);
  }, [
    areCloseParamsCalculating,
    collateralRemaining,
    fee,
    pnlReturned,
    totalReturned,
  ]);

  return (
    <div className={b()}>
      <form onSubmit={handleFormSubmit}>
        <Widget.SubSection>
          <CurrencyInput.Component
            label="Amount"
            value={amount?.toString() ?? null}
            error={isDirty ? errors.amount : undefined}
            onChange={handleAmountChange}
            onClear={clearAmount}
            rightPart={
              <CurrencyInput.Limiter
                value={positionSize}
                label="Position Size"
                onButtonClick={() => setAmount(1)}
                currency="busd"
                disabled={areCloseParamsCalculating || closeRatio.isEqualTo(1)}
              />
            }
            disabled={areCloseParamsCalculating}
            debounceTimeout={500}
          />
          <RatioButtons
            ratios={[0.1, 0.25, 0.5, 0.75, 0.9]}
            currentCloseRatio={closeRatio.toNumber()}
            onButtonClick={ratio => setAmount(ratio)}
            disabled={areCloseParamsCalculating}
          />
        </Widget.SubSection>
        <Widget.SubSection>
          <DataGrid columns={gridColumns} data={gridData} hasHead={false} />
        </Widget.SubSection>
        <Widget.SubSection>
          <Button
            variant="danger"
            type="submit"
            disabled={
              isSubmitting ||
              position === null ||
              !isValid ||
              areCloseParamsCalculating
            }
            className={b('submit-button')}
          >
            {isSubmitting ? <CircularProgress size={20} /> : submitButtonText}
          </Button>
        </Widget.SubSection>
      </form>
    </div>
  );
}

const Component = React.memo(Form);
export { Component as Form };
