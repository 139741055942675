import { memo, useMemo } from 'react';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import nerveLogo from 'assets/market_nerve.png';

import block from 'bem-cn-lite';
import { config } from 'config';

import './style.scss';

type Props = {
  marketID: string;
  withAssetSymbol?: boolean;
};

const b = block('market-name');

const MarketName = ({ marketID, withAssetSymbol }: Props) => {
  const logo = useMemo(
    () => (config.markets[marketID] ? nerveLogo : ''),
    [marketID],
  );

  return (
    <div className={b()}>
      <img
        src={logo}
        alt={config.markets[marketID]?.platform}
        className={b('icon')}
      />
      <div className={b('name')}>{config.markets[marketID]?.platform}</div>
      {withAssetSymbol && (
        <>
          <FontAwesomeIcon
            icon={faAngleRight}
            className={b('arrow')}
            size="sm"
          />
          <div className={b('symbol')}>
            {config.markets[marketID]?.assetSymbol}
          </div>
        </>
      )}
    </div>
  );
};

const Component = memo(MarketName);
export { Component as MarketName };
