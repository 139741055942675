import { formatCurrency } from 'utils/formatters';

import { NumberTooltip, Text } from 'components';

import style from './style.module.scss';

interface Props {
  value: string;
}

const Signum = ({ value }: Props) => {
  const positive = value[0] !== '-';

  if (positive) {
    return (
      <NumberTooltip.Component
        title={<Text typography="text-s">{value}</Text>}
        placement="left"
      >
        <span className={style.positive}>{formatCurrency(value)}</span>
      </NumberTooltip.Component>
    );
  }

  return (
    <NumberTooltip.Component
      title={<Text typography="text-s">{value}</Text>}
      placement="left"
    >
      <span className={style.negative}>{formatCurrency(value)}</span>
    </NumberTooltip.Component>
  );
};

export default Signum;
