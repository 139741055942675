import React from 'react';

import { block } from 'utils/classname';

import { Text } from 'components';
import { NumberTooltip } from 'components';

import './style.scss';

const b = block('value-card');

export type ValueProps<T> = {
  data: T;
  fontWeight?: 'normal' | 'bold';
  fractionDigits?: 'all' | number;
};

type Props<T> = {
  data: T;
  tooltipData?: T;
  tooltipPlacement?: NumberTooltip.TooltipProps['placement'];
  label: string;
  Value: React.ComponentType<ValueProps<T>>;
};

export function ValueCard<T>(props: Props<T>) {
  const { label, Value, data, tooltipData, tooltipPlacement = 'left' } = props;
  return (
    <div className={b()}>
      <NumberTooltip.Component
        title={
          tooltipData ? (
            <Value
              data={tooltipData}
              fontWeight="normal"
              fractionDigits="all"
            />
          ) : (
            ''
          )
        }
        placement={tooltipPlacement}
      >
        <Text color="secondary" typography="text-s">
          {label}
        </Text>
        <Value data={data} />
      </NumberTooltip.Component>
    </div>
  );
}

export const Component = ValueCard;
