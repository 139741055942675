import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { BN, format, zero } from 'utils/bigNumber';
import { clamp } from 'utils/clamp';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import debounce from 'awesome-debounce-promise';
import { PositionKind } from 'model';
import { Market } from 'model';
import { useAsyncAbortable } from 'react-async-hook';
import { useQuery } from 'react-query';

type CalcFeeWithSlippageData = {
  feeWithSlippage: BN;
  whatIfprice: BN;
};

type FormState = {
  market: Market | null;
  positionSize: BN | null;
  collateral: BN | null;
  leverage: number;
  positionKind: PositionKind;
};

type Errors = Partial<{
  [error in keyof FormState]: string;
}>;

const validatePositionSize = (
  positionSize: BN | null,
  maxNotional: BN | null,
): string | undefined => {
  if (positionSize === null || positionSize.isLessThanOrEqualTo(0)) {
    return 'Field is required';
  }
  if (maxNotional && positionSize.isGreaterThan(maxNotional)) {
    return `Exceed maximum position: ${format(maxNotional, 2)}`;
  }
  return undefined;
};

const validateCollateral = (
  balance: BN | null,
  collateral: BN | null,
  fee: BN | null,
): string | undefined => {
  if (collateral === null || collateral.isLessThanOrEqualTo(0)) {
    return 'Field is required';
  }
  const requiredBalance = collateral.plus(fee ?? zero);
  if (balance && fee && requiredBalance.isGreaterThan(balance)) {
    return `Not enough balance, required: ${format(requiredBalance, 2)}`;
  }
  return undefined;
};

function useCheck(
  market: Market | null,
  collateral: BN | null,
  leverage: number,
  positionKind: PositionKind,
) {
  const { marketAPI, stripsAPI } = useWeb3();

  const debouncedMaxNotionalCached = useCallback(
    debounce(marketAPI.maxNotionalCached, 600),
    [marketAPI],
  );
  const debouncedCalcFee = useCallback(debounce(stripsAPI.calcFee, 600), [
    stripsAPI,
  ]);

  return useAsyncAbortable<
    { maxNotional: BN; fee: CalcFeeWithSlippageData | null } | undefined,
    [Market | null, BN | null, number, PositionKind]
  >(
    async (_, market, collateral, leverage, positionKind) => {
      if (market && collateral) {
        const [maxNotionalCached, fee] = await Promise.all([
          debouncedMaxNotionalCached(market.id),
          debouncedCalcFee(market.id, collateral, leverage, positionKind),
        ]);
        return { maxNotional: maxNotionalCached.maxNotional, fee };
      }
    },
    [market, collateral, leverage, positionKind],
  );
}

export function useFormState(initialMarket: Market | null) {
  const { account } = useAccount();
  const { busdAPI } = useWeb3();
  const { data: balance = null } = useQuery(
    ['balance', account],
    () => busdAPI.getBalance(account!),
    { refetchInterval: 5000, enabled: account !== null },
  );

  const [fixedInput, setFixedInput] = useState<
    'collateral' | 'positionSize' | null
  >(null);
  const [editedInput, setEditedInput] = useState<
    'collateral' | 'positionSize' | null
  >(null);

  const [formState, setFormState] = useState<FormState & { dirty: boolean }>({
    market: initialMarket,
    positionSize: null,
    leverage: 1,
    collateral: null,
    positionKind: 'long',
    dirty: false,
  });
  const [errors, setErrors] = useState<Errors>({});
  const {
    loading,
    result: { maxNotional, fee } = { maxNotional: null, fee: null },
  } = useCheck(
    formState.market,
    formState.collateral,
    formState.leverage,
    formState.positionKind,
  );
  const isValid = !Object.values(errors).some(Boolean);

  const maxCollateral = useMemo(
    () => (balance ? balance.multipliedBy(0.97) : zero),
    [balance],
  );

  const handlePositionSizeFocus = () => {
    setEditedInput('positionSize');
  };

  const handleCollateralFocus = () => {
    setEditedInput('collateral');
  };

  const handlePositionSizeBlur = () => {
    setFixedInput('positionSize');
  };

  const handleCollateralBlur = () => {
    setFixedInput('collateral');
  };

  const handlePositionSizeChange = (value: string | null) => {
    if (editedInput === 'positionSize') {
      setFixedInput('positionSize');
      setFormState(prevState => ({
        ...prevState,
        positionSize: value ? new BN(value) : null,
        dirty: true,
      }));
    }
  };

  const clampCollateral = (value: string | null) => {
    const valueBN = value ? new BN(value) : null;
    if (balance && valueBN) {
      return valueBN.isGreaterThan(balance) ? balance : valueBN;
    } else {
      return null;
    }
  };

  const handleCollateralChange = (value: string | null) => {
    if (editedInput === 'collateral') {
      setFixedInput('collateral');
      setFormState(prevState => ({
        ...prevState,
        collateral: clampCollateral(value),
        dirty: true,
      }));
    }
  };

  const handleMarketSelect = useCallback((market: Market | null) => {
    setFormState(prevState => ({
      ...prevState,
      market,
    }));
  }, []);

  const handleLeverageChange = (value: number) => {
    value !== formState.leverage &&
      setFormState(prevState => ({
        ...prevState,
        leverage: clamp(1, 10, value),
        dirty: true,
      }));
  };

  const setMaxBalance = () => {
    if (balance !== null) {
      setFixedInput('collateral');
      setFormState(prevState => ({
        ...prevState,
        collateral: maxCollateral,
        positionSize: maxCollateral.times(prevState.leverage),
        dirty: true,
      }));
    }
  };

  const clearPositionSize = () => {
    setEditedInput(null);
    setFormState(prevState => ({
      ...prevState,
      positionSize: null,
    }));
  };

  const clearCollateral = () => {
    setEditedInput(null);
    setFormState(prevState => ({
      ...prevState,
      collateral: null,
    }));
  };

  useEffect(() => {
    if (editedInput === 'collateral') {
      setFormState(prevState => ({
        ...prevState,
        positionSize:
          prevState.collateral !== null
            ? new BN(prevState.collateral).times(prevState.leverage)
            : null,
      }));
    }
  }, [formState.collateral]);

  useEffect(() => {
    if (editedInput === 'positionSize') {
      setFormState(prevState => ({
        ...prevState,
        collateral:
          prevState.positionSize !== null
            ? new BN(prevState.positionSize).dividedBy(prevState.leverage)
            : null,
      }));
    }
  }, [formState.positionSize]);

  useEffect(() => {
    if (fixedInput === 'collateral') {
      setFormState(prevState => ({
        ...prevState,
        positionSize:
          prevState.collateral !== null
            ? new BN(prevState.collateral).times(prevState.leverage)
            : null,
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        collateral:
          prevState.positionSize !== null
            ? new BN(prevState.positionSize).dividedBy(prevState.leverage)
            : null,
      }));
    }
  }, [formState.leverage]);

  useEffect(() => {
    setErrors(prevErrors => ({
      ...prevErrors,
      positionSize: validatePositionSize(formState.positionSize, maxNotional),
      collateral: validateCollateral(
        balance,
        formState.collateral,
        fee ? fee.feeWithSlippage : null,
      ),
    }));
  }, [formState, balance, maxNotional, fee]);

  const createSubmitHandler =
    (onSubmit: (formState: FormState) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      isValid && onSubmit(formState);
    };

  return {
    market: formState.market,
    positionSize: formState.positionSize,
    collateral: formState.collateral,
    leverage: formState.leverage,
    positionKind: formState.positionKind,
    dirty: formState.dirty,
    errors,
    isValid,
    balance,
    maxCollateral,
    fee,
    isValidating: loading,
    handleMarketSelect,
    setPositionKind: (kind: PositionKind) =>
      setFormState(prevState => ({ ...prevState, positionKind: kind })),
    setMaxBalance,
    handleCollateralChange,
    handlePositionSizeChange,
    handleLeverageChange,
    handleCollateralFocus,
    handlePositionSizeFocus,
    handleCollateralBlur,
    handlePositionSizeBlur,
    createSubmitHandler,
    clearPositionSize,
    clearCollateral,
  } as const;
}
