import block from 'bem-cn-lite';
import {Text} from 'components';
import {IVestingObject} from 'contracts/apiHooks';
import {showVestingValue, VestingClaimCardClass} from '../index';

export default function VestingClaimedSTRP(props: Pick<IVestingObject, 'strpReleased'>) {
    const bem = block(VestingClaimCardClass);
    return (
        <div className={bem('column', {'accent-background': true})}>
            <Text
                className={bem('label')}
                as='span'
                align='center'
            >
                STRP Claimed
            </Text>
            <Text
                className={bem('value')}
                as='span'
                align='center'
            >
                {showVestingValue(props.strpReleased)}
            </Text>
        </div>
    );
}
