import { ReceiveBNBWidget } from './ReceiveBNBWidget';
import { ReceiveSUSDFrom } from 'features/receiveSUSD';

import block from 'bem-cn-lite';

import './style.scss';

const b = block('receive-currency-page');

export const ReceiveCurrency = () => {
  return (
    <section className={b()}>
      <ReceiveSUSDFrom />
      <ReceiveBNBWidget />
    </section>
  );
};
