import React from 'react';

import { block } from 'utils/classname';

import { Text } from 'components';

import { Provider } from './context/Provider';
import { ContestSteps, Rewards, ConnectDiscord } from './views';

import './style.scss';

const b = block('feedback-contest');

function FeedbackContest() {
  return (
    <Provider>
      <article className={b()}>
        <Text as="h1" typography="heading-l">
          Strips Feedback Contest
        </Text>
        <section className={b('content')}>
          <div className={b('left-column')}>
            <ContestSteps />
          </div>
          <div className={b('right-column')}>
            <Rewards />
            <ConnectDiscord />
          </div>
        </section>
      </article>
    </Provider>
  );
}

const Component = React.memo(FeedbackContest);
export { Component as FeedbackContest };
