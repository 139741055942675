import React, { useState, useEffect } from 'react';

import { BN, zero } from 'utils/bigNumber';

import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import { CurrencyInput, Button, Preloader } from 'components';

import block from 'bem-cn-lite';
import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('earned');

type Props = {
  busdEarned: BN | null;
};

const validateAmount = (amount: BN | null) => {
  if (amount === null || amount.isLessThanOrEqualTo(zero)) {
    return 'There is no profit to harvest';
  }
  return undefined;
};

function Earned({ busdEarned }: Props) {
  const [isHarvesting, setHarvesting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { insuranceAPI } = useWeb3();
  const [triedToHarvest, setTriedToHarvest] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const isValid = error === undefined;
  const { addTransaction } = useTransaction();

  const handleHarvest = () => {
    if (!isValid) {
      setTriedToHarvest(true);
      return;
    }
    !isHarvesting &&
      Promise.resolve()
        .then(() => setHarvesting(true))
        .then(() => insuranceAPI.harvest())
        .then(tx => {
          addTransaction(tx, `Harvest earned`);
        })
        .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => setHarvesting(false));
  };

  useEffect(() => {
    setError(validateAmount(busdEarned));
  }, [busdEarned]);

  return (
    <div className={b()}>
      <CurrencyInput.Component
        value={busdEarned?.toString()}
        label="BUSD earned"
        rightPart={<HarvestButton />}
        readOnly
        allowNegative
        error={triedToHarvest ? error : null}
      />
    </div>
  );

  function HarvestButton() {
    return (
      <div className={b('harvest-button-container')}>
        <Button
          className={b('harvest-button')}
          disabled={isHarvesting}
          onClick={handleHarvest}
        >
          {isHarvesting ? <Preloader /> : 'Harvest'}
        </Button>
      </div>
    );
  }
}

const Component = React.memo(Earned);
export { Component as Earned };
