import React, { useCallback } from 'react';

import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useModal from 'hooks/useModal';

import { CurrencyInput } from 'components';

import { StakeInsuranceModal } from './StakeInsurance';
import { UnstakeInsuranceModal } from './UnstakeInsurance';

import block from 'bem-cn-lite';

import './style.scss';

type Props = {
  collateral: string | null;
};

const b = block('staked');

function Controls() {
  const modal = useModal();

  const handlePlusClick = useCallback(() => {
    modal.present(<StakeInsuranceModal />);
  }, []);

  const handleMinusClick = useCallback(() => {
    modal.present(<UnstakeInsuranceModal />);
  }, []);

  return (
    <div className={b('controls')}>
      <div className={b('control')}>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2x"
          onClick={handlePlusClick}
        />
      </div>
      <div className={b('control')}>
        <FontAwesomeIcon
          icon={faMinusCircle}
          size="2x"
          onClick={handleMinusClick}
        />
      </div>
    </div>
  );
}

function Staked({ collateral }: Props) {
  return (
    <div className={b()}>
      <CurrencyInput.Component
        value={collateral}
        label="BUSD Staked"
        rightPart={<Controls />}
        readOnly
      />
    </div>
  );
}

const Component = React.memo(Staked);
export { Component as Staked };
