import { useEffect, useState } from 'react';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useOpenWindow } from 'hooks/useOpenWindow';

import {
  getDiscordAuthURL,
  getDiscordUser,
  isDiscordHandleUsed,
  createUserDiscord,
} from '../../api';
import { useFeatureStore } from '../../context/context';

export function useDiscordAuth() {
  const { account } = useAccount();
  const { showError } = useErrorHandler();
  const { user, setUser } = useFeatureStore();
  const [isConnecting, setConnecting] = useState<boolean>(false);
  const { childWindow, open, close } = useOpenWindow({
    height: 760,
    onParamsGet: handleParamsGet,
  });

  const authWithDiscord = () => {
    setConnecting(true);
    getDiscordAuthURL().then(({ redirectURL }) => {
      open(redirectURL);
    });
  };

  const connectDiscord = (code: string) => {
    if (account) {
      setConnecting(true);
      getDiscordUser(code)
        .then(({ username }) =>
          isDiscordHandleUsed(username)
            .then(isAccountUsed => {
              if (isAccountUsed) {
                throw new Error(`Discord account "${username}" already used`);
              }
            })
            .then(() =>
              createUserDiscord({
                wallet: account,
                twitterHandle: user?.twitterHandle,
                discordHandle: username,
              }).then(ret => setUser(ret)),
            ),
        )
        .catch(showError)
        .finally(() => setConnecting(false));
    }
  };

  function handleParamsGet(
    params: { code: string } | { error: string; errorDescription: string },
  ) {
    if ('code' in params) {
      connectDiscord(params.code);
    } else if (params.error) {
      showError({ message: params.errorDescription });
    }

    close();
    setConnecting(false);
  }

  useEffect(() => {
    if (childWindow === null) {
      setConnecting(false);
    }
  }, [childWindow]);

  return {
    isConnecting,
    authWithDiscord,
  } as const;
}
