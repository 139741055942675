import { Button, Preloader, Text } from 'components';

import { VestingClaimCardClass } from '../index';

import block from 'bem-cn-lite';

interface IClaimButtonProps {
  isLoading: boolean;
  isVestingEnabled: boolean;
  isClaimEnabled: boolean;
  onClaim: () => void;
}

export default function VestingClaimButton(props: IClaimButtonProps) {
  const bem = block(VestingClaimCardClass);
  if (props.isLoading) {
    return (
      <Button variant="dark" disabled>
        <Preloader />
      </Button>
    );
  }

  return props.isVestingEnabled ? (
    <Button
      className={bem('claim-button')}
      variant="success"
      disabled={!props.isClaimEnabled}
      onClick={() => props.onClaim()}
    >
      Claim
    </Button>
  ) : (
    <Text className={bem('label')} as="span" typography="text-l" color="error">
      No vesting info found!
    </Text>
  );
}
