import React from 'react';

import { BN } from 'utils/bigNumber';

import { ValueCard, NumericValue } from 'components';

import block from 'bem-cn-lite';

import './style.scss';

const b = block('add-insurance-cards');

type Props = {
  insurance: BN | null;
  poolSize: BN;
};

const calcPoolShare = (insuranceStake: BN, poolSize: BN) =>
  insuranceStake.div(insuranceStake.plus(poolSize));

function CurrencyCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="currency"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

function PercentsCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="percents"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

function Cards({ insurance, poolSize }: Props) {
  const share = insurance ? calcPoolShare(insurance, poolSize) : '';

  return (
    <div className={b()}>
      <ValueCard.Component
        label="Pool Size (BUSD)"
        data={poolSize.toString()}
        Value={CurrencyCardValue as any}
        tooltipData={poolSize.toString()}
      />
      <ValueCard.Component
        label="Share of Pool"
        data={share}
        Value={PercentsCardValue as any}
        tooltipData={share}
      />
      <ValueCard.Component
        label="SIP to Receive"
        data={insurance?.toString() ?? ''}
        Value={CurrencyCardValue as any}
        tooltipData={insurance?.toString() ?? ''}
      />
    </div>
  );
}

const Component = React.memo(Cards);

export { Component as Cards };
