import Box from '@material-ui/core/Box';

import styled from 'styled-components';

const FixedRateWidgetStyled = styled.div`
  /* Fixed Rate Widget */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Auto Layout */

  padding: 12px 16px;

  /* Error/Darkmode */

  border: 2px solid
    ${({ variation, theme }) =>
      variation >= 0 ? theme.colors.success.base : theme.colors.danger.base};

  box-sizing: border-box;
  border-radius: 16px;

  h3 {
    left: 0%;
    right: 0%;
    top: 17.07%;
    bottom: 43.9%;

    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    /* Error/Darkmode */

    color: ${({ variation, theme }) =>
      variation >= 0 ? theme.colors.success.base : theme.colors.danger.base};

    /* Inside Auto Layout */

    margin: 0px 0px;
  }

  span {
    width: 36px;
    height: 22px;
    left: 11px;
    top: 0px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 220% */

    letter-spacing: 0.25px;

    /* Error/Darkmode */

    color: ${({ variation, theme }) =>
      variation >= 0 ? theme.colors.success.base : theme.colors.danger.base};

    /* Inside Auto Layout */

    margin: 0px 4px;
  }

  label {
    /* Desktop/Link X-Small */

    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    /* Monochrome/Input */

    color: #eff0f7;

    /* Inside Auto Layout */

    margin: 0px 8px;
  }
`;

interface FixedRateWidgetProps {
  price: number;
  variation: number;
  label: string;
}
export const FixedRateWidget = ({
  price,
  variation,
  label,
}: FixedRateWidgetProps) => {
  return (
    <FixedRateWidgetStyled className="fixed-rate-widget" variation={variation}>
      <h3>{price}%</h3>
      <Box display="flex">
        <span className="fixed-rate-variation">{variation}%</span>
        <label>{label}</label>
      </Box>
    </FixedRateWidgetStyled>
  );
};
