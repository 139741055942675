import React, { useEffect, useMemo, useState } from 'react';

import block from 'bem-cn-lite';

import './style.scss';

type Props = {
  size?: 's' | 'm' | 'l';
  labelBefore?: string;
  labelAfter?: string;
  checked?: boolean;
  onChange?(checked: boolean): void;
  thumbColor?: 'primary' | 'secondary' | 'dark' | 'negative' | 'positive';
};

const b = block('tumbler');

function Tumbler(props: Props) {
  const {
    size = 'm',
    labelBefore,
    labelAfter,
    onChange,
    checked: defaultChecked = false,
    thumbColor,
  } = props;
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  const handleButtonClick = () => {
    setChecked(prevChecked => !prevChecked);
  };
  const handleControlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };
  const controlID = useMemo(() => Math.random().toString(), []);

  useEffect(() => {
    onChange?.(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <span className={b({ size })}>
      {labelBefore && (
        <label className={b('label')} htmlFor={checked ? controlID : ''}>
          {labelBefore}
        </label>
      )}
      <button
        className={b('button', { checked, color: thumbColor })}
        type="button"
        onClick={handleButtonClick}
      />
      <input
        id={controlID}
        hidden
        className={b('control')}
        type="checkbox"
        autoComplete="off"
        checked={checked}
        onChange={handleControlChange}
      />
      {labelAfter && (
        <label className={b('label')} htmlFor={!checked ? controlID : ''}>
          {labelAfter}
        </label>
      )}
    </span>
  );
}

export { Tumbler as Component };
export type { Props as TumblerProps };
