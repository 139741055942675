import React from 'react';

import Slider, { SliderProps } from '@material-ui/core/Slider';

import { Text } from '../Text';

import block from 'bem-cn-lite';

import './style.scss';

export type { Mark } from '@material-ui/core/Slider';
type Props = {
  value: number;
  error?: string;
  onChange: (value: number) => void;
  disabled?: boolean;
} & Pick<SliderProps, Exclude<keyof SliderProps, 'value' | 'onChange'>>;

const b = block('range-input');

function RangeInput(props: Props) {
  const { max, min, marks, value, error, onChange, disabled } = props;

  const handleChange = (_: any, value: number | number[]) => {
    onChange(Array.isArray(value) ? value[0] : value);
  };

  return (
    <div className={b()}>
      <Slider
        classes={{
          root: b('slider', { error: Boolean(error) }),
          markLabel: b('mark-label'),
          markLabelActive: b('mark-label_active'),
        }}
        value={value}
        max={max}
        min={min}
        onChange={handleChange}
        marks={marks}
        disabled={disabled}
      />
      {error && (
        <Text className={b('error')} typography="text-s" color="error">
          {error}
        </Text>
      )}
    </div>
  );
}

export const Component = React.memo(RangeInput);
