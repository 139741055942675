import client, {
  GetUser,
  AddTw,
  UpdateUser,
  GetIsTwitterHandleUsed,
  GetIsDiscordHandleUsed,
  AddDiscord,
} from '../../providers/ApolloProviderContest';
import {
  CreateUserInput,
  CreateUserMutation,
  GetUserQuery,
  UpdateUserInput,
  UpdateUserMutation,
  User,
  UsersByTwitterHandleQuery,
  UsersByDiscordHandleQuery,
} from 'feedbackContestAPI/api';
import {
  createUser as createUserQuery,
  updateUser as updateUserQuery,
} from 'feedbackContestAPI/mutations';
import {
  getUser as getUserQuery,
  usersByDiscordHandle,
  usersByTwitterHandle,
} from 'feedbackContestAPI/queries';

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from 'aws-exports';

Amplify.configure(awsconfig);

type RedirectURLResponse = {
  redirectURL: string;
};

type TwitterUser = {
  name: string;
};

type DiscordUser = {
  username: string;
};

export async function getTwitterAuthURL() {
  return API.get(
    'authorization',
    '/authorization/twitter/auth_url',
    {},
  ) as Promise<RedirectURLResponse>;
}

export async function getDiscordAuthURL() {
  return API.get(
    'authorization',
    '/authorization/discord/auth_url',
    {},
  ) as Promise<RedirectURLResponse>;
}

export async function getTwitterUser(requestToken: string, verifier: string) {
  return API.get('authorization', '/authorization/twitter/user', {
    queryStringParameters: {
      requestToken,
      verifier,
    },
  }) as Promise<TwitterUser>;
}

export async function getDiscordUser(code: string) {
  return API.get('authorization', '/authorization/discord/user', {
    queryStringParameters: {
      code,
    },
  }) as Promise<DiscordUser>;
}

export async function getUser(wallet: string): Promise<User | null> {
  const ret = await client.query({
    query: GetUser,
    variables: {
      wallet: wallet ?? '',
    },
  });
  if (!ret || !(ret.data?.feedbackcontestvw?.length > 0)) return null;
  const data = ret.data.feedbackcontestvw[0];

  const user = {
    wallet: data.twallet || data.dwallet,
    twitterHandle: data.twitterhandle,
    discordHandle: data.discordhandle,
    createdAt: '',
    updatedAt: '',
  };
  return user;
  // const response = await (API.graphql(
  //   graphqlOperation(getUserQuery, { wallet }),
  // ) as Promise<{ data: GetUserQuery }>);
  // return response?.data?.getUser ?? null;
}

export async function createUserTw(user: CreateUserInput) {
  const ret = await client.mutate({
    mutation: AddTw,
    variables: {
      wallet: user.wallet,
      twitterHandle: user.twitterHandle,
    },
  });

  const data = ret?.data?.insert_feedbackcontest?.returning[0] || {};
  const newUser = {
    wallet: user.wallet,
    twitterHandle: user.twitterHandle,
    discordHandle: user.discordHandle,
    createdAt: '',
    updatedAt: '',
  };
  return newUser;

  //   return API.graphql(
  //     graphqlOperation(createUserQuery, { input: user }),
  //   ) as Promise<{ data: CreateUserMutation }>;
  // }
}

export async function createUserDiscord(user: CreateUserInput) {
  const ret = await client.mutate({
    mutation: AddDiscord,
    variables: {
      wallet: user.wallet,
      discordHandle: user.discordHandle,
    },
  });

  const data = ret?.data?.insert_feedbackcontest?.returning[0] || {};
  const newUser = {
    wallet: user.wallet,
    twitterHandle: user.twitterHandle,
    discordHandle: user.discordHandle,
    createdAt: '',
    updatedAt: '',
  };
  return newUser;

  //   return API.graphql(
  //     graphqlOperation(createUserQuery, { input: user }),
  //   ) as Promise<{ data: CreateUserMutation }>;
  // }
}

export async function updateUser(user: UpdateUserInput) {
  const ret = await client.mutate({
    mutation: UpdateUser,
    variables: {
      ...user,
    },
  });

  const data = ret?.data?.update_feedbackcontest?.returning[0] || {};
  return {
    data: {
      updateUser: {
        ...data,
      },
    },
  };

  // return API.graphql(
  //   graphqlOperation(updateUserQuery, { input: user }),
  // ) as Promise<{ data: UpdateUserMutation }>;
}

export async function isTwitterHandleUsed(
  twitterHandle: string,
): Promise<boolean> {
  const ret = await client.query({
    query: GetIsTwitterHandleUsed,
    variables: {
      twitterHandle: twitterHandle ?? '',
    },
  });

  return ret.data?.feedbackcontestvw?.length > 0;

  // const responseData = await (API.graphql({
  //   query: usersByTwitterHandle,
  //   variables: { twitterHandle },
  // }) as Promise<{ data: UsersByTwitterHandleQuery }>);
  // const users = responseData?.data?.usersByTwitterHandle?.items;
  // return !!users?.length;
}

export async function isDiscordHandleUsed(
  discordHandle: string,
): Promise<boolean> {
  const ret = await client.query({
    query: GetIsDiscordHandleUsed,
    variables: {
      discordHandle: discordHandle ?? '',
    },
  });

  return ret.data?.feedbackcontestvw?.length > 0;

  // const responseData = await (API.graphql({
  //   query: usersByDiscordHandle,
  //   variables: { discordHandle },
  // }) as Promise<{ data: UsersByDiscordHandleQuery }>);
  // const users = responseData?.data?.usersByDiscordHandle?.items;
  // return !!users?.length;
}
