import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import styled from 'styled-components';

const StyledChip = styled(Chip)`
  border-radius: 51px !important;
  font-size: 10px !important;
  height: 22px !important;
  font-family: inherit !important;
  background-color: ${({ theme, variation }) =>
    variation < 0
      ? theme.colors.danger.bg
      : theme.colors.success.bg} !important;

  color: ${({ theme, variation }) =>
    variation < 0
      ? theme.colors.danger.dark
      : theme.colors.success.dark} !important;
`;

const MarketSummaryItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  place-content: space-around;
  h3 {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: left;
  }
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-right: 8px;
  }
`;

type MarketSummaryItemProps = {
  value: string;
  label: string;
  variation: number;
};
export const MarketSummaryItem = ({
  value,
  label,
  variation,
}: MarketSummaryItemProps) => {
  return (
    <MarketSummaryItemStyled>
      <h3>{value}</h3>
      <Box>
        <label>{label}</label>
        <StyledChip
          label={`${variation > 0 ? '+' : ''}${variation}%`}
          variation={variation}
        ></StyledChip>
      </Box>
    </MarketSummaryItemStyled>
  );
};
