import React, { useEffect, useState } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { CircularProgress } from '@material-ui/core';

import { BN } from 'utils/bigNumber';
import { block } from 'utils/classname';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';
import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import { Text, Widget, Button, CurrencyInput } from 'components';

import { ConnectTwitter } from '../TwitterConnector';
import { Cards } from './Cards';
import { ContestModal } from './Modal';

import { getRewardsProof, RewardProofInfo } from 'config';
import { config } from 'config';
import { ChainId } from 'model';
import { TwitterFollowButton } from 'react-twitter-embed';

import './style.scss';

const b = block('contest-steps');

// check if it's ETH mainnet / ropsten / rinkeby
const isValidNetwork = async (web3: Web3Provider) => {
  const chainId = await (await web3.getNetwork()).chainId;
  return [ChainId.MAINNET, ChainId.ROPSTEN, ChainId.RINKEBY].includes(chainId);
};

export const ContestSteps = () => {
  const { enableRewardBuyingWidget, enableClainRewardWidget } = config.features;
  const { account, status, isConnecting, connect } = useAccount();
  const { merkleAPI, busdAPI, web3 } = useWeb3();
  const [rewardProofInfo, setRewardProofInfo] = useState<
    RewardProofInfo | undefined
  >(undefined);
  const [availableToBuy, setAvailableToBuy] = useState('');
  const [availableToClaim, setAvailableToClaim] = useState('');
  const [price, setPrice] = useState('');
  const [commitment, setCommitment] = useState<BN | undefined>(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const { addTransaction } = useTransaction();
  const [usdcBalance, setUsdcBalance] = useState<BN>(new BN(0));
  const [lastTx, setLastTx] = useState('');
  const { showError } = useErrorHandler(web3);

  const modal = useModal();
  const openModal = () => modal.present(<ContestModal />);

  const tokensToBuy = (usdc: BN, price: BN) => {
    if (!price || price.isNaN() || price.eq(0)) return new BN(0);
    return usdc.div(price);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!enableRewardBuyingWidget) return;
        if (!account) return;

        const ret = getRewardsProof(account);
        if (!ret) return;
        setRewardProofInfo(ret);
        availableToBuy === '' && setAvailableToBuy(ret.total);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [enableRewardBuyingWidget, account]);

  useEffect(() => {
    (async () => {
      try {
        if (!enableRewardBuyingWidget) return;
        if (!account) return;
        if (!merkleAPI.isReady) return;
        if (!busdAPI.isReady) return;
        const bal = await busdAPI.getBalance(account);
        setUsdcBalance(bal);

        const ret = await merkleAPI.checkAvailableToBuy();
        // if total === 0 we use the config file
        if (ret?.total === '0') return;
        setAvailableToBuy(ret?.available?.toString() ?? '');
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    enableRewardBuyingWidget,
    account,
    rewardProofInfo,
    merkleAPI.isReady,
    busdAPI.isReady,
    lastTx,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (!enableRewardBuyingWidget) return;
        if (!availableToBuy) return;
        if (!merkleAPI.isReady) return;
        const checkPriceFor = new BN(availableToBuy).integerValue();
        const ret = await merkleAPI.priceFor(checkPriceFor.toString());
        const unitPrice = new BN(ret ?? '0').div(new BN(availableToBuy));
        setPrice(unitPrice?.toString());
      } catch (e) {
        console.error(e);
      }
    })();
  }, [enableRewardBuyingWidget, merkleAPI, availableToBuy]);

  useEffect(() => {
    (async () => {
      try {
        if (!enableClainRewardWidget) return;
        if (!account) return;
        if (!merkleAPI.isReady) return;
        const ret = await merkleAPI.checkAvailableToClaim();
        // if total === 0 we use the config file
        if (ret === '0') return;
        setAvailableToClaim(ret ?? '');
      } catch (e) {
        console.error(e);
      }
    })();
  }, [enableClainRewardWidget, merkleAPI, account]);

  const handleBuyFormSubmit = async () => {
    if (!(await isValidNetwork(web3))) {
      openModal();
      return;
    }
    if (!commitment) {
      throw Error('No commitment provided');
    }

    const tokens = tokensToBuy(commitment || new BN(0), new BN(price));
    try {
      await setSubmitting(true);
      const tx = await buyRewards(tokens);
      addTransaction(
        tx?.hash,
        `Buying ${tokens.toString()} STRP equivalent to ${commitment?.toString()} USDC`,
      );
      await tx?.wait();
      setLastTx(tx?.hash || '');
    } catch (error: any) {
      showError(error);
    }

    await setSubmitting(false);
  };

  const buyRewards = async (commitment: BN) => {
    if (!rewardProofInfo) return;
    const tx = await busdAPI.approve(merkleAPI.address, commitment);
    tx !== '' && addTransaction(tx, `Approving USDC`);

    return await merkleAPI.buyStrp(
      commitment.toString(),
      rewardProofInfo.index,
      rewardProofInfo.total,
      rewardProofInfo.proof,
    );
  };

  const handleClaimFormSubmit = async () => {
    if (!(await isValidNetwork(web3))) {
      openModal();
      return;
    }
    try {
      await setSubmitting(true);
      const tx = await merkleAPI.claimRewards();
      addTransaction(tx, `Claim ${availableToClaim.toString()} STRP`);
    } catch (error: any) {
      showError(error);
    }
    await setSubmitting(false);
  };

  return (
    <article className={b()}>
      <Text as="h2" typography="heading-l">
        How to participate
      </Text>
      <section className={b('steps')}>
        <Widget.Component>
          <Text as="h3" typography="text-l">
            Step 1:
          </Text>
          <section className={b('step-content')}>
            <Button
              className={b('step-button')}
              disabled={isConnecting || account !== null}
              variant={status === 'connected' ? 'success' : 'primary'}
              onClick={connect}
            >
              connect wallet
            </Button>
            {account !== null && <Text typography="text-l">{account}</Text>}
          </section>
        </Widget.Component>

        <Widget.Component>
          <Text as="h3" typography="text-l">
            Step 2:
          </Text>
          <section className={b('step-content')}>
            <ConnectTwitter />
          </section>
          <Widget.SubSection>
            <div className={b('twitter-following')}>
              Follow our Twitter page{' '}
              <TwitterFollowButton screenName="StripsFinance" />
            </div>
          </Widget.SubSection>
        </Widget.Component>
        <Widget.Section>
          <Text typography="text-m">
            To learn more about feedback rewards, visit our medium{' '}
            <a
              href="https://strips-finance.medium.com/feedback-rewards-competition-c8c8fd7e0385"
              target="_blank"
              rel="noopener noreferrer"
            >
              page
            </a>
            .
          </Text>
        </Widget.Section>

        {enableRewardBuyingWidget && (
          <Widget.Component>
            <Cards
              availableToBuy={availableToBuy || '-'}
              priceFor={price || '-'}
            />
            <Widget.Section>
              <div className={b('commitment-form')}>
                <div className={b('input')}>
                  <CurrencyInput.Component
                    label="My commitment"
                    value={commitment?.toString() ?? null}
                    onChange={val => {
                      setCommitment(
                        val && val !== '' ? new BN(val) : undefined,
                      );
                    }}
                    disabled={
                      !availableToBuy || new BN(availableToBuy).isEqualTo(0)
                    }
                    rightPart={
                      <CurrencyInput.Limiter
                        currency="usdc"
                        value={usdcBalance}
                        disabled={
                          !availableToBuy || new BN(availableToBuy).isEqualTo(0)
                        }
                        onButtonClick={() => {
                          setCommitment(
                            availableToBuy !== '' && price !== ''
                              ? new BN(availableToBuy).multipliedBy(
                                  new BN(price),
                                )
                              : undefined,
                          );
                        }}
                      />
                    }
                    onClear={() => setCommitment(undefined)}
                  />
                </div>

                <Button
                  variant={'primary'}
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !commitment || commitment.eq(0)}
                  className={b('submit-button')}
                  onClick={handleBuyFormSubmit}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : 'Confirm'}
                </Button>
              </div>
            </Widget.Section>
          </Widget.Component>
        )}

        {enableClainRewardWidget && (
          <Widget.Component>
            <Cards availableToClaim={availableToClaim || '-'} />
            <Widget.Section>
              <div className={b('claim-form')}>
                <Button
                  variant={'primary'}
                  type="submit"
                  disabled={isSubmitting || availableToClaim === ''}
                  className={b('submit-claim-button')}
                  onClick={handleClaimFormSubmit}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : 'Claim'}
                </Button>
              </div>
            </Widget.Section>
          </Widget.Component>
        )}
      </section>
    </article>
  );
};
