import { Button } from 'components';

import { MarketWidget } from './MarketWidget';
import { Switch } from './Switch';
import { Toggle } from './Toggle';

import block from 'bem-cn-lite';
import { useMarkets } from 'graphqlAPI';

import './style.scss';

const b = block('farm-page');

export const Farm = () => {
  const { markets } = useMarkets();

  return (
    <div className={b()}>
      <header className={b('header')}>
        <h2 className={b('title')}>Stake LP tokens to earn STRP</h2>
        <div className={b('action-panel')}>
          <Button>Harvest all and Stake</Button>
          <div className={b('buttons')}>
            <Switch textLeft="10x" textRight="5x" />
            <Toggle label="Staked only" />
          </div>
        </div>
      </header>
      <main className={b('main')}>
        {markets.map(market => (
          <>
            <MarketWidget market={market} />
            <MarketWidget market={market} />
            <MarketWidget market={market} />
            <MarketWidget market={market} />
            <MarketWidget market={market} />
            <MarketWidget market={market} />
          </>
        ))}
      </main>
    </div>
  );
};
