import { CircularProgress } from '@material-ui/core';

import { useTransaction } from 'hooks/useTransaction';

import style from './style.module.scss';

export const PendingTransaction = () => {
  const { transactions } = useTransaction();

  return (
    <div className={style.pendingBox}>
      {Object.keys(transactions).length > 0 && (
        <div className={style.pending}>
          <CircularProgress size={20} />
          <span className={style.pendingText}>
            {Object.keys(transactions).length} Pending Transaction(s)
          </span>
        </div>
      )}
    </div>
  );
};
