import { Switch, SwitchProps } from '@material-ui/core';

import block from 'bem-cn-lite';

import './style.scss';

type Props = Omit<SwitchProps, 'classes' | 'disableRipple'> & {
  label?: string;
};

const b = block('toggle');

const switchClasses: SwitchProps['classes'] = {
  root: b('root'),
  track: b('track'),
  thumb: b('thumb'),
  switchBase: b('base'),
  checked: b({ checked: true }),
};

export const Toggle: React.FC<Props> = ({ label, ...props }) => (
  <div className={b()}>
    <Switch classes={switchClasses} disableRipple {...props} />
    {label && <span className={b('label')}>{label}</span>}
  </div>
);
