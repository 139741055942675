import React from 'react';

import { block } from 'utils/classname';

import { Widget, Modal } from 'components';

import { Form } from './views';

import { Position } from 'graphqlAPI';

import './style.scss';

type Props = {
  position: Position;
};

const b = block('change-position-collateral');

function ChangePositionCollateral({ position }: Props) {
  return (
    <Modal title="Change position collateral" size="small">
      <Widget.Component>
        <Form position={position} />
      </Widget.Component>
    </Modal>
  );
}

const Component = React.memo(ChangePositionCollateral);
export { Component as ChangePositionCollateral };
