import React from 'react';

import { block } from 'utils/classname';
import { fractionToPercent } from 'utils/formatters';

import { Button } from 'components';

import './style.scss';

type Props = {
  ratios: number[];
  currentCloseRatio: number;
  onButtonClick(value: number): void;
  disabled?: boolean;
};

const b = block('ratio-buttons');

function RatioButtons(props: Props) {
  const { ratios, currentCloseRatio, onButtonClick, disabled } = props;

  return (
    <div className={b()}>
      {ratios.map((ratio, i) => {
        return (
          <Button
            key={i}
            className={b('button')}
            type="button"
            size="small"
            onClick={() => onButtonClick(ratio)}
            disabled={ratio === null || ratio === currentCloseRatio || disabled}
          >
            {fractionToPercent(ratio, { minimumFractionDigits: 0 })}
          </Button>
        );
      })}
    </div>
  );
}

const Component = React.memo(RatioButtons);

export { Component as RatioButtons };
