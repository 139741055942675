import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';

import { BN } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import { CloseParams } from 'contracts/apiHooks';

import { Position } from 'graphqlAPI';
import { useQuery } from 'react-query';

type FormState = {
  amount: BN | null;
};

type Errors = Partial<{
  [error in keyof FormState]: string;
}>;

const validateAmount = (
  positionSize: BN | null,
  amount: BN | null,
  closeParams: CloseParams | null,
  closeRatio: BN,
  balance: BN | null,
): string | undefined => {
  if (amount === null || amount.isLessThanOrEqualTo(0)) {
    return 'Field is required';
  }
  if (positionSize && amount.isGreaterThan(positionSize)) {
    return 'Position size exceeded';
  }
  if (
    closeRatio.isLessThan(1) &&
    closeParams?.leftMargin.isLessThan(closeParams?.minimumMargin)
  ) {
    return 'Margin is too low to close your position, add more collateral, otherwise you will be liquidated';
  }
  const maxFee = closeParams?.fee
    ?.multipliedBy(FEE_APPROVEMENT_MULTIPLIER)
    .decimalPlaces(2, BN.ROUND_UP);
  if (maxFee && balance?.isLessThan(maxFee)) {
    return `No enough balance to pay fee, required: ${maxFee.toString()} minimum`;
  }
  return undefined;
};

const ZERO = new BN(0);
const ONE = new BN(1);
const FEE_APPROVEMENT_MULTIPLIER = 1.1;

export function useFormState(position: Position | null) {
  // console.log({collateral: position?.collateral.toString()});

  const { connect, areRequestsLocked } = useAccount();
  const { account } = useAccount();
  const { stripsAPI, busdAPI } = useWeb3();

  const [formState, setFormState] = useState<FormState & { isDirty: boolean }>({
    amount: null,
    isDirty: false,
  });
  const [errors, setErrors] = useState<Errors>({});
  const isValid = !Object.values(errors).some(Boolean);

  const { data: balance = null } = useQuery(
    ['balance', account],
    () => busdAPI.getBalance(account!),
    { refetchInterval: 5000, enabled: account !== null },
  );

  const [areCloseParamsCalculating, setAreCloseParamsCalculating] =
    useState<boolean>(false);
  const [closeParams, setCloseParams] = useState<CloseParams | null>(null);
  const positionSize = useMemo(
    () => position?.notional ?? null,
    [position?.notional],
  );

  async function loadCloseParams(
    marketAddress: string,
    account: string,
    closeRatio: BN,
  ) {
    const responseData = await stripsAPI.calcCloseParams(
      marketAddress,
      account,
      closeRatio,
    );
    if (responseData) {
      setCloseParams(responseData);
      setAreCloseParamsCalculating(false);
    }
  }

  const handleAmountChange = (value: string | null) => {
    setFormState(prevState => ({
      ...prevState,
      amount: value ? new BN(value) : null,
      isDirty: true,
    }));
  };

  const setAmount = (ratio: number = 1) => {
    const clampFraction = Math.max(0, Math.min(1, ratio));
    if (positionSize !== null) {
      setFormState(prevState => ({
        ...prevState,
        amount: positionSize.multipliedBy(clampFraction) ?? null,
        isDirty: true,
      }));
    }
  };

  const clearAmount = () =>
    setFormState(prevState => ({
      ...prevState,
      amount: null,
    }));

  const createSubmitHandler =
    (onSubmit: (formState: FormState) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      isValid && onSubmit(formState);
    };

  const closeRatio = useMemo(() => {
    if (!positionSize || positionSize.isEqualTo(ZERO) || !formState.amount) {
      return ZERO;
    }
    if (positionSize.isEqualTo(formState.amount)) {
      return ONE;
    }
    return formState.amount.dividedBy(positionSize);
  }, [positionSize, formState.amount]);

  const collateralRemaining = useMemo(() => {
    if (!position || !formState.isDirty) {
      return ZERO;
    }
    return (
      position.collateral.multipliedBy(new BN(1).minus(closeRatio)) ?? ZERO
    );
  }, [closeRatio, position]);

  const pnlReturned = useMemo(
    () => (closeParams ? closeParams.pnl : ZERO),
    [closeParams],
  );

  const totalReturned = useMemo(() => {
    const collateral = position?.collateral ?? ZERO;
    const pnl = closeParams ? closeParams.pnl : 0;
    return collateral.multipliedBy(closeRatio).plus(pnl) ?? ZERO;
  }, [closeRatio, closeParams, position]);

  const fee = useMemo(
    () => (closeParams ? closeParams.fee : ZERO),
    [closeParams],
  );

  useEffect(() => {
    setErrors(prevErrors => ({
      ...prevErrors,
      amount: validateAmount(
        positionSize,
        formState.amount,
        closeParams,
        closeRatio,
        balance,
      ),
    }));
  }, [balance, closeParams, closeRatio, formState, positionSize]);

  useEffect(() => {
    if (areRequestsLocked) {
      connect();
    } else if (formState.isDirty) {
      if (!position?.marketAddress || !account) return;
      setAreCloseParamsCalculating(true);
      loadCloseParams(position.marketAddress, account, closeRatio);
    }
  }, [
    account,
    areRequestsLocked,
    closeRatio.toString(),
    position?.marketAddress,
  ]);

  useLayoutEffect(() => {
    if (positionSize && formState.amount?.isGreaterThan(positionSize)) {
      setFormState(prevState => ({
        ...prevState,
        amount: positionSize,
      }));
    }
  }, [formState.amount, positionSize]);

  return {
    isDirty: formState.isDirty,
    isValid,
    errors,
    amount: formState.amount,
    positionSize,
    closeRatio,
    closeParams,
    collateralRemaining,
    pnlReturned,
    totalReturned,
    fee,
    areCloseParamsCalculating,
    setAmount,
    clearAmount,
    handleAmountChange,
    createSubmitHandler,
    FEE_APPROVEMENT_MULTIPLIER,
  } as const;
}
