/**
 * __usePortfolioQuery__
 *
 * To run a query within a React component, call `usePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioQuery({
 *   variables: {
 *      accountAddress: // value for 'accountAddress'
 *   },
 * });
 */
import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import {
  Exact,
  Maybe,
  Scalars,
  Position,
  InsuranceStake,
  InsuranceFund,
  SipToken,
  MarketStake,
  Market,
  SlpToken,
} from '../types';

export type PortfolioQueryVariables = Exact<{
  accountAddress: Scalars['ID'];
}>;

export type PortfolioQuery = { __typename?: 'Query' } & {
  account?: Maybe<
    { __typename?: 'Account' } & {
      positions: Array<
        { __typename?: 'Position' } & Pick<Position, 'collateral' | 'notional'>
      >;
      insuranceStakes: Array<
        { __typename?: 'InsuranceStake' } & Pick<
          InsuranceStake,
          'collateralTotal' | 'initialStakedPnl' | 'sipTotal'
        > & {
            insurance: { __typename?: 'InsuranceFund' } & Pick<
              InsuranceFund,
              'id' | 'stakedPnl'
            > & {
                token: { __typename?: 'SIPToken' } & Pick<
                  SipToken,
                  'totalSupply'
                >;
              };
          }
      >;
      marketStakes: Array<
        { __typename?: 'MarketStake' } & Pick<
          MarketStake,
          'collateralTotal' | 'initialStakedPnl' | 'slpTotal'
        > & {
            market: { __typename?: 'Market' } & Pick<
              Market,
              'id' | 'stakedPnl'
            > & {
                token: { __typename?: 'SLPToken' } & Pick<
                  SlpToken,
                  'totalSupply'
                >;
              };
          }
      >;
    }
  >;
};

export const PortfolioDocument = gql`
  query account($accountAddress: ID!) {
    account(id: $accountAddress) {
      positions {
        collateral
        notional
      }
      marketStakes {
        collateralTotal
        slpTotal
        initialStakedPnl
        market {
          id
          stakedPnl
          token {
            totalSupply
          }
        }
      }
      insuranceStakes {
        collateralTotal
        sipTotal
        initialStakedPnl
        insurance {
          id
          stakedPnl
          token {
            totalSupply
          }
        }
      }
    }
  }
`;

const defaultOptions = {};

export function usePortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<PortfolioQuery, PortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioQuery, PortfolioQueryVariables>(
    PortfolioDocument,
    options,
  );
}
export function usePortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioQuery,
    PortfolioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioQuery, PortfolioQueryVariables>(
    PortfolioDocument,
    options,
  );
}
export type PortfolioQueryHookResult = ReturnType<typeof usePortfolioQuery>;
export type PortfolioLazyQueryHookResult = ReturnType<
  typeof usePortfolioLazyQuery
>;
export type PortfolioQueryResult = Apollo.QueryResult<
  PortfolioQuery,
  PortfolioQueryVariables
>;
