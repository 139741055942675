import { BN, zero, formatPercent } from 'utils/bigNumber';

import block from 'bem-cn-lite';

import './style.scss';

type Props = {
  longStakes: BN;
  shortStakes: BN;
};

const b = block('stakes-ratio');

export const StakesRatio: React.FC<Props> = ({ longStakes, shortStakes }) => {
  const totalDeposits = longStakes.plus(shortStakes);
  const longWidth = longStakes.isGreaterThan(0)
    ? longStakes.div(totalDeposits)
    : zero;
  const shortWidth = shortStakes.isGreaterThan(0)
    ? shortStakes.div(totalDeposits)
    : zero;

  return (
    <div className={b()}>
      <span className={b('label', { type: 'long' })}>
        LONG {formatPercent(longWidth)}%
      </span>
      <div
        className={b('bars-container')}
        style={{
          gridTemplateColumns: `${1 + longWidth.times(100).toNumber()}fr ${
            1 + shortWidth.times(100).toNumber()
          }fr`,
        }}
      >
        <div className={b('bar', { type: 'long' })} />
        <div className={b('bar', { type: 'short' })} />
      </div>
      <span className={b('label', { type: 'short' })}>
        {formatPercent(shortWidth)}% SHORT
      </span>
    </div>
  );
};
