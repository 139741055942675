import React from 'react';

import { ExpansionPanel, MarketName } from 'components';

import { Market } from 'model';

function ExpansionPanelHeader({ data }: ExpansionPanel.HeaderProps<Market>) {
  return <MarketName marketID={data.id} withAssetSymbol />;
}

const Component = React.memo(ExpansionPanelHeader);

export { Component as ExpansionPanelHeader };
