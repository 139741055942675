import React from 'react';

import { block } from 'utils/classname';

import { RangeInput, Text } from 'components';

import './style.scss';

const b = block('leverage');

type Props = {
  value: number;
  error?: string;
  onChange: (value: number) => void;
  disabled?: boolean;
};

const marks: RangeInput.Mark[] = [
  { value: 1, label: '1x' },
  { value: 5, label: '5x' },
  { value: 10, label: '10x' },
];

function Leverage({ value, error, onChange, disabled }: Props) {
  return (
    <div className={b({ error: Boolean(error) })}>
      <div className={b('range-input')}>
        <RangeInput.Component
          value={value}
          error={error}
          onChange={onChange}
          min={1}
          max={10}
          marks={marks}
          disabled={disabled}
        />
      </div>
      <Text className={b('text')}>{`${value}x`}</Text>
    </div>
  );
}

const Component = React.memo(Leverage);
export { Component as Leverage };
