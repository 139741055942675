import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { block } from 'utils/classname';

import { Button } from 'components';

import './style.scss';

type Props = {
  onClick(): void;
};

const b = block('snack-close-button');

export function SnackCloseButton({ onClick }: Props) {
  return (
    <Button onClick={onClick} variant="clear" className={b()}>
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );
}
