import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';

import { Preloader, Button } from 'components';

import { WalletMenu } from '../WalletMenu';
import { WalletModal } from 'features/modals/walletSelectModal';

import style from './style.module.scss';

export const UserBlock = () => {
  const { status, isConnecting } = useAccount();

  const modal = useModal();
  const handleConnectWallet = () => modal.present(<WalletModal />);

  const content = (() => {
    if (isConnecting) {
      return (
        <Button disabled={isConnecting} variant="dark">
          <Preloader />
        </Button>
      );
    }

    if (status === 'connected') {
      return <WalletMenu />;
    }

    return (
      <Button
        disabled={isConnecting}
        variant="primary"
        onClick={handleConnectWallet}
      >
        Connect to a wallet
      </Button>
    );
  })();

  return <div className={style.userBlock}>{content}</div>;
};
