import { useEffect, useState } from 'react';

import { useAccount } from 'hooks/useAccount';

import { useWeb3API } from 'contracts/apiHooks';

import { useAccountData } from 'graphqlAPI';
import { useQuery } from 'react-query';

export function useModel() {
  const [latestBlock, setLatestBlock] = useState(0);
  const { account } = useAccount();
  const { stripsAPI, web3 } = useWeb3API();
  const { data: realTimeData = null, refetch: refetchRealTimeData } = useQuery(
    ['realTimeData', account],
    () => stripsAPI.getRealTimeData(account!),
    { refetchInterval: 10000, enabled: account !== null },
  );

  const {
    positions,
    block,
    refresh: refetchPositions,
    loading: arePositionsLoading,
    error,
  } = useAccountData();

  useEffect(() => {
    (async () => {
      // @todo: block < latest is possible alaways true, so we need to decide some criteria.
      // Options:
      // 1- some magic number, not the best but better than just the graph out of the box
      // 2- web3 query some event we know is trigger so use that block number instead of latest

      try {
        const blockNumber = await web3.getBlockNumber();
        setLatestBlock(blockNumber);
      } catch (e) {}
    })();
  }, [latestBlock]);

  const useGraphData = !error || block < latestBlock;

  useEffect(() => {
    if (account) {
      refetchRealTimeData();
    }
  }, [error, positions, account]);

  return {
    realTimeData,
    arePositionsLoading,
    refetchPositions,
    positions: useGraphData ? positions : realTimeData?.positions,
  };
}
