import { useCallback, useEffect } from 'react';

import { NetworkStatus } from '@apollo/client/core/networkStatus';

import { BN, from256, DECIMAL_PLACES } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useWeb3 } from 'hooks/useWeb3';

import { usePortfolioLazyQuery, PortfolioQuery } from './usePortfolioQuery';
import { RealTimeData } from 'contracts/apiHooks';

import { useQuery } from 'react-query';

const convertToPortfolioValues = (
  response?: PortfolioQuery,
  pnlData?: RealTimeData['pnlData'],
) => {
  const zero = new BN(0);
  const positions = response?.account?.positions ?? [];
  const marketStakes = response?.account?.marketStakes ?? [];
  const insuranceStakes = response?.account?.insuranceStakes ?? [];

  const notionalsSum =
    positions.reduce((acc, x) => acc.plus(from256(x.notional)), zero) ?? zero;
  const collateralsSum =
    positions.reduce((acc, x) => acc.plus(from256(x.collateral)), zero) ?? zero;
  const marketStakingCollateralsSum =
    marketStakes.reduce(
      (acc, x) => acc.plus(from256(x.collateralTotal)),
      zero,
    ) ?? zero;
  const insuranceStakingCollateralsSum =
    insuranceStakes.reduce(
      (acc, x) => acc.plus(from256(x.collateralTotal)),
      zero,
    ) ?? zero;
  const marketStakingPnlSum =
    marketStakes.reduce((acc, x) => {
      const slpTotal = from256(x.slpTotal);
      const totalSupply = from256(x.market.token.totalSupply);
      const stakedPnl = from256(x.market.stakedPnl);
      const initialStakedPnl = from256(x.initialStakedPnl);
      if (totalSupply.isEqualTo(zero)) {
        return zero;
      }
      return acc.plus(
        slpTotal
          .dividedBy(totalSupply)
          .multipliedBy(stakedPnl.minus(initialStakedPnl)),
      );
    }, zero) ?? zero;
  const insuranceStakingPnlSum =
    insuranceStakes.reduce((acc, x) => {
      const sipTotal = from256(x.sipTotal);
      const totalSupply = from256(x.insurance.token.totalSupply);
      const stakedPnl = from256(x.insurance.stakedPnl);
      const initialStakedPnl = from256(x.initialStakedPnl);
      if (totalSupply.isEqualTo(zero)) {
        return zero;
      }
      return acc.plus(
        sipTotal
          .dividedBy(totalSupply)
          .multipliedBy(stakedPnl.minus(initialStakedPnl))
          .decimalPlaces(DECIMAL_PLACES, BN.ROUND_DOWN),
      );
    }, zero) ?? zero;
  const totalTradingPnl = pnlData?.pnl ?? zero;
  const totalPortfolioValue = collateralsSum
    .plus(marketStakingCollateralsSum)
    .plus(insuranceStakingCollateralsSum)
    .plus(marketStakingPnlSum)
    .plus(insuranceStakingPnlSum)
    .plus(totalTradingPnl)
    .decimalPlaces(DECIMAL_PLACES, BN.ROUND_DOWN);

  const totalMarginRatio = notionalsSum.isEqualTo(zero)
    ? zero
    : collateralsSum.plus(totalTradingPnl).dividedBy(notionalsSum);
  const leverage = collateralsSum.isEqualTo(zero)
    ? zero
    : notionalsSum.dividedBy(collateralsSum);
  const totalExposure = notionalsSum;

  return {
    totalPortfolioValue,
    totalMarginRatio,
    leverage,
    totalExposure,
  };
};

export function usePortfolioData() {
  const { showError } = useErrorHandler();
  const { account } = useAccount();
  const [
    request,
    { data, loading, refetch, startPolling, stopPolling, networkStatus },
  ] = usePortfolioLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { stripsAPI } = useWeb3();
  const { data: realTimeData = null } = useQuery(
    ['realTimeData', account],
    () => stripsAPI.getRealTimeData(account!),
    { refetchInterval: 10000, enabled: account !== null },
  );

  const refresh = useCallback(() => {
    if (account && refetch) {
      refetch({ accountAddress: account });
    }
  }, [account, refetch]);

  useEffect(() => {
    account && request({ variables: { accountAddress: account } });
  }, [account, request]);

  useEffect(() => {
    if (startPolling && stopPolling) {
      startPolling(10000);
      return stopPolling;
    }
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.error) {
      showError({ message: 'Network unavailable. Trying to connect...' });
    }
  }, [networkStatus]);

  return {
    refresh,
    loading,
    ...convertToPortfolioValues(data, realTimeData?.pnlData),
  };
}
