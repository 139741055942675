import buildRouteTree from 'build-route-tree';

export const routes = buildRouteTree({
  overview: null,
  'exchange-pro': null,
  'exchange-lite': null,
  liquidity: null,
  insurance: null,
  'receive-currency': null,
  'feedback-contest': null,
  'leverage-yield-farm': null,
  farm: null,
});
