import { useState, useEffect } from 'react';

import { useWeb3React } from '@web3-react/core';

import { injected } from '../connectors';

export function useEagerConnect() {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    const connect = async () => {
      try {
        const isAuthorized = await injected.isAuthorized();
        if (isAuthorized) {
          await activate(injected, undefined, true);
        }
        setTried(true);
      } catch (error: any) {
        setTried(true);
        console.error(error);
      }
    };

    connect();
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}
