import { useCallback, useEffect } from 'react';

import { NetworkStatus } from '@apollo/client/core/networkStatus';

import { BN, from256 } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';

import { useAccountLazyQuery, AccountQuery } from '../types';

import memoize from 'memoize-one';

type Position = {
  id: string;
  marketAddress: string;
  fixedRate: BN;
  floatingRate: BN;
  positionType: 'long' | 'short';
  notional: BN;
  collateral: BN;
  leverage: number;
  initialPrice: BN;
};

type MarketStake = {
  collateralTotal: BN;
  initialStakedPnl: BN;
  slpTotal: BN;
};

type InsuranceStake = {
  collateralTotal: BN;
  initialStakedPnl: BN;
  sipTotal: BN;
};

const emptyArray = [];
const emptyObject = {};

const convertPositions = memoize(
  (response?: AccountQuery): Position[] =>
    response?.account?.positions?.map(x => ({
      id: x.id,
      marketAddress: x.market.id,
      fixedRate: from256(x.market.price?.marketPrice ?? 0),
      floatingRate: from256(x.market.price?.oraclePrice ?? 0),
      collateral: from256(x.collateral),
      leverage: Number(x.leverage),
      notional: from256(x.notional),
      initialPrice: from256(x.initialPrice ?? 0),
      positionType: x.isLong ? 'long' : 'short',
    })) ?? emptyArray,
);

const convertMarketStakes = memoize(
  (response?: AccountQuery): { [marketID: string]: MarketStake } =>
    response?.account?.marketStakes?.reduce((acc, x) => {
      acc[x.market.id] = {
        collateralTotal: from256(x?.collateralTotal ?? 0),
        initialStakedPnl: from256(x?.initialStakedPnl ?? 0),
        slpTotal: from256(x?.slpTotal ?? 0),
      };
      return acc;
    }, {}) ?? emptyObject,
);

const convertInsuranceStakes = memoize(
  (response?: AccountQuery): InsuranceStake | null =>
    response?.account?.insuranceStakes.map(x => ({
      collateralTotal: from256(x.collateralTotal ?? 0),
      initialStakedPnl: from256(x.initialStakedPnl ?? 0),
      sipTotal: from256(x.sipTotal ?? 0),
    }))[0] ?? null,
);

export function useAccountData() {
  const { showError } = useErrorHandler();
  const { account } = useAccount();
  const [
    request,
    { data, loading, previousData, startPolling, stopPolling, networkStatus },
  ] = useAccountLazyQuery({ fetchPolicy: 'cache-and-network' });

  const refresh = useCallback(() => {
    if (account && request) {
      account && request({ variables: { accountAddress: account } });
    }
  }, [account, request]);

  useEffect(() => {
    account && request({ variables: { accountAddress: account } });
  }, [account, request]);

  useEffect(() => {
    if (startPolling && stopPolling) {
      startPolling(10000);
      return stopPolling;
    }
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.error) {
      showError({ message: 'Network unavailable. Trying to connect...' });
    }
  }, [networkStatus]);

  return {
    refresh,
    loading,
    //@ts-ignore
    block: data?._meta?.block?.number,
    positions: convertPositions(data ?? previousData),
    marketStakes: convertMarketStakes(data ?? previousData),
    insuranceStake: convertInsuranceStakes(data ?? previousData),
    error: networkStatus === NetworkStatus.error,
  };
}
export type { Position, MarketStake };
