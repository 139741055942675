import React, { useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { BN } from 'utils/bigNumber';
import { block } from 'utils/classname';
import { formatCurrency } from 'utils/formatters';

import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import {
  Widget,
  Button,
  CurrencyInput,
  DataGrid,
  DataGridColumn,
  Text,
  Currency,
  Tumbler,
  NumberTooltip,
} from 'components';

import { useFormState } from './useForm';

import { Position } from 'graphqlAPI';
import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('change-position-collateral-form');

type Props = {
  position: Position;
};

function getSubmitButtonText(
  areRequestsLocked: boolean,
  action: 'add' | 'remove',
) {
  if (areRequestsLocked) return 'CONNECT';
  if (action === 'add') return 'ADD COLLATERAL';
  return 'REMOVE COLLATERAL';
}

function Form({ position }: Props) {
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { connect, areRequestsLocked } = useAccount();
  const { addTransaction } = useTransaction();
  const { stripsAPI, busdAPI } = useWeb3();
  const {
    isDirty,
    isValid,
    errors,
    amount,
    defaultAmount,
    balance,
    maxAmount,
    setMaxAmount,
    gridData,
    action,
    handleActionChange,
    handleAmountChange,
    clearAmount,
    createSubmitHandler,
  } = useFormState(position);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleFormSubmit = createSubmitHandler(() => {
    if (areRequestsLocked) {
      connect();
    } else {
      Promise.resolve()
        .then(() => setSubmitting(true))
        .then(() => {
          if (position && amount) {
            if (action === 'add') {
              return addCollateral(position.marketAddress, new BN(amount));
            } else {
              return removeCollateral(position.marketAddress, new BN(amount));
            }
          }
          return new Promise((_, reject) =>
            reject(Error('Position or Amount is not provided')),
          );
        })
        .then(tx => {
          addTransaction(tx, `Change position collateral`);
        })
        .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => setSubmitting(false))
        .finally(() => modal.pop());
    }
  });

  const addCollateral = (marketAddress: string, collateral: BN) =>
    busdAPI
      .approve(stripsAPI.address, collateral.multipliedBy(1.1))
      .then(tx => {
        tx !== '' && addTransaction(tx, `Approving BUSD`);
      })
      .then(() => stripsAPI.addCollateral(marketAddress, collateral));

  const removeCollateral = (marketAddress: string, collateral: BN) =>
    stripsAPI.removeCollateral(marketAddress, collateral);

  const gridColumns: DataGridColumn[] = useMemo(() => {
    return [
      [
        { label: '1', minWidth: 'auto' },
        { label: '2', minWidth: '100px' },
        { label: '3', minWidth: 'auto', align: 'end', disablePadding: 'right' },
      ],
    ];
  }, []);

  const formattedGridData = useMemo(() => {
    return gridData.map(([label, value, currency]) => [
      <Text typography="heading-l" color="secondary">
        {label}
      </Text>,
      label === 'Collateral' ? (
        <NumberTooltip.Component
          title={<Text typography="text-s">{value}</Text>}
          placement="left"
        >
          <Text typography="text-m" color="secondary">
            {formatCurrency(value)}
          </Text>
        </NumberTooltip.Component>
      ) : (
        <Text typography="text-m" color="secondary">
          {value}
        </Text>
      ),
      <Currency.Component currency={currency} />,
    ]);
  }, [gridData]);

  const amountInputRightPart = useMemo(
    () =>
      action === 'add' ? (
        <CurrencyInput.Limiter
          value={balance}
          label="Balance"
          onButtonClick={() => setMaxAmount()}
          currency="busd"
          disabled={!!maxAmount && amount?.isEqualTo(maxAmount)}
        />
      ) : (
        <CurrencyInput.Simple currency="busd" />
      ),
    [action, amount, balance, maxAmount, setMaxAmount],
  );

  const handleActionTumblerChange = (checked: boolean) => {
    handleActionChange(checked ? 'remove' : 'add');
  };

  return (
    <div className={b()}>
      <form onSubmit={handleFormSubmit}>
        <Widget.SubSection align="end">
          <Tumbler.Component
            size="s"
            labelBefore="Add"
            labelAfter="Remove"
            onChange={handleActionTumblerChange}
            thumbColor={action === 'remove' ? 'negative' : 'positive'}
          />
        </Widget.SubSection>
        <Widget.SubSection>
          <CurrencyInput.Component
            label="Amount"
            value={
              isDirty
                ? amount?.toString() ?? null
                : defaultAmount?.toString() ?? null
            }
            error={isDirty ? errors.amount : undefined}
            onChange={handleAmountChange}
            onClear={clearAmount}
            rightPart={amountInputRightPart}
          />
        </Widget.SubSection>
        <Widget.SubSection>
          <DataGrid
            columns={gridColumns}
            data={formattedGridData}
            hasHead={false}
          />
        </Widget.SubSection>
        <Widget.SubSection>
          <Button
            variant={action === 'remove' ? 'danger' : 'success'}
            type="submit"
            disabled={isSubmitting || !isValid}
            className={b('submit-button')}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              getSubmitButtonText(areRequestsLocked, action)
            )}
          </Button>
        </Widget.SubSection>
      </form>
    </div>
  );
}

const Component = React.memo(Form);
export { Component as Form };
