import React, { useMemo } from 'react';

import { block } from 'utils/classname';

import { DataGrid, DataGridColumn, Text } from 'components';

import './style.scss';

const b = block('rewards');

function Rewards() {
  const gridColumns: DataGridColumn[] = useMemo(() => {
    return [
      [
        {
          label: 'Actions',
          minWidth: '370px',
        },
        {
          label: 'Points',
          minWidth: '100px',
        },
        {
          label: 'Max per user',
          minWidth: '125px',
        },
      ],
    ].map(row =>
      row.map(cell => ({
        ...cell,
        label: (
          <Text typography="heading-s" color="primary">
            {cell.label}
          </Text>
        ),
      })),
    );
  }, []);
  const gridData = useMemo<
    [React.ReactNode, React.ReactNode, React.ReactNode][]
  >(
    () => [
      ['Follow and enable notifications to Strips Finance', 50, 50],
      [
        <>
          Like and retweet our promotion{' '}
          <a
            href="https://twitter.com/StripsFinance/status/1423537950910869504"
            target="_blank"
            rel="noopener noreferrer"
          >
            post
          </a>
        </>,
        50,
        50,
      ],
      ['Posting feedback (with > 3 votes)', 100, 1000],
      ['Feedback accepted by the team', 500, 'unlimited'],
    ],
    [],
  );
  const formattedGridData = useMemo(() => {
    return gridData.map(row =>
      row.map(cell => (
        <Text typography="text-m" color="primary">
          {cell}
        </Text>
      )),
    );
  }, [gridData]);

  return (
    <article className={b()}>
      <Text as="h2" typography="heading-l">
        Rewards
      </Text>
      <DataGrid columns={gridColumns} data={formattedGridData} />
      <section className={b('comments')}>
        <Text typography="text-m">1 point = 1 token discount</Text>
      </section>
    </article>
  );
}

const Component = React.memo(Rewards);
export { Component as Rewards };
