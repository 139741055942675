import React, { useCallback, useEffect, useRef, useState } from 'react';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { block } from 'utils/classname';

import { createPortal } from 'react-dom';

import './style.scss';

const b = block('tooltip');

type Size = 'm';

type Props = {
  text: string;
  size?: Size;
};

function getPosition(rect: DOMRect): React.CSSProperties {
  return {
    left: rect.left - 10,
    top: rect.bottom + 10,
  };
}

function Tooltip({ text, size = 'm' }: Props) {
  const [isActive, setIsActive] = useState<boolean>(false);

  const [positionStyles, setPositionStyles] = useState<React.CSSProperties>();

  const handleMouseEnter = useCallback(() => {
    setIsActive(true);

    if (ref.current) {
      setPositionStyles(getPosition(ref.current.getBoundingClientRect()));
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsActive(false);
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={b()}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
      {isActive &&
        createPortal(
          <div className={b('text', { size })} style={positionStyles}>
            {text}
          </div>,
          document.body,
        )}
    </div>
  );
}

const Component = React.memo(Tooltip);

export { Component as Tooltip };
