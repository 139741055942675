import { TVChartContainer } from 'components/TVChartContainer';

import styled from 'styled-components';

const HistoricalPriceStyled = styled.div`
  display: flex;
  flex: 1;
  margin: 0 0 35px 0;
  & > div {
    flex: 1;
    height: initial;
  }
`;

export const HistoricalPrice = () => {
  return (
    <HistoricalPriceStyled>
      <TVChartContainer />
    </HistoricalPriceStyled>
  );
};
