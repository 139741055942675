import { useCallback, useEffect, useState, useRef } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { BN, from256, to256 } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';
import { calcGas } from 'utils/gas';

import { useErrorHandler } from 'hooks/useErrorHandler';

import type { InsurancePool } from '../types';
import { InsurancePool__factory } from '../types';

export type InsuranceAPI = ReturnType<typeof useInsuranceAPI>;

function createContract(address: string, web3: Web3Provider, account: string) {
  return getContract<InsurancePool>(
    InsurancePool__factory,
    address,
    web3,
    account,
  );
}

export function useInsuranceAPI(
  web3: Web3Provider,
  address: string,
  account: string,
) {
  const { showError } = useErrorHandler(web3);
  const saveAccount = useRef(account);
  const [contract, setContract] = useState<InsurancePool>(
    createContract(address, web3, account),
  );

  useEffect(() => {
    setContract(createContract(address, web3, account));
  }, [web3, address]);

  useEffect(() => {
    saveAccount.current = account;
  }, [account]);

  const stake = useCallback(
    async (amount: BN) => {
      try {
        const gasLimitEstimated = await contract.estimateGas.stake(
          to256(amount).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.stake(to256(amount).toString(), {
          gasLimit,
        });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const unstake = useCallback(
    async (amount: BN) => {
      try {
        const gasLimitEstimated = await contract.estimateGas.unstake(
          to256(amount).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.unstake(to256(amount).toString(), {
          gasLimit,
        });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const harvest = useCallback(
    async () => {
      try {
        const gasLimitEstimated = await contract.estimateGas.harvest();
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.harvest({
          gasLimit,
        });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const calcUnstakeProfit = useCallback(
    async (account: string, amount: BN) => {
      try {
        const ret = await contract.calcUnstakeProfit(
          account,
          to256(amount).toString(),
        );
        //@todo: this should be updated by whoever updates the widget.
        // it is no longer necessary
        return convertUnstackeProfit({
          profit: ret.profit.toString(),
          unstake_amount: ret.unstake_amount.toString(),
          fee: ret.fee.toString(),
          periodLeft: ret.periodLeft.toString(),
        });
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  return {
    address,
    stake,
    unstake,
    harvest,
    calcUnstakeProfit,
  } as const;
}

export type InsuranceUnstackeProfitInput = {
  profit: string;
  unstake_amount: string;
  fee: string;
  periodLeft: string;
};

export type InsuranceUnstackeProfit = {
  profit: BN;
  fee: BN;
  unstakeAmount: BN;
  periodLeft: number;
};

//@todo: this seems unnecesary complicated and now we return BigNumber from the contract so no need any transformation
function convertUnstackeProfit(
  params: InsuranceUnstackeProfitInput,
): InsuranceUnstackeProfit {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      const newKey = key === 'unstake_amount' ? 'unstakeAmount' : key;
      return [newKey, key === 'periodLeft' ? Number(value) : from256(value)];
    }),
  ) as InsuranceUnstackeProfit;
}
