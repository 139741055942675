import React, { useEffect, useMemo, useState } from 'react';

import { block } from 'utils/classname';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import { Widget, Text } from 'components';

import { Cards, Earned, Staked } from './views';
import { MarketUnstackeProfit } from 'contracts/apiHooks';

import { useAccountData, useInsurance } from 'graphqlAPI';

import './style.scss';

const b = block('insurance-widget');

function InsuranceWidget() {
  const { insurance } = useInsurance();
  const { insuranceStake } = useAccountData();
  const { insuranceAPI } = useWeb3();
  const { account } = useAccount();
  const [unstakeProfit, setUnstakeProfit] =
    useState<MarketUnstackeProfit | null>(null);
  const sipTotal = useMemo(() => {
    if (!insuranceStake) return null;
    return insuranceStake.sipTotal;
  }, [insuranceStake?.sipTotal?.toString() ?? null]);
  const busdEarned = unstakeProfit?.profit ?? null;

  async function loadUnstakeProfit() {
    if (!account || !sipTotal) return;
    const responseData = await insuranceAPI.calcUnstakeProfit(
      account,
      sipTotal,
    );
    setUnstakeProfit(responseData ?? null);
  }

  useEffect(() => {
    loadUnstakeProfit();
  }, [account, sipTotal]);

  return (
    <div className={b('container')}>
      <Widget.Component className={b()}>
        <Text as="h2" typography="heading-l">
          Strips Insurance Pool
        </Text>
        <Text color="secondary">Stake BUSD to earn</Text>
        <div className={b('value-cards')}>
          <Cards liquidity={insurance ? insurance.liquidity.toString() : ''} />
        </div>
        <div className={b('earned')}>
          <Earned busdEarned={busdEarned} />
        </div>
        <div className={b('staked')}>
          <Staked
            collateral={
              insuranceStake ? insuranceStake.collateralTotal.toString() : null
            }
          />
        </div>
      </Widget.Component>
    </div>
  );
}

const Component = React.memo(InsuranceWidget);

export { Component as InsuranceWidget };
