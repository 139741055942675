import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';

interface Trade {
  positive: boolean;
  price: string;
  notional: string;
  side: 'long' | 'short';
}

const SAMPLE: Trade[] = [
  {
    positive: true,
    price: '65.00%',
    notional: '50.000',
    side: 'long',
  },
  {
    positive: false,
    price: '65.00%',
    notional: '50.000',
    side: 'long',
  },
  {
    positive: true,
    price: '65.00%',
    notional: '50.000',
    side: 'long',
  },
  {
    positive: false,
    price: '65.00%',
    notional: '50.000',
    side: 'long',
  },
];

const StyledTable = styled.table`
  border-collapse: collapse;

  tr,
  th {
    height: 30px;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 22px;
  }

  thead tr {
    background: ${({ theme }) => theme.colors.offBlack.ash};
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    text-align: left;
  }

  th:first-child {
    border-radius: 6px 0 0 6px;
    padding: 3px;
  }

  th:last-child {
    border-radius: 0 6px 6px 0;
    padding: 3px;
  }
`;

const PositiveBadge = styled.td`
  padding: 0 0 0 20px;
  color: ${({ positive, theme }) =>
    positive ? theme.colors.success.base : theme.colors.danger.base};
`;

export const RecentTradesTable = ({ trades }: { trades: Trade[] }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th key={0}></th>
          <th key={1}>Price (USD)</th>
          <th key={2}>Notional</th>
          <th key={3}>Side</th>
        </tr>
      </thead>
      <tbody>
        {trades.map((item, index) => (
          <tr key={index}>
            <PositiveBadge key={index} positive={item.positive}>
              <FontAwesomeIcon
                icon={item.positive ? faChevronUp : faChevronDown}
              />
            </PositiveBadge>
            <td key={index}>{item.price}</td>
            <td key={index}>{item.notional}</td>
            <td key={index}>{item.side}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const RecentTradesStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  margin: 0 0 39px 32px;
`;

export const RecentTrades = () => {
  return (
    <RecentTradesStyled>
      <RecentTradesTable trades={SAMPLE} />
    </RecentTradesStyled>
  );
};
