import block from 'bem-cn-lite';

import './style.scss';

const b = block('switch');

type Props = {
  textLeft: string;
  textRight: string;
};

export const Switch: React.FC<Props> = ({ textLeft, textRight }) => (
  <label className={b()}>
    <div className={b('track')}>
      <span className={b('text', { position: 'left' })}>{textLeft}</span>
      <input className={b('input')} type="checkbox" />
      <span className={b('thumb')} />
      <span className={b('text', { position: 'right' })}>{textRight}</span>
    </div>
  </label>
);
