import { useEffect, useState } from 'react';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useOpenWindow } from 'hooks/useOpenWindow';

import {
  getTwitterAuthURL,
  getTwitterUser,
  createUserTw,
  isTwitterHandleUsed,
} from '../../api';
import { useFeatureStore } from '../../context/context';

export function useTwitterAuth() {
  const { account } = useAccount();
  const { showError } = useErrorHandler();
  const { user, setUser } = useFeatureStore();
  const [isConnecting, setConnecting] = useState<boolean>(false);
  const { childWindow, open, close } = useOpenWindow({
    onParamsGet: handleParamsGet,
  });

  const authWithTwitter = () => {
    setConnecting(true);
    getTwitterAuthURL().then(({ redirectURL }) => {
      open(redirectURL);
    });
  };

  const connectTwitter = (requestToken: string, verifier: string) => {
    if (account) {
      setConnecting(true);
      getTwitterUser(requestToken, verifier)
        .then(ret => {
          //@ts-ignore
          const { screen_name } = ret;
          return isTwitterHandleUsed(screen_name)
            .then(isAccountUsed => {
              if (isAccountUsed) {
                throw new Error(
                  `Twitter account "${screen_name}" already used`,
                );
              }
            })
            .then(() =>
              createUserTw({
                wallet: account,
                twitterHandle: screen_name,
                discordHandle: user?.discordHandle,
              }).then(ret => setUser(ret)),
            );
        })
        .catch(showError)
        .finally(() => setConnecting(false));
    }
  };

  function handleParamsGet(params: {
    oauthToken: string;
    oauthVerifier: string;
  }) {
    if ('oauthToken' in params && 'oauthVerifier' in params) {
      connectTwitter(params.oauthToken, params.oauthVerifier);
      setConnecting(false);
      close();
    }
  }

  useEffect(() => {
    if (!childWindow) {
      setConnecting(false);
    }
  }, [childWindow]);

  return {
    isConnecting,
    authWithTwitter,
  } as const;
}
