import { useAccount } from 'hooks/useAccount';

import { Button, Text, Preloader } from 'components';

import { useFeatureStore } from '../../context/context';
import { useTwitterAuth } from './useTwitterAuth';

import block from 'bem-cn-lite';

import './style.scss';

const b = block('twitter-connector');

export const ConnectTwitter: React.FC = () => {
  const { account } = useAccount();
  const { user, isUserLoading } = useFeatureStore();
  const { isConnecting, authWithTwitter } = useTwitterAuth();
  const buttonText = user?.twitterHandle ? 'change' : 'connect twitter';

  return (
    <div className={b()}>
      <Button
        disabled={isUserLoading || isConnecting || account === null}
        variant={user !== null && user.twitterHandle ? 'success' : 'primary'}
        onClick={authWithTwitter}
      >
        {isConnecting || isUserLoading ? <Preloader /> : buttonText}
      </Button>
      {user?.twitterHandle && (
        <Text typography="text-l">@{user.twitterHandle}</Text>
      )}
    </div>
  );
};
