//@ts-nocheck
import { useEffect, useState, useCallback, useRef } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { from256 } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';
import { calcGas } from 'utils/gas';

import { useErrorHandler } from 'hooks/useErrorHandler';

import type { Vesting } from '../types';
import { Vesting__factory } from '../types';

export type VestingAPI = ReturnType<typeof useVestingAPI>;

function createContract(address: string, web3: Web3Provider, account: string) {
  return getContract<Vesting>(Vesting__factory, address, web3, account);
}

// export type VestingAPI = {
//   getVestingData: (account: string) => Promise<IVestingObject>,
//   checkVestingAvailable: () => Promise<string | VestingAPIErrors.NoVestingAvailable>,
//   releaseVesting: (showTransactionPending: (tx: string) => void) => Promise<TransactionReceipt | string>,
// };

enum VestingAPIErrors {
  NoVestingAvailable = 'NOT_VESTED',
  TransactionIsReverted = 'Transaction has been reverted by the EVM',
}

export interface IVestingObject {
  isActive: boolean;

  nextReleaseDate: string;
  vestingEndDate: string;

  strpTotal: number;
  strpReleased: number;
  strpAvailable: number;
}

export function useVestingAPI(
  web3: Web3Provider,
  address: string,
  account: string,
) {
  const { showError } = useErrorHandler(web3);
  const saveAccount = useRef(account);
  const availableVesting = useRef(0);

  const [contract, setContract] = useState<Vesting>(
    createContract(address, web3, account),
  );

  useEffect(() => {
    saveAccount.current = account;
  }, [account]);

  useEffect(() => {
    setContract(createContract(address, web3, account));
  }, [web3, address]);

  const checkVestingAvailable = useCallback(
    async () => {
      try {
        const ret = await contract.checkVestingAvailable();
        availableVesting.current = convertNumberFrom18ToDecimal(ret.toString());
        return ret;
      } catch (error: any) {
        await showError(
          String(error).includes(VestingAPIErrors.NoVestingAvailable)
            ? new Error('No vesting available for this account!')
            : error,
        );
        throw error;
      }
    },
    // return new Promise((resolve, reject) => {
    //   contract.methods
    //     .checkVestingAvailable()
    //     .call({ from: saveAccount.current })
    //     .then(response => {
    //       availableVesting.current = convertNumberFrom18ToDecimal(response);
    //       resolve(response);
    //     })
    //     .catch(async (error: Error) => {
    //       await showError(
    //         String(error).includes(VestingAPIErrors.NoVestingAvailable)
    //           ? new Error('No vesting available for this account!')
    //           : error,
    //       );
    //       reject();
    //     });
    // });
    [contract],
  );

  const getVestingData = useCallback(
    async (account: string): IVestingObject => {
      try {
        const ret = await contract.investors(account);
        return convertInvestorData(ret, availableVesting.current);
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },

    // (account: string): Promise<IVestingObject> => {
    //   return new Promise((resolve, reject) => {
    //     contract.methods
    //       .investors(account)
    //       .call()
    //       .then(response =>
    //         resolve(convertInvestorData(response, availableVesting.current)),
    //       )
    //       .catch(async error => {
    //         await showError(error);
    //         reject();
    //       });
    //   });
    // },
    [contract],
  );

  const releaseVesting = useCallback(
    async (showTransactionPending): TransactionReceipt | string => {
      try {
        const gasLimitEstimated = await contract.estimateGas.releaseVesting();
        const gasLimit = calcGas(gasLimitEstimated);
        const tx = await contract.releaseVesting({
          gasLimit,
        });

        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },

    // (showTransactionPending): Promise<TransactionReceipt | string> => {
    //   return new Promise((resolve, reject) => {
    //     contract.methods
    //       .releaseVesting()
    //       .send({ from: saveAccount.current })
    //       .on('transactionHash', function (hash) {
    //         showTransactionPending(hash);
    //       })
    //       .on('receipt', resolve)
    //       .on('error', reject)
    //       .then(response => resolve(response))
    //       .catch(async error => {
    //         await showError(
    //           String(error).includes(VestingAPIErrors.TransactionIsReverted)
    //             ? new Error('Transaction has been reverted by the EVM!')
    //             : error,
    //         );
    //         reject();
    //       });
    //   });
    // },
    [contract],
  );

  return {
    isReady: !!contract.provider,
    getVestingData,
    checkVestingAvailable,
    releaseVesting,
  } as const;
}

function convertInvestorData(data, availableVesting: number): IVestingObject {
  return {
    isActive: data.isActive,

    nextReleaseDate: getNextReleaseDate(
      Number(data.startTime),
      Number(data.periodLength),
    ),
    vestingEndDate: getDateFromSeconds(data.endTime),

    strpAvailable: availableVesting,
    strpTotal: convertNumberFrom18ToDecimal(data.strpTotal.toString()),
    strpReleased: convertNumberFrom18ToDecimal(data.strpReleased.toString()),
  };
}

function convertNumberFrom18ToDecimal(number: string): number {
  return (from256(number).c || [])[0];
}

function getDateFromSeconds(seconds: number): string {
  return new Date(Number(seconds) * 1000).toISOString().split('T')[0];
}

function getNextReleaseDate(startTime: number, periodLength: number): string {
  const currentTimeInSeconds = Math.round(new Date().getTime() / 1000);

  const currentTimeDifference = currentTimeInSeconds - startTime;
  const amountOfClaimedPeriods = Math.ceil(
    currentTimeDifference / periodLength,
  );

  return getDateFromSeconds(
    Number(startTime) + amountOfClaimedPeriods * periodLength,
  );
}
