import { BN } from 'utils/bigNumber';
import { toCurrency } from 'utils/formatters';

import { Text } from 'components';

import block from 'bem-cn-lite';

import './style.scss';

type Props = {
  total: BN;
};

const b = block('total-deposits');

export const TotalDeposits: React.FC<Props> = ({ total }) => {
  return (
    <div className={b()}>
      <Text className={b('label')} color="secondary">
        Total deposits
      </Text>
      <Text className={b('total')} typography="text-l">
        ${toCurrency(total.toString(), 0, 2)}
      </Text>
    </div>
  );
};
