import React, { useState } from 'react';

import { block } from 'utils/classname';

import { Modal } from 'components';

import './style.scss';

const b = block('contest-modal');

export const ContestModal = () => {
  return (
    <Modal title="Wrong network" size="medium">
      <div className={b()}>
        To perform this action you <b>must</b> swtich to <b>Ethereum mainnet</b>
      </div>
    </Modal>
  );
};
