import { BN, format } from 'utils/bigNumber';
import { formatCurrency } from 'utils/formatters';

import { NumberTooltip, Text } from 'components';
import Label from 'components/Label';
import Signum from 'components/Signum';

import style from './style.module.scss';

interface Props {
  amount: string | BN;
  code: string;
  showChange?: boolean;
}

const Currency = ({ amount, code, showChange }: Props) => {
  return (
    <div className={style.container}>
      {showChange ? (
        <Signum value={String(amount)} />
      ) : (
        <NumberTooltip.Component
          title={
            <Text typography="text-s">
              {BN.isBigNumber(amount) ? amount.toString() : amount}
            </Text>
          }
          placement="left"
        >
          <span>
            {BN.isBigNumber(amount)
              ? format(amount, 2)
              : formatCurrency(amount)}
          </span>
        </NumberTooltip.Component>
      )}
      <Label text={code} className={style.label} />
    </div>
  );
};

export default Currency;
