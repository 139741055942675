import Box from '@material-ui/core/Box';

import { BuySellWidget } from './views/BuySellWidget';
import { Header } from './views/Header';
import { HistoricalPrice } from './views/HistoricalPrice';
import { OpenPositions } from './views/OpenPositions';
import { RecentTrades } from './views/RecentTrades';

export const ExchangePro = () => {
  return (
    <>
      <Header />
      <Box display="flex">
        <HistoricalPrice />
        <Box flexDirection="column">
          <BuySellWidget />
          <RecentTrades />
        </Box>
      </Box>
      <OpenPositions />
    </>
  );
};
