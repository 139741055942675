import React, { useEffect, useState } from 'react';

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { block } from 'utils/classname';
import {
  formatCurrency,
  fractionToPercent,
  formatRatio,
} from 'utils/formatters';

import { Text } from '../Text';

import './style.scss';

const b = block('numeric-value');

export type NumericValueKind = 'percents' | 'currency' | 'ratio';

type Props = {
  value: string | number;
  kind: NumericValueKind;
  withDiffMarker?: boolean;
  fontWeight?: 'normal' | 'bold';
  fractionDigits?: 'all' | number;
};

type Diff = 'positive' | 'negative' | 'no/unknown';

type DiffProps = {
  diff: Diff;
};

function DiffComponent({ diff }: DiffProps) {
  switch (diff) {
    case 'negative':
      return (
        <div className={b('negative-diff-icon')}>
          <FontAwesomeIcon icon={faCaretDown} size="lg" />
        </div>
      );

    case 'positive':
      return (
        <div className={b('positive-diff-icon')}>
          <FontAwesomeIcon icon={faCaretUp} size="lg" />
        </div>
      );
  }

  return null;
}

function getFormattedValue(
  value: string | number,
  valueKind: NumericValueKind,
  fractionDigits?: 'all' | number,
): string {
  switch (valueKind) {
    case 'currency':
      return formatCurrency(value, fractionDigits);
    case 'percents':
      return fractionToPercent(value, { fractionDigits });
    case 'ratio':
      return formatRatio(value);
  }
}

function NumericValue(props: Props) {
  const {
    value,
    kind,
    withDiffMarker,
    fontWeight = 'bold',
    fractionDigits = 2,
  } = props;
  const [prevValue, setPrevValue] = useState<number | string>();
  const [diff, setDiff] = useState<Diff>('no/unknown');

  useEffect(() => {
    const numericValue = Number(value);
    const prevNumericValue = Number(prevValue);

    if (prevValue === null) setDiff('no/unknown');
    else if (numericValue > prevNumericValue) setDiff('positive');
    else if (numericValue < prevNumericValue) setDiff('negative');
    else setDiff('no/unknown');

    setPrevValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const formattedValue = getFormattedValue(value, kind, fractionDigits);

  return (
    <div className={b()}>
      {withDiffMarker && (
        <div className={b('diff')}>
          <DiffComponent diff={formattedValue ? diff : 'no/unknown'} />
        </div>
      )}
      <Text
        className={b('text', {
          diff: withDiffMarker && formattedValue && diff,
        })}
        weight={fontWeight}
      >
        {formattedValue || '—'}
      </Text>
    </div>
  );
}

export const Component = React.memo(NumericValue);

export { Component as NumericValue };
