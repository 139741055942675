import block from 'bem-cn-lite';
import {Text} from 'components';
import {IVestingObject} from 'contracts/apiHooks';
import {showVestingValue, VestingClaimCardClass} from '../index';

export default function VestingDates(props: Pick<IVestingObject, 'nextReleaseDate' | 'vestingEndDate'>) {
    const bem = block(VestingClaimCardClass);
    return (
        <div className={bem('column')}>
            <div className={bem('row')}>
                <Text
                    className={bem('label')}
                    as='span'
                    typography='text-l'
                >
                    Next release
                </Text>
                <Text
                    className={bem('value')}
                    as='span'
                >
                    {showVestingValue(props.nextReleaseDate)}
                </Text>
            </div>
            <div className={bem('row')}>
                <Text
                    className={bem('label')}
                    as='span'
                    typography='text-l'
                >
                    Vesting end date
                </Text>
                <Text
                    className={bem('value')}
                    as='span'
                >
                    {showVestingValue(props.vestingEndDate)}
                </Text>
            </div>
        </div>
    );
}
