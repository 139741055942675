import { useContext } from 'react';

import { Web3APIContext } from 'contexts/Web3APIContext';

const useWeb3 = () => {
  const context = useContext(Web3APIContext);
  if (context) {
    return context;
  }
  throw Error('Context Provider is not provided!');
};

export { useWeb3 };
