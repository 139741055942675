import { InjectedConnector } from '@web3-react/injected-connector';

import { WalletConnectV2Connector } from './walletConnectV2Connector';

import { ChainId } from 'model';

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(
    `REACT_APP_INFURA_KEY must be a defined environment variable`,
  );
}

const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
if (typeof CHAIN_ID === 'undefined') {
  throw new Error(`REACT_APP_CHAIN_ID must be a defined environment variable`);
}

// @todo: for now, we only support one chain ID per environment, we should probably read an array of chain IDs from .env to support multiple
const allSupportedChainIds = [CHAIN_ID];

const NETWORK_URLS: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [ChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [ChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [ChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [ChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [ChainId.BSC]: `https://bsc-dataseed.binance.org`,
  [ChainId.BSC_TESTNET]: `https://data-seed-prebsc-2-s3.binance.org:8545`,
  [ChainId.ARBITRUM_ONE]: `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`,
  [ChainId.ARBITRUM_RINKEBY]: `https://arbitrum-rinkeby.infura.io/v3/${INFURA_KEY}`,
  [ChainId.LOCAL]: 'http://127.0.0.1:8545/',
};

export const injected = new InjectedConnector({
  supportedChainIds: allSupportedChainIds,
});

export const walletconnect = new WalletConnectV2Connector({
  projectId: 'f63cf37d7efb015a331209dc49f2dbd2',
  showQrModal: true,
  chains: allSupportedChainIds,
  rpcMap: NETWORK_URLS,
} as any);
