import { FixedRateWidget } from './FixedRateWidget';
import { MarketSelector } from './MarketSelector';
import { MarketSummary } from './MarketSummary';

import styled from 'styled-components';

const HeaderStyled = styled.div`
  display: flex;
  height: 106px;
  flex-grow: 1;
  margin: 0 0 19px 0;

  & .market-selector {
    flex-grow: 1;
  }

  & .market-summary {
    width: 707px;
  }
`;

export const Header = () => {
  return (
    <HeaderStyled>
      <MarketSelector />
      <FixedRateWidget price={26.9} variation={-11.75} label={'Fixed Rate'} />
      <MarketSummary />
    </HeaderStyled>
  );
};
