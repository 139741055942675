import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BN, format, zero } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import { MarketUnstackeProfit } from 'contracts/apiHooks';

import debounce from 'awesome-debounce-promise';
import { CurrencyID } from 'model';
import { useAsyncAbortable } from 'react-async-hook';

function useUnstakeProfit(
  account: string | null | undefined,
  amount: BN | null,
) {
  const { insuranceAPI } = useWeb3();

  const debouncedCalcUnstakeProfit = useCallback(
    debounce(insuranceAPI.calcUnstakeProfit, 600),
    [insuranceAPI],
  );

  const dayInSeconds = 24 * 60 * 60;

  const secondsToDays = (seconds: number) => {
    return Math.round(seconds / dayInSeconds);
  };

  return useAsyncAbortable<
    Omit<MarketUnstackeProfit, number> | undefined,
    [string | null | undefined, BN | null]
  >(
    async (_, account, amount) => {
      if (account && amount) {
        const profitData = await debouncedCalcUnstakeProfit(account, amount);
        const { profit, unstakeAmount, fee, periodLeft } = profitData ?? {
          profit: zero,
          unstakeAmount: zero,
          fee: zero,
          periodLeft: 0,
        };
        return {
          profit,
          unstakeAmount,
          fee,
          periodLeft: secondsToDays(periodLeft),
        };
      }
    },
    [account, amount],
  );
}

const validate = (
  insurance: BN | null,
  balance: BN | null,
): string | undefined => {
  if (insurance === null || insurance.isLessThanOrEqualTo(0)) {
    return 'Field is required';
  }

  if (balance && insurance.isGreaterThan(balance)) {
    return 'Not enough balance';
  }

  return undefined;
};

export function useForm(balance: BN | null) {
  const { account } = useAccount();
  const [insuranceStake, setInsuranceStake] = useState<BN | null>(null);
  const [error, setError] = useState<string | undefined>();
  const [dirty, setDirty] = useState<boolean>(false);

  const isValid = !Boolean(error);

  const {
    loading: isProfitCalculated,
    result: { profit, unstakeAmount, fee, periodLeft } = {
      profit: zero,
      unstakeAmount: zero,
      fee: zero,
      periodLeft: 0,
    },
  } = useUnstakeProfit(account, insuranceStake);

  const gridData = useMemo<[string, string, CurrencyID?][]>(() => {
    const data: [string, string, CurrencyID?][] = [];
    data.push(['Profit earned', format(profit, 2), 'busd']);
    data.push(['Penalty (paid)', format(fee, 2), 'busd']);
    data.push(['Total returned', format(unstakeAmount, 2), 'busd']);
    data.push(['Penalty period remaining (days)', String(periodLeft) || '0']);
    return data;
  }, [profit, unstakeAmount, fee, periodLeft]);

  const changeInsurance = (value: string | null) => {
    const valueBN = value ? new BN(value) : null;
    if (balance && valueBN) {
      setInsuranceStake(valueBN.isGreaterThan(balance) ? balance : valueBN);
    } else {
      setInsuranceStake(valueBN);
    }
    setDirty(true);
  };

  const setMaxBalance = () => balance && setInsuranceStake(balance);

  const makeSubmitHandler =
    (onSubmit: (insuranceStake: BN | null) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit(insuranceStake);
    };

  useEffect(() => {
    setError(validate(insuranceStake, balance));
  }, [insuranceStake]);

  return {
    insuranceStake,
    error,
    dirty,
    isValid,
    setMaxBalance,
    changeInsurance,
    makeSubmitHandler,
    isProfitCalculated,
    gridData,
  } as const;
}
