import { useCallback } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import * as Sentry from '@sentry/react';

import { SnackCloseButton } from './view/CloseButton';
import { TransactionReceipt } from 'web3-core/types';

import { serializeError } from 'eth-rpc-errors';
import { useSnackbar } from 'notistack';

// todo: add any custom error we want to include
const mapContractErrorMsg = {
  NOT_ENOUGH_MARGIN: 'NOT_ENOUGH_MARGIN: TODO: some description',
  WRONG_AMOUNT: 'You entered an invalid value. Please check again.',
  'intrinsic gas too low':
    'You run out of gas. Try to increase gas or gas price',
};

type MetaMaskError = {
  message: string;
  code: number;
  data?: {
    code: number;
    data: string;
    message: string;
  };
};

type ContractError = {
  receipt: TransactionReceipt;
  message?: string;
  stack?: string;
};

type ErrorWithMessage = {
  message: string;
};

type Error = MetaMaskError | ContractError | ErrorWithMessage;

const isMetaMaskError = (error: Error) => 'code' in error;

const isErrorWithParsedMessage = (error: Error): error is ErrorWithMessage =>
  !('receipt' in error) &&
  'message' in error &&
  /"message":|"code":/.test(error.message);

const isErrorWithMessage = (error: Error): error is ErrorWithMessage =>
  !('receipt' in error) && 'message' in error;

const isContractError = (error: Error): error is ContractError =>
  'receipt' in error;

export function useErrorHandler(web3?: Web3Provider) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeButton = useCallback(
    key => <SnackCloseButton onClick={() => closeSnackbar(key)} />,
    [closeSnackbar],
  );

  const showError = async (error: Error) => {
    console.log(error);
    const message = await getMessageFromError(error, web3);
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 10000,
      action: closeButton,
      preventDuplicate: true,
    });
  };

  return {
    showError,
  };
}

const isContractErrorWithReason = (error: Error): error is ErrorWithMessage =>
  !('receipt' in error) &&
  'message' in error &&
  /reverted with reason string/.test(error.message);

// Error: [ethjs-query] while formatting outputs from RPC '{"value":{"code":-32603,"data":{"code":-32000,"message":"intrinsic gas too low"}}}'

const cleanContractError = (error: string) => {
  return error
    ?.replaceAll("'", '')
    ?.replace('reverted with reason string ', '');
};

const cleanIntrinsicError = (error: string) => {
  return error?.replaceAll('"', '')?.replace('message:', '');
};

async function getMessageFromError(error: Error, web3?: Web3Provider) {
  let message = '';
  if (isMetaMaskError(error)) {
    const metaMaskError = serializeError(error);
    Sentry.captureException(metaMaskError);
    const { code, message: errorMessage } = metaMaskError;
    // console.log({ metamaskError: { code, message: errorMessage } });
    const intrinsicError = errorMessage?.match(
      new RegExp('"message":( )*"[ _:A-Za-z]+"'),
    )?.[0];

    message = intrinsicError
      ? cleanIntrinsicError(intrinsicError)
      : errorMessage || code.toString();
  } else if (isContractErrorWithReason(error)) {
    Sentry.captureException(error);
    const { contractCode } = parseMessage(error.message);
    message = cleanContractError(contractCode || '');
  } else if (isErrorWithParsedMessage(error)) {
    Sentry.captureException(error);
    const { message: errorMessage, code, reason } = parseMessage(error.message);
    message = reason || errorMessage || code || error.message;
  } else if (isErrorWithMessage(error)) {
    Sentry.captureException(error);
    ({ message } = error);
  } else if (web3 && isContractError(error)) {
    const { transactionHash } = error.receipt;
    message = await getRevertReason(web3, transactionHash);
  } else {
    Sentry.captureException(error);
    // console.log('unknown error case');
    message = JSON.stringify(error);
  }

  return mapContractErrorMsg[message] || message || 'Unknown error';
}

async function getRevertReason(
  web3: Web3Provider,
  transactionHash: string,
): Promise<string> {
  try {
    const tx = await web3.getTransaction(transactionHash);
    // @ts-ignore
    await web3.eth.call(tx, tx.blockNumber);
  } catch (err) {
    Sentry.captureException(err);
    // @ts-ignore
    const { message, code, reason } = parseMessage(err?.message);
    // console.log({ err, reason, message, code });
    // @ts-ignore
    return reason || message || code || err.message;
  }
  return '';
}

function parseMessage(messageString: string) {
  const message = messageString?.match(
    new RegExp('"message":( )?"[: _A-Za-z]+"'),
  )?.[0];
  const code = messageString?.match(
    new RegExp('(?<="code":s?)[-d]+(?=,|\n|s)'),
  )?.[0];
  const reason = message?.split(': ')?.slice(1).join(': ');
  const contractCode = messageString?.match(
    new RegExp("reverted with reason string '[sd_A-Za-z]+'"),
  )?.[0];
  return { message, code, reason, contractCode };
}
