import { useEffect, useState, useCallback, useRef } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { to256, from256, EBNToBN } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';
import { calcGas } from 'utils/gas';

import { useErrorHandler } from '../../hooks/useErrorHandler';
import type { MerkleReward } from '../types';
import { MerkleReward__factory } from '../types';

import BN from 'bignumber.js';

export type MerkleAPI = ReturnType<typeof useMerkleAPI>;

function createContract(address: string, web3: Web3Provider, account: string) {
  return getContract<MerkleReward>(
    MerkleReward__factory,
    address,
    web3,
    account,
  );
}

export type CheckAvailableToBuyResult = {
  total: string;
  available: string;
};

export function useMerkleAPI(
  web3: Web3Provider,
  address: string,
  account: string,
) {
  const { showError } = useErrorHandler(web3);
  const saveAccount = useRef(account);

  const [contract, setContract] = useState<MerkleReward>(
    createContract(address, web3, account),
  );

  useEffect(() => {
    saveAccount.current = account;
  }, [account]);

  useEffect(() => {
    setContract(createContract(address, web3, account));
  }, [web3, address]);

  const checkAvailableToBuy = useCallback(
    async () => {
      try {
        const ret = await contract.checkAvailableToBuy();
        //@todo: this should be updated by whoever updates the widget.
        // it is no longer necessary

        return {
          total: from256(EBNToBN(ret.total)).toString(),
          available: from256(EBNToBN(ret.available)).toString(),
        } as CheckAvailableToBuyResult;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const priceFor = useCallback(
    async (strpAmount: string) => {
      try {
        const ret = await contract.priceFor(strpAmount);
        return new BN(ret.toString());
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const buyStrp = useCallback(
    async (
      wantToBuy: string,
      index: number,
      total: string,
      proof: string[],
    ) => {
      const wantedBN = to256(wantToBuy).toString();
      const totalBN = to256(total).toString();
      try {
        const gasLimitEstimated = await contract.estimateGas.buyStrp(
          wantedBN,
          index,
          totalBN,
          proof,
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.buyStrp(wantedBN, index, totalBN, proof, {
          gasLimit,
        });
        return tx;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const checkAvailableToClaim = useCallback(async () => {
    try {
      const ret = await contract.checkAvailableToClaim();
      return from256(EBNToBN(ret)).toString();
    } catch (error: any) {
      showError(error);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const claimRewards = useCallback(
    async () => {
      try {
        const gasLimitEstimated = await contract.estimateGas.claimRewards();
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.claimRewards({ gasLimit });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  return {
    address,
    isReady: !!contract.provider,
    buyStrp,
    claimRewards,
    checkAvailableToBuy,
    checkAvailableToClaim,
    priceFor,
  } as const;
}
