import React from 'react';

import { Text } from '../Text';
import busdLogoSrc from 'assets/assetLogos/busd.svg';

import block from 'bem-cn-lite';
import { CurrencyID } from 'model';

import './style.scss';

const b = block('currency');

type Props = {
  currency: CurrencyID;
  showLabel?: boolean;
};

function Currency({ currency, showLabel = true }: Props) {
  const logoSrc = currency === 'busd' ? busdLogoSrc : null;

  return (
    <div className={b()}>
      {logoSrc && <img className={b('logo')} src={busdLogoSrc} alt="logo" />}
      {showLabel && <Text>{currency.toUpperCase()}</Text>}
    </div>
  );
}

export const Component = React.memo(Currency);
