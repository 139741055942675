import { useState } from 'react';

import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  AccordionDetails,
  AccordionDetailsProps,
} from '@material-ui/core';
import { ExpandMore, OpenInNew } from '@material-ui/icons';

import { toCurrency } from 'utils/formatters';

import { Button, CurrencyInput } from 'components';

import nerveLogo from 'assets/market_nerve.png';

import block from 'bem-cn-lite';
import { config } from 'config';
import { Market } from 'model';

import './style.scss';

type RowProps = {
  label: string;
  value: React.ReactNode;
  isLabelBold?: boolean;
  isValueBold?: boolean;
};

type Props = {
  market: Market;
};

const Row: React.FC<RowProps> = ({
  label,
  value,
  isLabelBold,
  isValueBold,
}) => (
  <div className={b('row')}>
    <label className={b('label', { bold: isLabelBold })}>{label}</label>
    <span className={b('value', { bold: isValueBold })}>{value}</span>
  </div>
);

const HarvestButton = () => (
  <div className={b('harvest-button-container')}>
    <Button>Harvest</Button>
  </div>
);

const b = block('market-widget');
const accordionClasses: AccordionProps['classes'] = {
  root: b('expansion-panel'),
};

const accordionSummaryClasses: AccordionSummaryProps['classes'] = {
  root: b('expansion-summary'),
  content: b('expansion-summary-content'),
};

const accordionDetailsClasses: AccordionDetailsProps['classes'] = {
  root: b('expansion-content'),
};

export const MarketWidget: React.FC<Props> = ({ market }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const marketConfig = config.markets[market.id];

  return (
    <div className={b()}>
      <header className={b('header')}>
        <img className={b('logo')} src={nerveLogo} alt="Nerve" />
        <h3 className={b('title')}>
          {marketConfig.platform} - {marketConfig.assetSymbol} LP
        </h3>
      </header>
      <div className={b('content')}>
        <div className={b('section')}>
          <Row label="APY:" isLabelBold value="112.5%" />
          <Row label="Earn:" value="STRP" isValueBold />
        </div>
        <CurrencyInput.Component
          value="0"
          label="STRP EARNED"
          rightPart={<HarvestButton />}
          readOnly
          allowNegative
        />
        <Row label="LP tokens staked:" value="0.15" />
        <Button variant="primary">Deposit</Button>
      </div>
      <div className={b('details')}>
        <Accordion
          expanded={expanded}
          classes={accordionClasses}
          onChange={(_, expanded) => setExpanded(expanded)}
        >
          <AccordionSummary
            classes={accordionSummaryClasses}
            expandIcon={<ExpandMore className={b('expand-icon')} />}
            aria-controls="panel1bh-content"
            id={market.id}
          >
            {expanded ? 'Hide' : 'Details'}
          </AccordionSummary>
          <AccordionDetails classes={accordionDetailsClasses}>
            <div className={b('section')}>
              <Row label="STRP EARNED" value="0" />
              <Row label="STAKING REWARDS" value="0" />
              <Row label="TRADING REWARDS" value="0" />
            </div>
            <div className={b('section')}>
              <Row
                label="Deposit:"
                value={
                  <a
                    className={b('market-link')}
                    href={`${config.network.explorerUrl}/address/${market.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {marketConfig.platform} - {marketConfig.assetSymbol}
                    <OpenInNew className={b('market-link-icon')} />
                  </a>
                }
              />
              <Row
                label="Total Liquidity"
                value={`$${toCurrency('204453', 0, 2)}`}
              />
              <a
                href={`${config.network.explorerUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Bscscan
              </a>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
