import React from 'react';

import Modal from 'components/Modal';

import { ClosePosition } from 'features/closePosition';

import { Position } from 'graphqlAPI';

import './style.scss';

type Props = {
  position: Position;
};

function ConfirmCloseModal({ position }: Props) {
  return (
    <Modal title="Close position" size="small">
      <ClosePosition position={position} />
    </Modal>
  );
}

const Component = React.memo(ConfirmCloseModal);

export { Component as ConfirmCloseModal };
