import React, { useEffect } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Web3ReactProvider } from '@web3-react/core';

import Web3ReactManager from 'components/Web3ReactManager';

import App from './App';
import { GlobalStyles } from './styles/theme/GlobalStyles';
import Theme from './styles/theme/Theme';

import 'normalize.css';
import { Providers } from 'providers';
import ReactDOM from 'react-dom';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
const PRODUCTION = process.env.REACT_APP_PROD;

// if (
//   typeof SENTRY_DSN === 'undefined' ||
//   typeof SENTRY_RELEASE === 'undefined'
// ) {
//   throw new Error(
//     `REACT_APP_SENTRY_DSN and REACT_APP_SENTRY_RELEASE must be a defined environment variable`,
//   );
// }

// if (typeof PRODUCTION === 'undefined') {
//   throw new Error(
//     `REACT_APP_PRODUCTION must be a defined environment variable`,
//   );
// }

Sentry.init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSend: event => {
    // Don't report errors in development environment
    if (PRODUCTION === 'false') {
      return null;
    }
    return event;
  },
});

const OAuthPlaceholder = () => {
  useEffect(() => {
    const search = window.location.search;
    window.opener?.postMessage(search, '*');
  }, []);
  return null;
};

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  window.opener === null ? (
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReactManager>
          <Theme>
            <Providers>
              <GlobalStyles />
              <App />
            </Providers>
          </Theme>
        </Web3ReactManager>
      </Web3ReactProvider>
    </React.StrictMode>
  ) : (
    <OAuthPlaceholder />
  ),
  document.getElementById('root'),
);
