import { createContext } from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';

export type Status =
  | 'no-provider'
  | 'wrong-network'
  | 'disconnected'
  | 'connected';

export type Account = {
  account: string | null | undefined;
  status: Status;
  isConnecting: boolean;
  areRequestsLocked: boolean;
  connect: (connector?: AbstractConnector) => Promise<void>;
  disconnect: () => void;
  approvedState: object;
  isTokenApproved: (wallet: string, token: string) => boolean;
  updateTokenApproved: (wallet: string, token: string, value: boolean) => void;
};

const AccountContext = createContext<Account | null>(null);

export { AccountContext };
