import React from 'react';

import { block } from 'utils/classname';

import { Text } from 'components';

import './style.scss';

const b = block('recieve-susd-notes');

type Props = {
  list: string[];
};

export function ReceiveSUSDNotes(props: Props) {
  const { list } = props;

  return (
    <ul className={b()}>
      {list.map(note => (
        <li className={b('note')}>
          <Text
            className={b('note-text')}
            typography="text-s"
            color="secondary"
          >
            {note}
          </Text>
        </li>
      ))}
    </ul>
  );
}
