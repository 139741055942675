import { useEffect, useMemo, useState } from 'react';

import { useAccount } from 'hooks/useAccount';

import { getUser } from '../api';
import { FeatureContext } from './context';
import { User } from 'feedbackContestAPI/api';

export const Provider: React.FC = ({ children }) => {
  const { account } = useAccount();
  const [isUserLoaded, setUserLoaded] = useState<boolean>(false);
  const [isUserLoading, setUserLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const value = useMemo(
    () => ({
      user,
      isUserLoading,
      isUserLoaded,
      setUser,
    }),
    [user, isUserLoaded, isUserLoading],
  );

  useEffect(() => {
    if (account) {
      setUserLoading(true);
      getUser(account)
        .then(setUser)
        .then(() => setUserLoaded(true))
        .finally(() => setUserLoading(false));
    } else {
      setUser(null);
    }
  }, [account]);

  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  );
};
