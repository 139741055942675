import React, { useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { BN } from 'utils/bigNumber';

import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import {
  Button,
  Preloader,
  CurrencyInput,
  Modal,
  MarketName,
  Widget,
  DataGrid,
  DataGridColumn,
  Text,
  Currency,
} from 'components';

import { useForm } from './useForm';

import block from 'bem-cn-lite';
import { Market } from 'model';
import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('remove-liquidity-modal');

type Props = {
  market: Market;
};

function RemoveLiquidityModal({ market }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { pop } = useModal();
  const { marketAPI } = useWeb3();
  const { addTransaction } = useTransaction();

  const [isRemoving, setRemoving] = useState(false);
  const {
    liquidity,
    balance,
    isValid,
    dirty,
    error,
    changeLiquidity,
    setMaxBalance,
    makeSubmitHandler,
    isProfitCalculated,
    gridData,
  } = useForm(market);

  const title = (
    <div className={b('title')}>
      Remove liquidity
      <div className={b('market-instrument-pair')}>
        <MarketName marketID={market.id} withAssetSymbol />
      </div>
    </div>
  );

  const handleSubmit = makeSubmitHandler(() => {
    liquidity &&
      Promise.resolve()
        .then(() => setRemoving(true))
        .then(() => marketAPI.unstake(market.id, new BN(liquidity)))
        .then(tx => {
          addTransaction(tx, `Remove liquidity`);
        })
        .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => setRemoving(false))
        .finally(pop);
  });

  const gridColumns: DataGridColumn[] = useMemo(() => {
    return [
      [
        { label: '1', minWidth: 'auto' },
        { label: '2', minWidth: '100px' },
        { label: '3', minWidth: 'auto', align: 'end', disablePadding: 'right' },
      ],
    ];
  }, []);

  const formattedGridData = useMemo(() => {
    return gridData.map(([label, value, unit]) => [
      <Text typography="heading-l" color="secondary">
        {label}
      </Text>,
      isProfitCalculated ? (
        <CircularProgress size={14} />
      ) : (
        <Text typography="text-m" color="secondary">
          {value}
        </Text>
      ),
      typeof unit === 'string' ? <Currency.Component currency={unit} /> : '',
    ]);
  }, [isProfitCalculated, gridData]);

  return (
    <Modal title={title} size="medium">
      <div className={b()}>
        <form onSubmit={handleSubmit}>
          <div className={b('input')}>
            <CurrencyInput.Component
              label="Liquidity"
              error={dirty ? error : undefined}
              value={liquidity?.toString() ?? null}
              onChange={changeLiquidity}
              rightPart={
                <CurrencyInput.Limiter
                  value={balance}
                  currency="slp"
                  onButtonClick={setMaxBalance}
                  disabled={!!balance && liquidity?.isEqualTo(balance)}
                />
              }
            />
          </div>
          <Widget.SubSection>
            <DataGrid
              columns={gridColumns}
              data={formattedGridData}
              hasHead={false}
            />
          </Widget.SubSection>
          <Button
            className={b('remove-liquidity-button')}
            type="submit"
            disabled={isRemoving || !isValid || isProfitCalculated}
            variant="primary"
          >
            {isRemoving ? <Preloader /> : 'Remove liquidity'}
          </Button>
        </form>
      </div>
    </Modal>
  );
}

const Component = React.memo(RemoveLiquidityModal);
export { Component as RemoveLiquidityModal };
