import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};

export type Account = {
  __typename?: 'Account';
  /**  Account address  */
  id: Scalars['ID'];
  /**  changes of portfolio  */
  change?: Maybe<PortfolioChange>;
  marketStakes: Array<MarketStake>;
  insuranceStakes: Array<InsuranceStake>;
  positions: Array<Position>;
  hourlyHistory: Array<PortfolioHourlyChange>;
  dailyHistory: Array<PortfolioDailyChange>;
};

export type AccountMarketStakesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MarketStake_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<MarketStake_Filter>;
};

export type AccountInsuranceStakesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InsuranceStake_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InsuranceStake_Filter>;
};

export type AccountPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
};

export type AccountHourlyHistoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioHourlyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioHourlyChange_Filter>;
};

export type AccountDailyHistoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioDailyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioDailyChange_Filter>;
};

export type Account_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  change?: Maybe<Scalars['String']>;
  change_not?: Maybe<Scalars['String']>;
  change_gt?: Maybe<Scalars['String']>;
  change_lt?: Maybe<Scalars['String']>;
  change_gte?: Maybe<Scalars['String']>;
  change_lte?: Maybe<Scalars['String']>;
  change_in?: Maybe<Array<Scalars['String']>>;
  change_not_in?: Maybe<Array<Scalars['String']>>;
  change_contains?: Maybe<Scalars['String']>;
  change_not_contains?: Maybe<Scalars['String']>;
  change_starts_with?: Maybe<Scalars['String']>;
  change_not_starts_with?: Maybe<Scalars['String']>;
  change_ends_with?: Maybe<Scalars['String']>;
  change_not_ends_with?: Maybe<Scalars['String']>;
};

export enum Account_OrderBy {
  Id = 'id',
  Change = 'change',
  MarketStakes = 'marketStakes',
  InsuranceStakes = 'insuranceStakes',
  Positions = 'positions',
  HourlyHistory = 'hourlyHistory',
  DailyHistory = 'dailyHistory',
}

export type Block_Height = {
  hash?: Maybe<Scalars['Bytes']>;
  number?: Maybe<Scalars['Int']>;
};

export type InsuranceFund = {
  __typename?: 'InsuranceFund';
  /**  address of contract  */
  id: Scalars['ID'];
  token?: Maybe<SipToken>;
  liquidity?: Maybe<Scalars['BigDecimal']>;
  stakedPnl?: Maybe<Scalars['BigDecimal']>;
};

export type InsuranceFund_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_gt?: Maybe<Scalars['String']>;
  token_lt?: Maybe<Scalars['String']>;
  token_gte?: Maybe<Scalars['String']>;
  token_lte?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_contains?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  liquidity?: Maybe<Scalars['BigDecimal']>;
  liquidity_not?: Maybe<Scalars['BigDecimal']>;
  liquidity_gt?: Maybe<Scalars['BigDecimal']>;
  liquidity_lt?: Maybe<Scalars['BigDecimal']>;
  liquidity_gte?: Maybe<Scalars['BigDecimal']>;
  liquidity_lte?: Maybe<Scalars['BigDecimal']>;
  liquidity_in?: Maybe<Array<Scalars['BigDecimal']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedPnl?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_not?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_gt?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_lt?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_gte?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_lte?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedPnl_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum InsuranceFund_OrderBy {
  Id = 'id',
  Token = 'token',
  Liquidity = 'liquidity',
  StakedPnl = 'stakedPnl',
}

export type InsuranceStake = {
  __typename?: 'InsuranceStake';
  /**  account_address + asset_address  */
  id: Scalars['ID'];
  insurance: InsuranceFund;
  account: Account;
  sipTotal?: Maybe<Scalars['BigInt']>;
  collateralTotal?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl?: Maybe<Scalars['BigDecimal']>;
};

export type InsuranceStake_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  insurance?: Maybe<Scalars['String']>;
  insurance_not?: Maybe<Scalars['String']>;
  insurance_gt?: Maybe<Scalars['String']>;
  insurance_lt?: Maybe<Scalars['String']>;
  insurance_gte?: Maybe<Scalars['String']>;
  insurance_lte?: Maybe<Scalars['String']>;
  insurance_in?: Maybe<Array<Scalars['String']>>;
  insurance_not_in?: Maybe<Array<Scalars['String']>>;
  insurance_contains?: Maybe<Scalars['String']>;
  insurance_not_contains?: Maybe<Scalars['String']>;
  insurance_starts_with?: Maybe<Scalars['String']>;
  insurance_not_starts_with?: Maybe<Scalars['String']>;
  insurance_ends_with?: Maybe<Scalars['String']>;
  insurance_not_ends_with?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  sipTotal?: Maybe<Scalars['BigInt']>;
  sipTotal_not?: Maybe<Scalars['BigInt']>;
  sipTotal_gt?: Maybe<Scalars['BigInt']>;
  sipTotal_lt?: Maybe<Scalars['BigInt']>;
  sipTotal_gte?: Maybe<Scalars['BigInt']>;
  sipTotal_lte?: Maybe<Scalars['BigInt']>;
  sipTotal_in?: Maybe<Array<Scalars['BigInt']>>;
  sipTotal_not_in?: Maybe<Array<Scalars['BigInt']>>;
  collateralTotal?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_not?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_gt?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_lt?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_gte?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_lte?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collateralTotal_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  initialStakedPnl?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_not?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_gt?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_lt?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_gte?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_lte?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_in?: Maybe<Array<Scalars['BigDecimal']>>;
  initialStakedPnl_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum InsuranceStake_OrderBy {
  Id = 'id',
  Insurance = 'insurance',
  Account = 'account',
  SipTotal = 'sipTotal',
  CollateralTotal = 'collateralTotal',
  InitialStakedPnl = 'initialStakedPnl',
}

export type Market = {
  __typename?: 'Market';
  /**  address of contract  */
  id: Scalars['ID'];
  name: Scalars['String'];
  assetSymbol: Scalars['String'];
  token: SlpToken;
  created: Scalars['BigInt'];
  createdAtBlock: Scalars['BigInt'];
  createdAtTransaction: Scalars['Bytes'];
  liquidity?: Maybe<Scalars['BigDecimal']>;
  stakedPnl?: Maybe<Scalars['BigDecimal']>;
  price?: Maybe<Price>;
  priceDailyChange?: Maybe<PriceDailyChange>;
};

export type MarketStake = {
  __typename?: 'MarketStake';
  /**  account_address + asset_address  */
  id: Scalars['ID'];
  market: Market;
  account: Account;
  slpTotal?: Maybe<Scalars['BigInt']>;
  collateralTotal?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl?: Maybe<Scalars['BigDecimal']>;
};

export type MarketStake_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  market?: Maybe<Scalars['String']>;
  market_not?: Maybe<Scalars['String']>;
  market_gt?: Maybe<Scalars['String']>;
  market_lt?: Maybe<Scalars['String']>;
  market_gte?: Maybe<Scalars['String']>;
  market_lte?: Maybe<Scalars['String']>;
  market_in?: Maybe<Array<Scalars['String']>>;
  market_not_in?: Maybe<Array<Scalars['String']>>;
  market_contains?: Maybe<Scalars['String']>;
  market_not_contains?: Maybe<Scalars['String']>;
  market_starts_with?: Maybe<Scalars['String']>;
  market_not_starts_with?: Maybe<Scalars['String']>;
  market_ends_with?: Maybe<Scalars['String']>;
  market_not_ends_with?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  slpTotal?: Maybe<Scalars['BigInt']>;
  slpTotal_not?: Maybe<Scalars['BigInt']>;
  slpTotal_gt?: Maybe<Scalars['BigInt']>;
  slpTotal_lt?: Maybe<Scalars['BigInt']>;
  slpTotal_gte?: Maybe<Scalars['BigInt']>;
  slpTotal_lte?: Maybe<Scalars['BigInt']>;
  slpTotal_in?: Maybe<Array<Scalars['BigInt']>>;
  slpTotal_not_in?: Maybe<Array<Scalars['BigInt']>>;
  collateralTotal?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_not?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_gt?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_lt?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_gte?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_lte?: Maybe<Scalars['BigDecimal']>;
  collateralTotal_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collateralTotal_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  initialStakedPnl?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_not?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_gt?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_lt?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_gte?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_lte?: Maybe<Scalars['BigDecimal']>;
  initialStakedPnl_in?: Maybe<Array<Scalars['BigDecimal']>>;
  initialStakedPnl_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum MarketStake_OrderBy {
  Id = 'id',
  Market = 'market',
  Account = 'account',
  SlpTotal = 'slpTotal',
  CollateralTotal = 'collateralTotal',
  InitialStakedPnl = 'initialStakedPnl',
}

export type Market_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  assetSymbol?: Maybe<Scalars['String']>;
  assetSymbol_not?: Maybe<Scalars['String']>;
  assetSymbol_gt?: Maybe<Scalars['String']>;
  assetSymbol_lt?: Maybe<Scalars['String']>;
  assetSymbol_gte?: Maybe<Scalars['String']>;
  assetSymbol_lte?: Maybe<Scalars['String']>;
  assetSymbol_in?: Maybe<Array<Scalars['String']>>;
  assetSymbol_not_in?: Maybe<Array<Scalars['String']>>;
  assetSymbol_contains?: Maybe<Scalars['String']>;
  assetSymbol_not_contains?: Maybe<Scalars['String']>;
  assetSymbol_starts_with?: Maybe<Scalars['String']>;
  assetSymbol_not_starts_with?: Maybe<Scalars['String']>;
  assetSymbol_ends_with?: Maybe<Scalars['String']>;
  assetSymbol_not_ends_with?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_gt?: Maybe<Scalars['String']>;
  token_lt?: Maybe<Scalars['String']>;
  token_gte?: Maybe<Scalars['String']>;
  token_lte?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_contains?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['BigInt']>;
  created_not?: Maybe<Scalars['BigInt']>;
  created_gt?: Maybe<Scalars['BigInt']>;
  created_lt?: Maybe<Scalars['BigInt']>;
  created_gte?: Maybe<Scalars['BigInt']>;
  created_lte?: Maybe<Scalars['BigInt']>;
  created_in?: Maybe<Array<Scalars['BigInt']>>;
  created_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlock?: Maybe<Scalars['BigInt']>;
  createdAtBlock_not?: Maybe<Scalars['BigInt']>;
  createdAtBlock_gt?: Maybe<Scalars['BigInt']>;
  createdAtBlock_lt?: Maybe<Scalars['BigInt']>;
  createdAtBlock_gte?: Maybe<Scalars['BigInt']>;
  createdAtBlock_lte?: Maybe<Scalars['BigInt']>;
  createdAtBlock_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtTransaction?: Maybe<Scalars['Bytes']>;
  createdAtTransaction_not?: Maybe<Scalars['Bytes']>;
  createdAtTransaction_in?: Maybe<Array<Scalars['Bytes']>>;
  createdAtTransaction_not_in?: Maybe<Array<Scalars['Bytes']>>;
  createdAtTransaction_contains?: Maybe<Scalars['Bytes']>;
  createdAtTransaction_not_contains?: Maybe<Scalars['Bytes']>;
  liquidity?: Maybe<Scalars['BigDecimal']>;
  liquidity_not?: Maybe<Scalars['BigDecimal']>;
  liquidity_gt?: Maybe<Scalars['BigDecimal']>;
  liquidity_lt?: Maybe<Scalars['BigDecimal']>;
  liquidity_gte?: Maybe<Scalars['BigDecimal']>;
  liquidity_lte?: Maybe<Scalars['BigDecimal']>;
  liquidity_in?: Maybe<Array<Scalars['BigDecimal']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedPnl?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_not?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_gt?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_lt?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_gte?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_lte?: Maybe<Scalars['BigDecimal']>;
  stakedPnl_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedPnl_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  price?: Maybe<Scalars['String']>;
  price_not?: Maybe<Scalars['String']>;
  price_gt?: Maybe<Scalars['String']>;
  price_lt?: Maybe<Scalars['String']>;
  price_gte?: Maybe<Scalars['String']>;
  price_lte?: Maybe<Scalars['String']>;
  price_in?: Maybe<Array<Scalars['String']>>;
  price_not_in?: Maybe<Array<Scalars['String']>>;
  price_contains?: Maybe<Scalars['String']>;
  price_not_contains?: Maybe<Scalars['String']>;
  price_starts_with?: Maybe<Scalars['String']>;
  price_not_starts_with?: Maybe<Scalars['String']>;
  price_ends_with?: Maybe<Scalars['String']>;
  price_not_ends_with?: Maybe<Scalars['String']>;
  priceDailyChange?: Maybe<Scalars['String']>;
  priceDailyChange_not?: Maybe<Scalars['String']>;
  priceDailyChange_gt?: Maybe<Scalars['String']>;
  priceDailyChange_lt?: Maybe<Scalars['String']>;
  priceDailyChange_gte?: Maybe<Scalars['String']>;
  priceDailyChange_lte?: Maybe<Scalars['String']>;
  priceDailyChange_in?: Maybe<Array<Scalars['String']>>;
  priceDailyChange_not_in?: Maybe<Array<Scalars['String']>>;
  priceDailyChange_contains?: Maybe<Scalars['String']>;
  priceDailyChange_not_contains?: Maybe<Scalars['String']>;
  priceDailyChange_starts_with?: Maybe<Scalars['String']>;
  priceDailyChange_not_starts_with?: Maybe<Scalars['String']>;
  priceDailyChange_ends_with?: Maybe<Scalars['String']>;
  priceDailyChange_not_ends_with?: Maybe<Scalars['String']>;
};

export enum Market_OrderBy {
  Id = 'id',
  Name = 'name',
  AssetSymbol = 'assetSymbol',
  Token = 'token',
  Created = 'created',
  CreatedAtBlock = 'createdAtBlock',
  CreatedAtTransaction = 'createdAtTransaction',
  Liquidity = 'liquidity',
  StakedPnl = 'stakedPnl',
  Price = 'price',
  PriceDailyChange = 'priceDailyChange',
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type PortfolioChange = {
  __typename?: 'PortfolioChange';
  id: Scalars['ID'];
  account: Account;
  fromHour: Scalars['BigInt'];
  fromDay: Scalars['BigInt'];
  fromWeek: Scalars['BigInt'];
  fromMonth: Scalars['BigInt'];
  lastHourValue: Scalars['BigDecimal'];
  last24Value: Scalars['BigDecimal'];
  lastWeekValue: Scalars['BigDecimal'];
  lastMonthValue: Scalars['BigDecimal'];
  changeHourRate?: Maybe<Scalars['BigDecimal']>;
  change24Rate?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate?: Maybe<Scalars['BigDecimal']>;
};

export type PortfolioChange_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  fromHour?: Maybe<Scalars['BigInt']>;
  fromHour_not?: Maybe<Scalars['BigInt']>;
  fromHour_gt?: Maybe<Scalars['BigInt']>;
  fromHour_lt?: Maybe<Scalars['BigInt']>;
  fromHour_gte?: Maybe<Scalars['BigInt']>;
  fromHour_lte?: Maybe<Scalars['BigInt']>;
  fromHour_in?: Maybe<Array<Scalars['BigInt']>>;
  fromHour_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fromDay?: Maybe<Scalars['BigInt']>;
  fromDay_not?: Maybe<Scalars['BigInt']>;
  fromDay_gt?: Maybe<Scalars['BigInt']>;
  fromDay_lt?: Maybe<Scalars['BigInt']>;
  fromDay_gte?: Maybe<Scalars['BigInt']>;
  fromDay_lte?: Maybe<Scalars['BigInt']>;
  fromDay_in?: Maybe<Array<Scalars['BigInt']>>;
  fromDay_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fromWeek?: Maybe<Scalars['BigInt']>;
  fromWeek_not?: Maybe<Scalars['BigInt']>;
  fromWeek_gt?: Maybe<Scalars['BigInt']>;
  fromWeek_lt?: Maybe<Scalars['BigInt']>;
  fromWeek_gte?: Maybe<Scalars['BigInt']>;
  fromWeek_lte?: Maybe<Scalars['BigInt']>;
  fromWeek_in?: Maybe<Array<Scalars['BigInt']>>;
  fromWeek_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fromMonth?: Maybe<Scalars['BigInt']>;
  fromMonth_not?: Maybe<Scalars['BigInt']>;
  fromMonth_gt?: Maybe<Scalars['BigInt']>;
  fromMonth_lt?: Maybe<Scalars['BigInt']>;
  fromMonth_gte?: Maybe<Scalars['BigInt']>;
  fromMonth_lte?: Maybe<Scalars['BigInt']>;
  fromMonth_in?: Maybe<Array<Scalars['BigInt']>>;
  fromMonth_not_in?: Maybe<Array<Scalars['BigInt']>>;
  lastHourValue?: Maybe<Scalars['BigDecimal']>;
  lastHourValue_not?: Maybe<Scalars['BigDecimal']>;
  lastHourValue_gt?: Maybe<Scalars['BigDecimal']>;
  lastHourValue_lt?: Maybe<Scalars['BigDecimal']>;
  lastHourValue_gte?: Maybe<Scalars['BigDecimal']>;
  lastHourValue_lte?: Maybe<Scalars['BigDecimal']>;
  lastHourValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  lastHourValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  last24Value?: Maybe<Scalars['BigDecimal']>;
  last24Value_not?: Maybe<Scalars['BigDecimal']>;
  last24Value_gt?: Maybe<Scalars['BigDecimal']>;
  last24Value_lt?: Maybe<Scalars['BigDecimal']>;
  last24Value_gte?: Maybe<Scalars['BigDecimal']>;
  last24Value_lte?: Maybe<Scalars['BigDecimal']>;
  last24Value_in?: Maybe<Array<Scalars['BigDecimal']>>;
  last24Value_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  lastWeekValue?: Maybe<Scalars['BigDecimal']>;
  lastWeekValue_not?: Maybe<Scalars['BigDecimal']>;
  lastWeekValue_gt?: Maybe<Scalars['BigDecimal']>;
  lastWeekValue_lt?: Maybe<Scalars['BigDecimal']>;
  lastWeekValue_gte?: Maybe<Scalars['BigDecimal']>;
  lastWeekValue_lte?: Maybe<Scalars['BigDecimal']>;
  lastWeekValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  lastWeekValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  lastMonthValue?: Maybe<Scalars['BigDecimal']>;
  lastMonthValue_not?: Maybe<Scalars['BigDecimal']>;
  lastMonthValue_gt?: Maybe<Scalars['BigDecimal']>;
  lastMonthValue_lt?: Maybe<Scalars['BigDecimal']>;
  lastMonthValue_gte?: Maybe<Scalars['BigDecimal']>;
  lastMonthValue_lte?: Maybe<Scalars['BigDecimal']>;
  lastMonthValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  lastMonthValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  changeHourRate?: Maybe<Scalars['BigDecimal']>;
  changeHourRate_not?: Maybe<Scalars['BigDecimal']>;
  changeHourRate_gt?: Maybe<Scalars['BigDecimal']>;
  changeHourRate_lt?: Maybe<Scalars['BigDecimal']>;
  changeHourRate_gte?: Maybe<Scalars['BigDecimal']>;
  changeHourRate_lte?: Maybe<Scalars['BigDecimal']>;
  changeHourRate_in?: Maybe<Array<Scalars['BigDecimal']>>;
  changeHourRate_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  change24Rate?: Maybe<Scalars['BigDecimal']>;
  change24Rate_not?: Maybe<Scalars['BigDecimal']>;
  change24Rate_gt?: Maybe<Scalars['BigDecimal']>;
  change24Rate_lt?: Maybe<Scalars['BigDecimal']>;
  change24Rate_gte?: Maybe<Scalars['BigDecimal']>;
  change24Rate_lte?: Maybe<Scalars['BigDecimal']>;
  change24Rate_in?: Maybe<Array<Scalars['BigDecimal']>>;
  change24Rate_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  changeWeekRate?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate_not?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate_gt?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate_lt?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate_gte?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate_lte?: Maybe<Scalars['BigDecimal']>;
  changeWeekRate_in?: Maybe<Array<Scalars['BigDecimal']>>;
  changeWeekRate_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  changeMonthRate?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate_not?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate_gt?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate_lt?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate_gte?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate_lte?: Maybe<Scalars['BigDecimal']>;
  changeMonthRate_in?: Maybe<Array<Scalars['BigDecimal']>>;
  changeMonthRate_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum PortfolioChange_OrderBy {
  Id = 'id',
  Account = 'account',
  FromHour = 'fromHour',
  FromDay = 'fromDay',
  FromWeek = 'fromWeek',
  FromMonth = 'fromMonth',
  LastHourValue = 'lastHourValue',
  Last24Value = 'last24Value',
  LastWeekValue = 'lastWeekValue',
  LastMonthValue = 'lastMonthValue',
  ChangeHourRate = 'changeHourRate',
  Change24Rate = 'change24Rate',
  ChangeWeekRate = 'changeWeekRate',
  ChangeMonthRate = 'changeMonthRate',
}

export type PortfolioDailyChange = {
  __typename?: 'PortfolioDailyChange';
  /** timestamp in days */
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  account: Account;
  oldValue: Scalars['BigDecimal'];
  newValue: Scalars['BigDecimal'];
};

export type PortfolioDailyChange_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['BigDecimal']>;
  oldValue_not?: Maybe<Scalars['BigDecimal']>;
  oldValue_gt?: Maybe<Scalars['BigDecimal']>;
  oldValue_lt?: Maybe<Scalars['BigDecimal']>;
  oldValue_gte?: Maybe<Scalars['BigDecimal']>;
  oldValue_lte?: Maybe<Scalars['BigDecimal']>;
  oldValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oldValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  newValue?: Maybe<Scalars['BigDecimal']>;
  newValue_not?: Maybe<Scalars['BigDecimal']>;
  newValue_gt?: Maybe<Scalars['BigDecimal']>;
  newValue_lt?: Maybe<Scalars['BigDecimal']>;
  newValue_gte?: Maybe<Scalars['BigDecimal']>;
  newValue_lte?: Maybe<Scalars['BigDecimal']>;
  newValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  newValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum PortfolioDailyChange_OrderBy {
  Id = 'id',
  Timestamp = 'timestamp',
  Account = 'account',
  OldValue = 'oldValue',
  NewValue = 'newValue',
}

export type PortfolioHourlyChange = {
  __typename?: 'PortfolioHourlyChange';
  /**  timestamp in hours  */
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  account: Account;
  oldValue: Scalars['BigDecimal'];
  newValue: Scalars['BigDecimal'];
};

export type PortfolioHourlyChange_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['BigDecimal']>;
  oldValue_not?: Maybe<Scalars['BigDecimal']>;
  oldValue_gt?: Maybe<Scalars['BigDecimal']>;
  oldValue_lt?: Maybe<Scalars['BigDecimal']>;
  oldValue_gte?: Maybe<Scalars['BigDecimal']>;
  oldValue_lte?: Maybe<Scalars['BigDecimal']>;
  oldValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oldValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  newValue?: Maybe<Scalars['BigDecimal']>;
  newValue_not?: Maybe<Scalars['BigDecimal']>;
  newValue_gt?: Maybe<Scalars['BigDecimal']>;
  newValue_lt?: Maybe<Scalars['BigDecimal']>;
  newValue_gte?: Maybe<Scalars['BigDecimal']>;
  newValue_lte?: Maybe<Scalars['BigDecimal']>;
  newValue_in?: Maybe<Array<Scalars['BigDecimal']>>;
  newValue_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum PortfolioHourlyChange_OrderBy {
  Id = 'id',
  Timestamp = 'timestamp',
  Account = 'account',
  OldValue = 'oldValue',
  NewValue = 'newValue',
}

export type Position = {
  __typename?: 'Position';
  /**  Equals to: <marketAddress>-<accountAddress> */
  id: Scalars['ID'];
  market: Market;
  account: Account;
  created: Scalars['BigInt'];
  isActive: Scalars['Boolean'];
  isLong: Scalars['Boolean'];
  isLiquidated: Scalars['Boolean'];
  notional: Scalars['BigDecimal'];
  collateral: Scalars['BigDecimal'];
  leverage: Scalars['BigDecimal'];
  initialPrice: Scalars['BigDecimal'];
};

export type Position_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  market?: Maybe<Scalars['String']>;
  market_not?: Maybe<Scalars['String']>;
  market_gt?: Maybe<Scalars['String']>;
  market_lt?: Maybe<Scalars['String']>;
  market_gte?: Maybe<Scalars['String']>;
  market_lte?: Maybe<Scalars['String']>;
  market_in?: Maybe<Array<Scalars['String']>>;
  market_not_in?: Maybe<Array<Scalars['String']>>;
  market_contains?: Maybe<Scalars['String']>;
  market_not_contains?: Maybe<Scalars['String']>;
  market_starts_with?: Maybe<Scalars['String']>;
  market_not_starts_with?: Maybe<Scalars['String']>;
  market_ends_with?: Maybe<Scalars['String']>;
  market_not_ends_with?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['BigInt']>;
  created_not?: Maybe<Scalars['BigInt']>;
  created_gt?: Maybe<Scalars['BigInt']>;
  created_lt?: Maybe<Scalars['BigInt']>;
  created_gte?: Maybe<Scalars['BigInt']>;
  created_lte?: Maybe<Scalars['BigInt']>;
  created_in?: Maybe<Array<Scalars['BigInt']>>;
  created_not_in?: Maybe<Array<Scalars['BigInt']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  isActive_in?: Maybe<Array<Scalars['Boolean']>>;
  isActive_not_in?: Maybe<Array<Scalars['Boolean']>>;
  isLong?: Maybe<Scalars['Boolean']>;
  isLong_not?: Maybe<Scalars['Boolean']>;
  isLong_in?: Maybe<Array<Scalars['Boolean']>>;
  isLong_not_in?: Maybe<Array<Scalars['Boolean']>>;
  isLiquidated?: Maybe<Scalars['Boolean']>;
  isLiquidated_not?: Maybe<Scalars['Boolean']>;
  isLiquidated_in?: Maybe<Array<Scalars['Boolean']>>;
  isLiquidated_not_in?: Maybe<Array<Scalars['Boolean']>>;
  notional?: Maybe<Scalars['BigDecimal']>;
  notional_not?: Maybe<Scalars['BigDecimal']>;
  notional_gt?: Maybe<Scalars['BigDecimal']>;
  notional_lt?: Maybe<Scalars['BigDecimal']>;
  notional_gte?: Maybe<Scalars['BigDecimal']>;
  notional_lte?: Maybe<Scalars['BigDecimal']>;
  notional_in?: Maybe<Array<Scalars['BigDecimal']>>;
  notional_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collateral?: Maybe<Scalars['BigDecimal']>;
  collateral_not?: Maybe<Scalars['BigDecimal']>;
  collateral_gt?: Maybe<Scalars['BigDecimal']>;
  collateral_lt?: Maybe<Scalars['BigDecimal']>;
  collateral_gte?: Maybe<Scalars['BigDecimal']>;
  collateral_lte?: Maybe<Scalars['BigDecimal']>;
  collateral_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collateral_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  leverage?: Maybe<Scalars['BigDecimal']>;
  leverage_not?: Maybe<Scalars['BigDecimal']>;
  leverage_gt?: Maybe<Scalars['BigDecimal']>;
  leverage_lt?: Maybe<Scalars['BigDecimal']>;
  leverage_gte?: Maybe<Scalars['BigDecimal']>;
  leverage_lte?: Maybe<Scalars['BigDecimal']>;
  leverage_in?: Maybe<Array<Scalars['BigDecimal']>>;
  leverage_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  initialPrice?: Maybe<Scalars['BigDecimal']>;
  initialPrice_not?: Maybe<Scalars['BigDecimal']>;
  initialPrice_gt?: Maybe<Scalars['BigDecimal']>;
  initialPrice_lt?: Maybe<Scalars['BigDecimal']>;
  initialPrice_gte?: Maybe<Scalars['BigDecimal']>;
  initialPrice_lte?: Maybe<Scalars['BigDecimal']>;
  initialPrice_in?: Maybe<Array<Scalars['BigDecimal']>>;
  initialPrice_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum Position_OrderBy {
  Id = 'id',
  Market = 'market',
  Account = 'account',
  Created = 'created',
  IsActive = 'isActive',
  IsLong = 'isLong',
  IsLiquidated = 'isLiquidated',
  Notional = 'notional',
  Collateral = 'collateral',
  Leverage = 'leverage',
  InitialPrice = 'initialPrice',
}

export type Price = {
  __typename?: 'Price';
  id: Scalars['ID'];
  market: Market;
  timestamp: Scalars['BigInt'];
  marketPrice: Scalars['BigDecimal'];
  oraclePrice: Scalars['BigDecimal'];
};

export type PriceDailyChange = {
  __typename?: 'PriceDailyChange';
  id: Scalars['ID'];
  market: Market;
  from: Scalars['BigInt'];
  marketLastPrice: Scalars['BigDecimal'];
  marketChangeRate?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice: Scalars['BigDecimal'];
  oracleChangeRate?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent?: Maybe<Scalars['BigDecimal']>;
};

export type PriceDailyChange_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  market?: Maybe<Scalars['String']>;
  market_not?: Maybe<Scalars['String']>;
  market_gt?: Maybe<Scalars['String']>;
  market_lt?: Maybe<Scalars['String']>;
  market_gte?: Maybe<Scalars['String']>;
  market_lte?: Maybe<Scalars['String']>;
  market_in?: Maybe<Array<Scalars['String']>>;
  market_not_in?: Maybe<Array<Scalars['String']>>;
  market_contains?: Maybe<Scalars['String']>;
  market_not_contains?: Maybe<Scalars['String']>;
  market_starts_with?: Maybe<Scalars['String']>;
  market_not_starts_with?: Maybe<Scalars['String']>;
  market_ends_with?: Maybe<Scalars['String']>;
  market_not_ends_with?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['BigInt']>;
  from_not?: Maybe<Scalars['BigInt']>;
  from_gt?: Maybe<Scalars['BigInt']>;
  from_lt?: Maybe<Scalars['BigInt']>;
  from_gte?: Maybe<Scalars['BigInt']>;
  from_lte?: Maybe<Scalars['BigInt']>;
  from_in?: Maybe<Array<Scalars['BigInt']>>;
  from_not_in?: Maybe<Array<Scalars['BigInt']>>;
  marketLastPrice?: Maybe<Scalars['BigDecimal']>;
  marketLastPrice_not?: Maybe<Scalars['BigDecimal']>;
  marketLastPrice_gt?: Maybe<Scalars['BigDecimal']>;
  marketLastPrice_lt?: Maybe<Scalars['BigDecimal']>;
  marketLastPrice_gte?: Maybe<Scalars['BigDecimal']>;
  marketLastPrice_lte?: Maybe<Scalars['BigDecimal']>;
  marketLastPrice_in?: Maybe<Array<Scalars['BigDecimal']>>;
  marketLastPrice_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  marketChangeRate?: Maybe<Scalars['BigDecimal']>;
  marketChangeRate_not?: Maybe<Scalars['BigDecimal']>;
  marketChangeRate_gt?: Maybe<Scalars['BigDecimal']>;
  marketChangeRate_lt?: Maybe<Scalars['BigDecimal']>;
  marketChangeRate_gte?: Maybe<Scalars['BigDecimal']>;
  marketChangeRate_lte?: Maybe<Scalars['BigDecimal']>;
  marketChangeRate_in?: Maybe<Array<Scalars['BigDecimal']>>;
  marketChangeRate_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  marketChangePercent?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent_not?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent_gt?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent_lt?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent_gte?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent_lte?: Maybe<Scalars['BigDecimal']>;
  marketChangePercent_in?: Maybe<Array<Scalars['BigDecimal']>>;
  marketChangePercent_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oracleLastPrice?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice_not?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice_gt?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice_lt?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice_gte?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice_lte?: Maybe<Scalars['BigDecimal']>;
  oracleLastPrice_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oracleLastPrice_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oracleChangeRate?: Maybe<Scalars['BigDecimal']>;
  oracleChangeRate_not?: Maybe<Scalars['BigDecimal']>;
  oracleChangeRate_gt?: Maybe<Scalars['BigDecimal']>;
  oracleChangeRate_lt?: Maybe<Scalars['BigDecimal']>;
  oracleChangeRate_gte?: Maybe<Scalars['BigDecimal']>;
  oracleChangeRate_lte?: Maybe<Scalars['BigDecimal']>;
  oracleChangeRate_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oracleChangeRate_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oracleChangePercent?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent_not?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent_gt?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent_lt?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent_gte?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent_lte?: Maybe<Scalars['BigDecimal']>;
  oracleChangePercent_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oracleChangePercent_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum PriceDailyChange_OrderBy {
  Id = 'id',
  Market = 'market',
  From = 'from',
  MarketLastPrice = 'marketLastPrice',
  MarketChangeRate = 'marketChangeRate',
  MarketChangePercent = 'marketChangePercent',
  OracleLastPrice = 'oracleLastPrice',
  OracleChangeRate = 'oracleChangeRate',
  OracleChangePercent = 'oracleChangePercent',
}

export type Price_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  market?: Maybe<Scalars['String']>;
  market_not?: Maybe<Scalars['String']>;
  market_gt?: Maybe<Scalars['String']>;
  market_lt?: Maybe<Scalars['String']>;
  market_gte?: Maybe<Scalars['String']>;
  market_lte?: Maybe<Scalars['String']>;
  market_in?: Maybe<Array<Scalars['String']>>;
  market_not_in?: Maybe<Array<Scalars['String']>>;
  market_contains?: Maybe<Scalars['String']>;
  market_not_contains?: Maybe<Scalars['String']>;
  market_starts_with?: Maybe<Scalars['String']>;
  market_not_starts_with?: Maybe<Scalars['String']>;
  market_ends_with?: Maybe<Scalars['String']>;
  market_not_ends_with?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  marketPrice?: Maybe<Scalars['BigDecimal']>;
  marketPrice_not?: Maybe<Scalars['BigDecimal']>;
  marketPrice_gt?: Maybe<Scalars['BigDecimal']>;
  marketPrice_lt?: Maybe<Scalars['BigDecimal']>;
  marketPrice_gte?: Maybe<Scalars['BigDecimal']>;
  marketPrice_lte?: Maybe<Scalars['BigDecimal']>;
  marketPrice_in?: Maybe<Array<Scalars['BigDecimal']>>;
  marketPrice_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oraclePrice?: Maybe<Scalars['BigDecimal']>;
  oraclePrice_not?: Maybe<Scalars['BigDecimal']>;
  oraclePrice_gt?: Maybe<Scalars['BigDecimal']>;
  oraclePrice_lt?: Maybe<Scalars['BigDecimal']>;
  oraclePrice_gte?: Maybe<Scalars['BigDecimal']>;
  oraclePrice_lte?: Maybe<Scalars['BigDecimal']>;
  oraclePrice_in?: Maybe<Array<Scalars['BigDecimal']>>;
  oraclePrice_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
};

export enum Price_OrderBy {
  Id = 'id',
  Market = 'market',
  Timestamp = 'timestamp',
  MarketPrice = 'marketPrice',
  OraclePrice = 'oraclePrice',
}

export type Query = {
  __typename?: 'Query';
  insuranceFund?: Maybe<InsuranceFund>;
  insuranceFunds: Array<InsuranceFund>;
  market?: Maybe<Market>;
  markets: Array<Market>;
  price?: Maybe<Price>;
  prices: Array<Price>;
  priceDailyChange?: Maybe<PriceDailyChange>;
  priceDailyChanges: Array<PriceDailyChange>;
  slptoken?: Maybe<SlpToken>;
  slptokens: Array<SlpToken>;
  siptoken?: Maybe<SipToken>;
  siptokens: Array<SipToken>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  portfolioChange?: Maybe<PortfolioChange>;
  portfolioChanges: Array<PortfolioChange>;
  portfolioHourlyChange?: Maybe<PortfolioHourlyChange>;
  portfolioHourlyChanges: Array<PortfolioHourlyChange>;
  portfolioDailyChange?: Maybe<PortfolioDailyChange>;
  portfolioDailyChanges: Array<PortfolioDailyChange>;
  marketStake?: Maybe<MarketStake>;
  marketStakes: Array<MarketStake>;
  insuranceStake?: Maybe<InsuranceStake>;
  insuranceStakes: Array<InsuranceStake>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};

export type QueryInsuranceFundArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryInsuranceFundsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InsuranceFund_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InsuranceFund_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryMarketArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryMarketsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Market_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Market_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryPriceArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryPricesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Price_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Price_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryPriceDailyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryPriceDailyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PriceDailyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PriceDailyChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type QuerySlptokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QuerySlptokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SlpToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<SlpToken_Filter>;
  block?: Maybe<Block_Height>;
};

export type QuerySiptokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QuerySiptokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SipToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<SipToken_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Account_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Account_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryPortfolioChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryPortfolioChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryPortfolioHourlyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryPortfolioHourlyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioHourlyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioHourlyChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryPortfolioDailyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryPortfolioDailyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioDailyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioDailyChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryMarketStakeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryMarketStakesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MarketStake_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<MarketStake_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryInsuranceStakeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryInsuranceStakesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InsuranceStake_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InsuranceStake_Filter>;
  block?: Maybe<Block_Height>;
};

export type QueryPositionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type QueryPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
  block?: Maybe<Block_Height>;
};

export type Query_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type SipToken = {
  __typename?: 'SIPToken';
  /**  Token address  */
  id: Scalars['ID'];
  insurance: InsuranceFund;
  symbol: Scalars['String'];
  totalSupply?: Maybe<Scalars['BigInt']>;
};

export type SipToken_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  insurance?: Maybe<Scalars['String']>;
  insurance_not?: Maybe<Scalars['String']>;
  insurance_gt?: Maybe<Scalars['String']>;
  insurance_lt?: Maybe<Scalars['String']>;
  insurance_gte?: Maybe<Scalars['String']>;
  insurance_lte?: Maybe<Scalars['String']>;
  insurance_in?: Maybe<Array<Scalars['String']>>;
  insurance_not_in?: Maybe<Array<Scalars['String']>>;
  insurance_contains?: Maybe<Scalars['String']>;
  insurance_not_contains?: Maybe<Scalars['String']>;
  insurance_starts_with?: Maybe<Scalars['String']>;
  insurance_not_starts_with?: Maybe<Scalars['String']>;
  insurance_ends_with?: Maybe<Scalars['String']>;
  insurance_not_ends_with?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbol_not?: Maybe<Scalars['String']>;
  symbol_gt?: Maybe<Scalars['String']>;
  symbol_lt?: Maybe<Scalars['String']>;
  symbol_gte?: Maybe<Scalars['String']>;
  symbol_lte?: Maybe<Scalars['String']>;
  symbol_in?: Maybe<Array<Scalars['String']>>;
  symbol_not_in?: Maybe<Array<Scalars['String']>>;
  symbol_contains?: Maybe<Scalars['String']>;
  symbol_not_contains?: Maybe<Scalars['String']>;
  symbol_starts_with?: Maybe<Scalars['String']>;
  symbol_not_starts_with?: Maybe<Scalars['String']>;
  symbol_ends_with?: Maybe<Scalars['String']>;
  symbol_not_ends_with?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['BigInt']>;
  totalSupply_not?: Maybe<Scalars['BigInt']>;
  totalSupply_gt?: Maybe<Scalars['BigInt']>;
  totalSupply_lt?: Maybe<Scalars['BigInt']>;
  totalSupply_gte?: Maybe<Scalars['BigInt']>;
  totalSupply_lte?: Maybe<Scalars['BigInt']>;
  totalSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
};

export enum SipToken_OrderBy {
  Id = 'id',
  Insurance = 'insurance',
  Symbol = 'symbol',
  TotalSupply = 'totalSupply',
}

export type SlpToken = {
  __typename?: 'SLPToken';
  /**  Token address  */
  id: Scalars['ID'];
  symbol: Scalars['String'];
  market: Market;
  totalSupply?: Maybe<Scalars['BigInt']>;
};

export type SlpToken_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  symbol?: Maybe<Scalars['String']>;
  symbol_not?: Maybe<Scalars['String']>;
  symbol_gt?: Maybe<Scalars['String']>;
  symbol_lt?: Maybe<Scalars['String']>;
  symbol_gte?: Maybe<Scalars['String']>;
  symbol_lte?: Maybe<Scalars['String']>;
  symbol_in?: Maybe<Array<Scalars['String']>>;
  symbol_not_in?: Maybe<Array<Scalars['String']>>;
  symbol_contains?: Maybe<Scalars['String']>;
  symbol_not_contains?: Maybe<Scalars['String']>;
  symbol_starts_with?: Maybe<Scalars['String']>;
  symbol_not_starts_with?: Maybe<Scalars['String']>;
  symbol_ends_with?: Maybe<Scalars['String']>;
  symbol_not_ends_with?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  market_not?: Maybe<Scalars['String']>;
  market_gt?: Maybe<Scalars['String']>;
  market_lt?: Maybe<Scalars['String']>;
  market_gte?: Maybe<Scalars['String']>;
  market_lte?: Maybe<Scalars['String']>;
  market_in?: Maybe<Array<Scalars['String']>>;
  market_not_in?: Maybe<Array<Scalars['String']>>;
  market_contains?: Maybe<Scalars['String']>;
  market_not_contains?: Maybe<Scalars['String']>;
  market_starts_with?: Maybe<Scalars['String']>;
  market_not_starts_with?: Maybe<Scalars['String']>;
  market_ends_with?: Maybe<Scalars['String']>;
  market_not_ends_with?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['BigInt']>;
  totalSupply_not?: Maybe<Scalars['BigInt']>;
  totalSupply_gt?: Maybe<Scalars['BigInt']>;
  totalSupply_lt?: Maybe<Scalars['BigInt']>;
  totalSupply_gte?: Maybe<Scalars['BigInt']>;
  totalSupply_lte?: Maybe<Scalars['BigInt']>;
  totalSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
};

export enum SlpToken_OrderBy {
  Id = 'id',
  Symbol = 'symbol',
  Market = 'market',
  TotalSupply = 'totalSupply',
}

export type Subscription = {
  __typename?: 'Subscription';
  insuranceFund?: Maybe<InsuranceFund>;
  insuranceFunds: Array<InsuranceFund>;
  market?: Maybe<Market>;
  markets: Array<Market>;
  price?: Maybe<Price>;
  prices: Array<Price>;
  priceDailyChange?: Maybe<PriceDailyChange>;
  priceDailyChanges: Array<PriceDailyChange>;
  slptoken?: Maybe<SlpToken>;
  slptokens: Array<SlpToken>;
  siptoken?: Maybe<SipToken>;
  siptokens: Array<SipToken>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  portfolioChange?: Maybe<PortfolioChange>;
  portfolioChanges: Array<PortfolioChange>;
  portfolioHourlyChange?: Maybe<PortfolioHourlyChange>;
  portfolioHourlyChanges: Array<PortfolioHourlyChange>;
  portfolioDailyChange?: Maybe<PortfolioDailyChange>;
  portfolioDailyChanges: Array<PortfolioDailyChange>;
  marketStake?: Maybe<MarketStake>;
  marketStakes: Array<MarketStake>;
  insuranceStake?: Maybe<InsuranceStake>;
  insuranceStakes: Array<InsuranceStake>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};

export type SubscriptionInsuranceFundArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionInsuranceFundsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InsuranceFund_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InsuranceFund_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionMarketArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionMarketsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Market_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Market_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionPriceArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionPricesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Price_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Price_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionPriceDailyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionPriceDailyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PriceDailyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PriceDailyChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionSlptokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionSlptokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SlpToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<SlpToken_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionSiptokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionSiptokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SipToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<SipToken_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Account_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Account_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionPortfolioChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionPortfolioChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionPortfolioHourlyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionPortfolioHourlyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioHourlyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioHourlyChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionPortfolioDailyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionPortfolioDailyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PortfolioDailyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PortfolioDailyChange_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionMarketStakeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionMarketStakesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MarketStake_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<MarketStake_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionInsuranceStakeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionInsuranceStakesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InsuranceStake_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InsuranceStake_Filter>;
  block?: Maybe<Block_Height>;
};

export type SubscriptionPositionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
};

export type SubscriptionPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
  block?: Maybe<Block_Height>;
};

export type Subscription_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny',
}

export type AccountQueryVariables = Exact<{
  accountAddress: Scalars['ID'];
}>;

export type AccountQuery = { __typename?: 'Query' } & {
  account?: Maybe<
    { __typename?: 'Account' } & {
      positions: Array<
        { __typename?: 'Position' } & Pick<
          Position,
          | 'id'
          | 'collateral'
          | 'notional'
          | 'isLong'
          | 'isActive'
          | 'isLiquidated'
          | 'leverage'
          | 'initialPrice'
        > & {
            market: { __typename?: 'Market' } & Pick<Market, 'id'> & {
                price?: Maybe<
                  { __typename?: 'Price' } & Pick<
                    Price,
                    'marketPrice' | 'oraclePrice'
                  >
                >;
              };
          }
      >;
      insuranceStakes: Array<
        { __typename?: 'InsuranceStake' } & Pick<
          InsuranceStake,
          'collateralTotal' | 'initialStakedPnl' | 'sipTotal'
        >
      >;
      marketStakes: Array<
        { __typename?: 'MarketStake' } & Pick<
          MarketStake,
          'slpTotal' | 'collateralTotal' | 'initialStakedPnl'
        > & { market: { __typename?: 'Market' } & Pick<Market, 'id'> }
      >;
    }
  >;
};

export type InsuranceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InsuranceQuery = { __typename?: 'Query' } & {
  insuranceFund?: Maybe<
    { __typename?: 'InsuranceFund' } & Pick<
      InsuranceFund,
      'id' | 'liquidity' | 'stakedPnl'
    > & {
        token?: Maybe<
          { __typename?: 'SIPToken' } & Pick<SipToken, 'id' | 'totalSupply'>
        >;
      }
  >;
};

export type MarketsQueryVariables = Exact<{ [key: string]: never }>;

export type MarketsQuery = { __typename?: 'Query' } & {
  markets: Array<
    { __typename?: 'Market' } & Pick<
      Market,
      'id' | 'name' | 'assetSymbol' | 'liquidity' | 'stakedPnl'
    > & {
        price?: Maybe<
          { __typename?: 'Price' } & Pick<Price, 'oraclePrice' | 'marketPrice'>
        >;
        token: { __typename?: 'SLPToken' } & Pick<
          SlpToken,
          'id' | 'totalSupply'
        >;
      }
  >;
};

export const AccountDocument = gql`
  query account($accountAddress: ID!) {
    account(id: $accountAddress) {
      positions(where: { isActive: true }) {
        id
        collateral
        notional
        isLong
        isActive
        isLiquidated
        leverage
        market {
          id
          price {
            marketPrice
            oraclePrice
          }
        }
        initialPrice
      }
      insuranceStakes {
        collateralTotal
        initialStakedPnl
        sipTotal
      }
      marketStakes {
        market {
          id
        }
        slpTotal
        collateralTotal
        initialStakedPnl
      }
    }
  }
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      accountAddress: // value for 'accountAddress'
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<
  AccountQuery,
  AccountQueryVariables
>;
export const InsuranceDocument = gql`
  query insurance($id: ID!) {
    insuranceFund(id: $id) {
      id
      liquidity
      stakedPnl
      token {
        id
        totalSupply
      }
    }
  }
`;

/**
 * __useInsuranceQuery__
 *
 * To run a query within a React component, call `useInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<InsuranceQuery, InsuranceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuranceQuery, InsuranceQueryVariables>(
    InsuranceDocument,
    options,
  );
}
export function useInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InsuranceQuery,
    InsuranceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuranceQuery, InsuranceQueryVariables>(
    InsuranceDocument,
    options,
  );
}
export type InsuranceQueryHookResult = ReturnType<typeof useInsuranceQuery>;
export type InsuranceLazyQueryHookResult = ReturnType<
  typeof useInsuranceLazyQuery
>;
export type InsuranceQueryResult = Apollo.QueryResult<
  InsuranceQuery,
  InsuranceQueryVariables
>;
export const MarketsDocument = gql`
  query markets {
    markets {
      id
      name
      assetSymbol
      liquidity
      stakedPnl
      price {
        oraclePrice
        marketPrice
      }
      token {
        id
        totalSupply
      }
    }
  }
`;

/**
 * __useMarketsQuery__
 *
 * To run a query within a React component, call `useMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketsQuery(
  baseOptions?: Apollo.QueryHookOptions<MarketsQuery, MarketsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketsQuery, MarketsQueryVariables>(
    MarketsDocument,
    options,
  );
}
export function useMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketsQuery,
    MarketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketsQuery, MarketsQueryVariables>(
    MarketsDocument,
    options,
  );
}
export type MarketsQueryHookResult = ReturnType<typeof useMarketsQuery>;
export type MarketsLazyQueryHookResult = ReturnType<typeof useMarketsLazyQuery>;
export type MarketsQueryResult = Apollo.QueryResult<
  MarketsQuery,
  MarketsQueryVariables
>;
