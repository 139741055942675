import React, { useCallback, useRef, useState } from 'react';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popper, ClickAwayListener } from '@material-ui/core';

import block from 'bem-cn-lite';

import './style.scss';

const b = block('select');

export type OptionProps<T> = {
  option: T;
  isActive: boolean;
};

type OptionContainerProps<T> = OptionProps<T> & {
  Option: React.ComponentType<OptionProps<T>>;
  onSelect(option: T): void;
};

export type ActiveOptionProps<T> = {
  option: T | null;
};

type Props<T> = {
  options: T[];
  activeOption: T | null;
  onSelect(option: T): void;
  getID?(option: T): string;
  ActiveOption?: React.ComponentType<ActiveOptionProps<T>>;
  Option?: React.ComponentType<OptionProps<T>>;
};

function OptionContainer<T>(
  props: React.PropsWithChildren<OptionContainerProps<T>>,
) {
  const { isActive, option, onSelect, Option } = props;
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onSelect(option);
    },
    [onSelect, option],
  );

  return (
    <div className={b('option', { active: isActive })} onClick={handleClick}>
      <div className={b('option-background')} />
      <div className={b('option-text')}>
        <Option isActive={isActive} option={option} />
      </div>
    </div>
  );
}

function DefaultOption<T>({ option }: OptionProps<T>) {
  return <div>{`${option}`}</div>;
}

function DefaultActiveOption<T>({ option }: ActiveOptionProps<T>) {
  return <div className={b('active-option')}>{`${option}`}</div>;
}

function Select<T>(props: Props<T>) {
  const {
    activeOption,
    options,
    onSelect,
    Option = DefaultOption,
    ActiveOption = DefaultActiveOption,
    getID = o => `${o}`,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const buttonRef = useRef<HTMLDivElement>(null);
  const blockWidth = buttonRef.current?.offsetWidth ?? 0;

  return (
    <div className={b({ expanded: isExpanded })}>
      <div
        ref={buttonRef}
        className={b('active-option-container')}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ActiveOption option={props.activeOption} />
        <div className={b('active-option-icon')}>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
      </div>
      <Popper
        open={isExpanded}
        anchorEl={buttonRef.current}
        placement="bottom-start"
        modifiers={{}}
        style={{ zIndex: 2 }}
      >
        <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
          <div
            className={b('options', { displayed: isExpanded })}
            style={{ width: blockWidth }}
          >
            {options.map((x, index) => (
              <OptionContainer
                key={index}
                option={x}
                Option={Option}
                isActive={
                  activeOption ? getID(x) === getID(activeOption) : false
                }
                onSelect={value => {
                  onSelect(value);
                  setIsExpanded(false);
                }}
              />
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export const Component = React.memo(Select) as typeof Select;
