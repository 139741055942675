import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import block from 'bem-cn-lite';
import { PositionKind } from 'model';

import './style.scss';

type Props = {
  disabled?: boolean;
  submitting?: boolean;
  onSubmit(positionKind: PositionKind): void;
};

const b = block('stake-buttons');

export const StakeButtons: React.FC<Props> = ({ disabled, onSubmit }) => (
  <div className={b()}>
    <button
      className={b('button', { type: 'long', disabled })}
      disabled={disabled}
      type="submit"
      onClick={() => onSubmit('long')}
    >
      <div className={b('icon')}>
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
      Long
    </button>
    <button
      className={b('button', { type: 'short', disabled })}
      disabled={disabled}
      type="submit"
      onClick={() => onSubmit('short')}
    >
      Short
      <div className={b('icon')}>
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    </button>
  </div>
);
