import React, { useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';
import { useWeb3 } from 'hooks/useWeb3';

import {
  Button,
  Preloader,
  Modal,
  CurrencyInput,
  DataGridColumn,
  Text,
  Currency,
  Widget,
  DataGrid,
} from 'components';

import { useForm } from './useForm';

import block from 'bem-cn-lite';
import BN from 'bignumber.js';
import { useInsurance } from 'graphqlAPI';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';

import './style.scss';

const b = block('unstake-insurance-modal');

function UnstakeInsuranceModal() {
  const { enqueueSnackbar } = useSnackbar();
  const { areRequestsLocked, account, connect } = useAccount();
  const { insuranceAPI, slpTokensAPI } = useWeb3();
  const { insurance } = useInsurance();
  const { addTransaction } = useTransaction();
  const { data: balance = null } = useQuery(
    ['SIPBalance', account, insurance],
    () => slpTokensAPI.balanceOf(account!, insurance!.sipAddress),
    { refetchInterval: 5000, enabled: account !== null && insurance !== null },
  );
  const { pop } = useModal();
  const [isUnstaking, setUnstaking] = useState(false);
  const {
    insuranceStake,
    changeInsurance,
    setMaxBalance,
    makeSubmitHandler,
    error,
    isValid,
    dirty,
    isProfitCalculated,
    gridData,
  } = useForm(balance);

  const unstake = (insurance: BN) => {
    Promise.resolve()
      .then(() => setUnstaking(true))
      .then(() => insuranceAPI.unstake(insurance))
      .then(tx => {
        addTransaction(tx, `Unstake insurance`);
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setUnstaking(true))
      .finally(pop);
  };

  const handleSubmit = makeSubmitHandler(() => {
    if (areRequestsLocked) {
      return connect();
    }

    if (insuranceStake) {
      return unstake(insuranceStake);
    }
  });

  const buttonContent = areRequestsLocked ? 'Connect' : 'Unstake insurance';

  const gridColumns: DataGridColumn[] = useMemo(() => {
    return [
      [
        { label: '1', minWidth: 'auto' },
        { label: '2', minWidth: '100px' },
        { label: '3', minWidth: 'auto', align: 'end', disablePadding: 'right' },
      ],
    ];
  }, []);

  const formattedGridData = useMemo(() => {
    return gridData.map(([label, value, unit]) => [
      <Text typography="heading-l" color="secondary">
        {label}
      </Text>,
      isProfitCalculated ? (
        <CircularProgress size={14} />
      ) : (
        <Text typography="text-m" color="secondary">
          {value}
        </Text>
      ),
      typeof unit === 'string' ? <Currency.Component currency={unit} /> : '',
    ]);
  }, [isProfitCalculated, gridData]);

  return (
    <Modal title="Unstake insurance" size="medium">
      <div className={b()}>
        <form onSubmit={handleSubmit}>
          <div className={b('input')}>
            <CurrencyInput.Component
              label="Insurance"
              value={insuranceStake?.toString() ?? null}
              onChange={changeInsurance}
              error={dirty ? error : undefined}
              rightPart={
                <CurrencyInput.Limiter
                  currency="sip"
                  value={balance}
                  onButtonClick={setMaxBalance}
                  disabled={!!balance && insuranceStake?.isEqualTo(balance)}
                />
              }
            />
          </div>
          <Widget.SubSection>
            <DataGrid
              columns={gridColumns}
              data={formattedGridData}
              hasHead={false}
            />
          </Widget.SubSection>
          <Button
            className={b('unstake-insurance-button')}
            variant="primary"
            disabled={!isValid || isUnstaking || isProfitCalculated}
          >
            {isUnstaking ? <Preloader /> : buttonContent}
          </Button>
        </form>
      </div>
    </Modal>
  );
}

const Component = React.memo(UnstakeInsuranceModal);
export { Component as UnstakeInsuranceModal };
