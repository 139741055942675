import { useEffect } from 'react';

import { NetworkStatus } from '@apollo/client/core/networkStatus';

import { from256 } from 'utils/bigNumber';

import { useErrorHandler } from 'hooks/useErrorHandler';

import { useMarketsQuery, MarketsQuery } from '../types';

import memoize from 'memoize-one';
import { Market } from 'model';

const convert = memoize(
  (response?: MarketsQuery): Market[] =>
    response?.markets?.map(market => ({
      id: market.id,
      slpAddress: market.token.id,
      assetSymbol: market.assetSymbol,
      liquidity: from256(market.liquidity ?? 0),
      fixedRate: from256(market.price?.marketPrice ?? 0),
      floatingRate: from256(market.price?.oraclePrice ?? 0),
      stakedPNL: from256(market.stakedPnl ?? 0),
      totalSupply: from256(market.token.totalSupply ?? 0),
    })) ?? [],
);

export function useMarkets() {
  const { showError } = useErrorHandler();
  const { data, loading, startPolling, stopPolling, networkStatus } =
    useMarketsQuery({});

  useEffect(() => {
    startPolling(10000);
    return stopPolling;
  }, []);

  useEffect(() => {
    if (networkStatus === NetworkStatus.error) {
      showError({ message: 'Network unavailable. Trying to connect...' });
    }
  }, [networkStatus]);

  return { markets: convert(data), loading };
}
