import classes from 'utils/classes';

import { Button, PendingTransaction } from 'components';

import { UserBlock } from './UserBlock';
import logo from 'assets/logo_dark.png';

import { NavLink, useHistory } from 'react-router-dom';

import style from './style.module.scss';

interface Props {
  className?: string;
}

const Header = ({ className }: Props) => {
  const history = useHistory();

  return (
    <div className={classes(style.container, className)}>
      <div className={style.brand}>
        <NavLink to="/" className={style.logoLink}>
          <img src={logo} alt="logo" className={style.logoImg} />
        </NavLink>
      </div>
      <div className={style.content}>
        <PendingTransaction />
        {/* <Button
          variant="primary"
          onClick={() => {
            history.push('/feedback-contest')
          }}
          className={style.contestBtn}
        >
          Strips Feedback Contest
        </Button> */}
        <UserBlock />
      </div>
    </div>
  );
};

export default Header;
