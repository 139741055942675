import { useContext } from 'react';

import { AccountContext } from '../contexts/AccountContext';

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context) {
    return context;
  }
  throw Error('Context Provider is not provided!');
};
