import React from 'react';

import { BN } from 'utils/bigNumber';

import { ValueCard, NumericValue } from 'components';

import block from 'bem-cn-lite';

import './style.scss';

type Props = {
  liquidity: BN | null;
  marketLiquidity: BN | null;
};

const b = block('add-liquidity-cards');

function CurrencyCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="currency"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

function PercentsCardValue({
  data,
  fontWeight,
  fractionDigits,
}: ValueCard.ValueProps<string>) {
  return (
    <NumericValue.Component
      kind="percents"
      value={data}
      fontWeight={fontWeight}
      fractionDigits={fractionDigits}
    />
  );
}

function Cards({ liquidity, marketLiquidity }: Props) {
  return (
    <div className={b()}>
      <ValueCard.Component
        label="Pool Size (BUSD)"
        data={marketLiquidity ? marketLiquidity.toString() : ''}
        Value={CurrencyCardValue as any}
        tooltipData={marketLiquidity ? marketLiquidity.toString() : ''}
      />
      <ValueCard.Component
        label="Share of Pool"
        data={
          marketLiquidity && liquidity
            ? liquidity.dividedBy(marketLiquidity.plus(liquidity)).toString()
            : ''
        }
        Value={PercentsCardValue as any}
        tooltipData={
          marketLiquidity && liquidity
            ? liquidity.dividedBy(marketLiquidity.plus(liquidity)).toString()
            : ''
        }
      />
      <ValueCard.Component
        label="SLP to Receive"
        data={marketLiquidity && liquidity ? liquidity.toString() : ''}
        Value={CurrencyCardValue as any}
        tooltipData={marketLiquidity && liquidity ? liquidity.toString() : ''}
      />
    </div>
  );
}

const Component = React.memo(Cards);

export { Component as Cards };
