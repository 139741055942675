import React from 'react';

import { fractionToPercent } from 'utils/formatters';

import { useAccount } from 'hooks/useAccount';

import {
  Widget,
  MarketName,
  Button,
  DataGrid,
  NumberTooltip,
  Text,
} from 'components';
import Currency from 'components/LegacyCurrency';

import { useWeb3API } from 'contracts/apiHooks';

import { config } from 'config';
import { useAccountData, useMarkets } from 'graphqlAPI';
import { Market } from 'model';
import { useQuery } from 'react-query';

import style from './style.module.scss';

interface Props {
  onTradeClick(market: Market): void;
}

export const Markets = ({ onTradeClick }: Props) => {
  const { loading, markets } = useMarkets();
  const { account } = useAccount();
  const { positions } = useAccountData();
  const { stripsAPI } = useWeb3API();
  const { data: realTimeData = null } = useQuery(
    ['realTimeData', account],
    () => stripsAPI.getRealTimeData(account!),
    { refetchInterval: 10000, enabled: account !== null },
  );

  const getRate = (type: 'fixed' | 'floating', market: Market) => {
    const field = type === 'fixed' ? 'fixedRate' : 'floatingRate';
    return account && !!positions.length
      ? realTimeData?.priceData[market.id]?.[field]
      : market[field];
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <Widget.Component>
      <div>
        <div className={style.header}>
          <h2 className={style.heading}>Markets</h2>
        </div>
        <DataGrid
          columns={[
            [
              { label: 'Market' },
              { label: 'Name' },
              { label: 'Fixed Rate', colSpan: 2 },
              { label: 'Floating Rate', colSpan: 2 },
              { label: 'Staked Liquidity', sortable: true },
              { label: '24h Turnover', sortable: true },
              { label: '' },
            ],
            [
              { label: '' },
              { label: '' },
              { label: 'Current' },
              { label: '24h Change' },
              { label: 'Current' },
              { label: '24h Change' },
              { label: '' },
              { label: '' },
              { label: '' },
            ],
          ]}
          data={markets.map(market => [
            <MarketName marketID={market.id} />,
            config.markets[market.id].assetSymbol,
            <NumberTooltip.Component
              title={
                <Text typography="text-s">
                  {fractionToPercent(getRate('fixed', market), {
                    fractionDigits: 'all',
                  })}
                </Text>
              }
              placement="right"
            >
              {fractionToPercent(getRate('fixed', market))}
            </NumberTooltip.Component>,
            '-',
            <NumberTooltip.Component
              title={
                <Text typography="text-s">
                  {fractionToPercent(getRate('floating', market), {
                    fractionDigits: 'all',
                  })}
                </Text>
              }
              placement="right"
            >
              {fractionToPercent(getRate('floating', market))}
            </NumberTooltip.Component>,
            '-',
            <Currency amount={market.liquidity} code="BUSD" />,
            '-',
            <Button size="small" onClick={() => onTradeClick(market)}>
              Trade
            </Button>,
          ])}
        />
      </div>
    </Widget.Component>
  );
};

export default Markets;
