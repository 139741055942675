import { from256, BN } from './bigNumber';

export const fractionToPercent = (
  value: string | number | BN | null | undefined,
  options?: { fractionDigits?: number | 'all'; minimumFractionDigits?: number },
) => {
  if (value == null) return '';

  const minimumFractionDigits = options?.minimumFractionDigits ?? 2;
  const fractionDigits = options?.fractionDigits ?? minimumFractionDigits;

  let floatVal: BN | string | number;
  if (typeof value === 'string') {
    floatVal = parseFloat(value);
    if (isNaN(floatVal)) {
      return value;
    }
    floatVal = new BN(floatVal).multipliedBy(100);
  } else if (typeof value === 'number') {
    floatVal = new BN(value).multipliedBy(100);
  } else {
    floatVal = value.multipliedBy(100);
  }

  const [, fractionPart = ''] = floatVal.toString().split('.');
  const fractionPartLength = fractionPart.length;

  const decimalPlaces =
    fractionDigits === 'all'
      ? Math.max(fractionPartLength, minimumFractionDigits)
      : Math.max(fractionDigits, minimumFractionDigits);

  floatVal = floatVal.toFixed(decimalPlaces, BN.ROUND_UP);

  return `${floatVal}%`;
};

export const toCurrency = (
  str: string,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2,
) => {
  const floatVal = parseFloat(str);
  if (isNaN(floatVal)) {
    return str;
  }

  return `${floatVal.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  })}`;
};

export function formatPercents(
  value: string | number,
  minimumFractionDigits = 2,
) {
  if (value === '') {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
  });

  return `${formatter.format(Number(value))}%`;
}

export function formatCurrency(
  value: string | number | BN,
  fractionDigits: 'all' | number = 2,
) {
  if (value instanceof BN && fractionDigits === 'all') {
    return from256(value).toString();
  }

  if (value instanceof BN && fractionDigits !== 'all') {
    return from256(value).toFormat(fractionDigits, 1);
  }

  if (value === '') {
    return '';
  }

  if (fractionDigits === 'all') {
    return new BN(value).toString();
  }

  return new BN(value).toFixed(fractionDigits, 1);
}

export function formatRatio(value: string | number) {
  return `${value}x`;
}
