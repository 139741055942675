import React, { useEffect, useState } from 'react';

import { NumericValue, ValueCard } from './../../../../../components';
import { block } from './../../../../../utils/classname';

import './style.scss';

type Props = {
  availableToBuy?: string;
  availableToClaim?: string;
  priceFor?: string;
};

const b = block('trade-widget-cards');

function CardValue({ data }: ValueCard.ValueProps<string>) {
  if (data === '-') return <>{data}</>;
  return (
    <NumericValue.Component kind="currency" value={data} fractionDigits="all" />
  );
}

function Cards({ availableToBuy, priceFor, availableToClaim }: Props) {
  return (
    <div className={b()}>
      {availableToBuy && (
        <ValueCard.Component
          data={availableToBuy}
          label="Allocation (STRP Tokens)"
          Value={CardValue}
        />
      )}
      {priceFor && (
        <ValueCard.Component
          data={priceFor}
          label="Price (USDC)"
          Value={CardValue}
        />
      )}
      {availableToClaim && (
        <ValueCard.Component
          data={availableToClaim}
          label="Available (STRP Tokens)"
          Value={CardValue}
        />
      )}
    </div>
  );
}

const Component = React.memo(Cards);

export { Component as Cards };
