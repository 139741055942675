import { FromOverviewToTradeState } from '../types';
import { Markets } from 'features/showMarkets';
import { Portfolio } from 'features/showPortfolio';
import { Positions } from 'features/showPositions';

import block from 'bem-cn-lite';
import { Market } from 'model';
import { useHistory } from 'react-router';

import './style.scss';

const b = block('overview');

export function Overview() {
  const history = useHistory<FromOverviewToTradeState>();

  const redirectToTrade = (market: Market) => {
    history.push({
      pathname: '/trade',
      state: {
        market,
      },
    });
  };

  return (
    <div className={b()}>
      <Portfolio />
      <Positions />
      <Markets onTradeClick={redirectToTrade} />
    </div>
  );
}
