import React from 'react';

import { block } from 'utils/classname';

import { Widget } from 'components';

import { Form } from './views';

const b = block('receive-susd');

function ReceiveSUSDFrom() {
  return (
    <Widget.Component className={b()}>
      <Form />
    </Widget.Component>
  );
}

const Component = React.memo(ReceiveSUSDFrom);
export { Component as ReceiveSUSDFrom };
