import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';

import { Button } from 'components';
import { CurrencyInput } from 'components/CurrencyInputV2';

import { FixedRateWidget } from './FixedRateWidget';

import styled from 'styled-components';

const BuySellWidgetStyled = styled.div`
  display: flex;
  width: 430px;
  height: 411px;
  margin: 0 0 39px 32px;
  border-radius: 16px;
`;

const BackgroundStyled = styled.div`
  flex: 1;
  padding: 20px;
  background: ${({ theme }) => theme.colors.offBlack.ash};
  border-radius: 16px;
  backdrop-filter: blur(20px);
`;

const VectorStyled = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;

  /* Secondary/Darkmode */

  background: ${({ theme }) => theme.colors.secondary.base};
  opacity: 0.15;
  filter: blur(20px);
`;

const Separator = styled.div`
  height: 0;
  border: 1px solid;
  margin: 0 32px;
  border-color: ${({ theme }) => theme.colors.offBlack.placeHold};
`;

const ContentStyled = styled.div`
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  .content {
    flex: 1;
  }
`;

const ButtonContainer = styled(Box)`
  button,
  .fixed-rate-widget,
  .leveraged-select {
    flex: 1;
    margin: 0 12px;
  }
`;

export const BuySellWidget = () => {
  return (
    <BuySellWidgetStyled>
      <BackgroundStyled>
        <VectorStyled />
        <ContentStyled>
          <ButtonContainer
            display="flex"
            justifyContent="space-around"
            p="32px 20px"
          >
            <FixedRateWidget
              price={26.9}
              variation={11.75}
              label={'Fixed Rate'}
            />
            <Select
              native
              value={10}
              onChange={() => {}}
              label="Leverage"
              inputProps={{
                name: 'age',
                id: 'outlined-age-native-simple',
              }}
              className="leveraged-select"
            >
              <option aria-label="None" value="" />
              <option value={10}>10x</option>
              <option value={20}>5x</option>
              <option value={30}>1x</option>
            </Select>
          </ButtonContainer>
          <Separator />
          <Box
            display="flex"
            justifyContent="space-around"
            p="32px 32px 0 32px"
          >
            <CurrencyInput
              label="Amount"
              value={'999'}
              onChange={() => {}}
              currency="busd"
              disabled={false}
            />
          </Box>
          <ButtonContainer
            display="flex"
            justifyContent="space-around"
            p="20px"
          >
            <Button variant="success">Long</Button>
            <Button variant="danger">Short</Button>
          </ButtonContainer>
        </ContentStyled>
      </BackgroundStyled>
    </BuySellWidgetStyled>
  );
};
