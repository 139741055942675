import React from 'react';

import useModal from 'hooks/useModal';

import { Button, Text } from 'components';

import { AddLiquidityModal } from './AddLiquidityModal';

import block from 'bem-cn-lite';

import './style.scss';

const b = block('add-liquidity');

function AddLiquidity() {
  const modal = useModal();
  const openModal = () => modal.present(<AddLiquidityModal />);

  return (
    <div className={b()}>
      <Text as="h2" typography="heading-l">
        Liquidity
      </Text>
      <Text color="secondary">Add liquidity to receive LP tokens</Text>
      <Button className={b('add-button')} onClick={openModal}>
        Add Liquidity
      </Button>
    </div>
  );
}

const Component = React.memo(AddLiquidity);
export { Component as AddLiquidity };
