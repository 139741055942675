import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BN, format, zero } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import { MarketUnstackeProfit } from 'contracts/apiHooks';

import debounce from 'awesome-debounce-promise';
import { CurrencyID, Market } from 'model';
import { useAsyncAbortable } from 'react-async-hook';
import { useQuery } from 'react-query';

const validate = (
  liquidity: BN | null,
  balance: BN | null,
): string | undefined => {
  if (liquidity === null || liquidity.isLessThanOrEqualTo(0)) {
    return 'Field is required';
  }

  if (balance && liquidity.isGreaterThan(balance)) {
    return 'Not enough balance';
  }

  return undefined;
};

function useUnstakeProfit(
  market: Market | null,
  account: string | null | undefined,
  amount: BN | null,
) {
  const { marketAPI } = useWeb3();

  const debouncedCalcUnstakeProfit = useCallback(
    debounce(marketAPI.calcUnstakeProfit, 600),
    [marketAPI],
  );

  const dayInSeconds = 24 * 60 * 60;

  const secondsToDays = (seconds: number) => {
    return Math.round(seconds / dayInSeconds);
  };

  return useAsyncAbortable<
    Omit<MarketUnstackeProfit, number> | undefined,
    [Market | null, string | null | undefined, BN | null]
  >(
    async (_, market, account, amount) => {
      if (market && account && amount) {
        const profitData = await debouncedCalcUnstakeProfit(
          market.id,
          account,
          amount,
        );

        const { profit, unstakeAmount, fee, periodLeft } = profitData ?? {
          profit: zero,
          unstakeAmount: zero,
          fee: zero,
          periodLeft: 0,
        };
        return {
          profit,
          unstakeAmount,
          fee,
          periodLeft: secondsToDays(periodLeft),
        };
      }
    },
    [market, account, amount],
  );
}

export function useForm(market: Market) {
  const { slpAddress } = market;
  const { account } = useAccount();
  const { slpTokensAPI } = useWeb3();
  const [liquidity, setLiquidity] = useState<BN | null>(null);
  const [error, setError] = useState<string | undefined>();
  const [dirty, setDirty] = useState<boolean>(false);
  const { data: balance = null } = useQuery(
    ['SLPBalance', account, slpAddress],
    () => slpTokensAPI.balanceOf(account!, slpAddress),
    { refetchInterval: 5000, enabled: account !== null },
  );

  const isValid = !Boolean(error);

  const {
    loading: isProfitCalculated,
    result: { profit, unstakeAmount, fee, periodLeft } = {
      profit: zero,
      unstakeAmount: zero,
      fee: zero,
      periodLeft: 0,
    },
  } = useUnstakeProfit(market, account, liquidity);

  const gridData = useMemo<[string, string, CurrencyID?][]>(() => {
    const data: [string, string, CurrencyID?][] = [];
    data.push(['Profit earned', format(profit, 2), 'busd']);
    data.push(['Penalty (paid)', format(fee, 2), 'busd']);
    data.push(['Total returned', format(unstakeAmount, 2), 'busd']);
    data.push(['Penalty period remaining (days)', String(periodLeft) || '0']);
    return data;
  }, [profit, unstakeAmount, fee, periodLeft]);

  const changeLiquidity = (value: string | null) => {
    const valueBN = value ? new BN(value) : null;
    if (balance && valueBN) {
      setLiquidity(valueBN.isGreaterThan(balance) ? balance : valueBN);
    } else {
      setLiquidity(valueBN);
    }
    setDirty(true);
  };

  const setMaxBalance = () => balance && setLiquidity(balance);

  const makeSubmitHandler =
    (onSubmit: (liquidity: BN | null) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit(liquidity);
    };

  useEffect(() => {
    setError(validate(liquidity, balance));
  }, [liquidity]);

  return {
    liquidity,
    balance,
    error,
    dirty,
    isValid,
    setMaxBalance,
    changeLiquidity,
    makeSubmitHandler,
    isProfitCalculated,
    gridData,
  } as const;
}
