import { useEffect } from 'react';

import { NetworkStatus } from '@apollo/client/core/networkStatus';

import { BN, from256 } from 'utils/bigNumber';

import { useErrorHandler } from 'hooks/useErrorHandler';

import { useInsuranceQuery, InsuranceQuery } from '../types';

import { config } from 'config';

type Insurance = {
  id: string;
  sipAddress: string;
  liquidity: BN;
  stakedPNL: BN;
  totalSupply: BN;
};

const convert = (response?: InsuranceQuery): Insurance | null =>
  response?.insuranceFund
    ? {
        id: response.insuranceFund.id,
        sipAddress: response.insuranceFund.token?.id ?? '',
        liquidity: from256(response?.insuranceFund?.liquidity ?? 0),
        stakedPNL: from256(response?.insuranceFund?.stakedPnl ?? 0),
        totalSupply: from256(response?.insuranceFund.token?.totalSupply ?? 0),
      }
    : null;

export function useInsurance() {
  const { showError } = useErrorHandler();
  const { data, loading, startPolling, stopPolling, networkStatus } =
    useInsuranceQuery({
      variables: { id: config.contracts.insurance },
    });

  useEffect(() => {
    startPolling(10000);
    return stopPolling;
  }, []);

  useEffect(() => {
    if (networkStatus === NetworkStatus.error) {
      showError({ message: 'Network unavailable. Trying to connect...' });
    }
  }, [networkStatus]);

  return { insurance: convert(data), loading };
}
export type { Insurance };
