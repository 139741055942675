import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react';

import { BN, format } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useWeb3 } from 'hooks/useWeb3';

import { Position } from 'graphqlAPI';
import { CurrencyID } from 'model';
import { useQuery } from 'react-query';

type Action = 'add' | 'remove';

type FormState = {
  amount: BN | null;
  defaultAmount: BN | null;
  action: Action;
  defaultAction: 'add';
};

type Errors = Partial<{
  [error in keyof FormState]: string;
}>;

const ZERO = new BN(0);
const MARGIN_RATIO_RED_ZONE = 1.2;
const currentMinimumMargin = new BN(0.035); // TODO найти где взять и заменить

const validateAmount = (
  amount: BN | null,
  action: Action,
  isMarginRatioLessThanMinimum: boolean,
) => {
  if (amount === null || amount.isLessThanOrEqualTo(ZERO)) {
    return 'Field is required';
  }
  if (isMarginRatioLessThanMinimum) {
    return 'Margin Ratio is less than minimum';
  }
  return undefined;
};

export function useFormState(position: Position | null) {
  const { account } = useAccount();
  const { stripsAPI, busdAPI } = useWeb3();
  const [formState, setFormState] = useState<FormState & { isDirty: boolean }>({
    amount: null,
    defaultAmount: null,
    action: 'add',
    defaultAction: 'add',
    isDirty: false,
  });

  const [errors, setErrors] = useState<Errors>({});
  const isValid = !Object.values(errors).some(Boolean);
  const createSubmitHandler =
    (onSubmit: (formState: FormState) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      isValid && onSubmit(formState);
    };

  const { data: realTimeData = null } = useQuery(
    ['realTimeData', account],
    () => stripsAPI.getRealTimeData(account!),
    { refetchInterval: 10000, enabled: account !== null },
  );

  const { data: balance = null } = useQuery(
    ['balance', account],
    () => busdAPI.getBalance(account!),
    { refetchInterval: 5000, enabled: account !== null },
  );

  const setMaxAmount = () => {
    if (maxAmount !== null) {
      setFormState(prevState => ({
        ...prevState,
        amount: maxAmount,
      }));
    }
  };

  const handleAmountChange = useCallback((value: string | null) => {
    setFormState(prevState => ({
      ...prevState,
      amount: value ? new BN(value) : null,
      isDirty: calcIsDirty({
        ...prevState,
        amount: value ? new BN(value) : null,
      }),
    }));
  }, []);

  const clearAmount = useCallback(
    () =>
      setFormState(prevState => ({
        ...prevState,
        amount: null,
      })),
    [],
  );

  const handleActionChange = useCallback((value: Action) => {
    setFormState(prevState => ({
      ...prevState,
      action: value,
    }));
  }, []);

  const maxAmount = useMemo(() => {
    return formState.action === 'add' ? balance : position?.collateral ?? ZERO;
  }, [formState.action, balance, position?.collateral]);

  const currentMarginRatio = useMemo(() => {
    if (!position) return ZERO;
    const marginRatio = realTimeData?.pnlData.marginRatio;
    return marginRatio ?? ZERO;
  }, [position, realTimeData?.pnlData]);

  const calculatedCollateral = useMemo(() => {
    if (!position?.collateral) return ZERO;
    return calcCollateral(
      formState.amount ?? ZERO,
      position.collateral,
      formState.action,
    );
  }, [formState?.amount, position?.collateral, formState.action]);

  const calculatedMarginRatio = useMemo(() => {
    if (!position) return ZERO;
    return calcMarginRatio(calculatedCollateral, position, currentMarginRatio);
  }, [currentMarginRatio, position, calculatedCollateral]);

  const calculatedPnl = useMemo(() => {
    if (!position) return ZERO;
    return calcPnl(position, currentMarginRatio);
  }, [currentMarginRatio, position]);

  const calculatedMinimumRequiredCollateral = useMemo(() => {
    if (!position) return ZERO;
    return calcMinimumRequiredCollateral(
      position,
      calculatedPnl,
      currentMinimumMargin,
    );
  }, [calculatedPnl, position]);

  const isMarginRatioLessThanMinimum = calcIsMarginRatioLessThanMinimum(
    calculatedMarginRatio,
    currentMinimumMargin,
  );

  const gridData = useMemo<[string, string, CurrencyID][]>(() => {
    const data: [string, string, CurrencyID][] = [];
    data.push(['Collateral', calculatedCollateral.toString(), 'busd']);
    data.push([
      'Margin Ratio',
      `${format(calculatedMarginRatio.times(100), 2)}%`,
      'busd',
    ]);
    if (calculatedMinimumRequiredCollateral.isGreaterThan(ZERO)) {
      data.push([
        'Minimum Required collateral ',
        calculatedMinimumRequiredCollateral.toString(),
        'busd',
      ]);
    }
    data.push(['What if price', '-', 'busd']);
    return data;
  }, [
    calculatedCollateral,
    calculatedMarginRatio,
    calculatedMinimumRequiredCollateral,
  ]);

  useEffect(() => {
    setErrors(prevErrors => ({
      ...prevErrors,
      amount: validateAmount(
        formState.amount,
        formState.action,
        isMarginRatioLessThanMinimum,
      ),
    }));
  }, [formState.action, formState.amount, isMarginRatioLessThanMinimum]);

  useEffect(() => {
    if (formState.isDirty) return;
    setFormState(prevState => ({
      ...prevState,
      defaultAmount: !formState.isDirty
        ? calculatedMinimumRequiredCollateral
        : formState.defaultAmount,
    }));
  }, [
    calculatedMinimumRequiredCollateral,
    formState.defaultAmount,
    formState.isDirty,
  ]);

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      isDirty: calcIsDirty(formState),
    }));
  }, [
    formState.action,
    formState.amount,
    formState.defaultAction,
    formState.defaultAmount,
  ]);

  useLayoutEffect(() => {
    if (maxAmount && formState.amount?.isGreaterThan(maxAmount)) {
      setFormState(prevState => ({
        ...prevState,
        amount: maxAmount,
      }));
    }
  }, [formState.amount, formState.action, maxAmount]);

  return {
    isDirty: formState.isDirty,
    isValid,
    errors,
    amount: formState.amount,
    defaultAmount: formState.defaultAmount,
    action: formState.action,
    balance,
    maxAmount,
    setMaxAmount,
    gridData,
    clearAmount,
    handleAmountChange,
    handleActionChange,
    createSubmitHandler,
  } as const;
}

function calcIsDirty(formState: FormState) {
  let isDirty: boolean;
  if (formState.action !== formState.defaultAction) {
    isDirty = true;
  } else if (formState.defaultAmount && formState.amount) {
    isDirty = !formState.defaultAmount.isEqualTo(formState.amount);
  } else {
    isDirty = formState.defaultAmount !== formState.amount;
  }
  return isDirty;
}

function calcCollateral(amount: BN, collateral: BN, action: Action) {
  return action === 'add' ? collateral.plus(amount) : collateral.minus(amount);
}

function calcMarginRatio(
  calculatedCollateral: BN,
  position: Position,
  currentMargin: BN,
) {
  if (position?.notional.isEqualTo(ZERO)) return ZERO;
  return (
    calculatedCollateral
      .plus(
        currentMargin
          .multipliedBy(position.notional)
          .minus(position.collateral),
      )
      .dividedBy(position.notional) ?? ZERO
  );
}

function calcMinimumRequiredCollateral(
  position: Position,
  calculatedPnl: BN,
  currentMinimumMargin: BN,
) {
  const value = position.notional
    .multipliedBy(currentMinimumMargin.multipliedBy(MARGIN_RATIO_RED_ZONE))
    .minus(calculatedPnl)
    .minus(position.collateral)
    .decimalPlaces(2, BN.ROUND_UP);
  return value.isNegative() ? ZERO : value;
}

function calcIsMarginRatioLessThanMinimum(
  calculatedMarginRatio: BN,
  currentMinimumMargin: BN,
) {
  return calculatedMarginRatio.isLessThan(
    currentMinimumMargin.multipliedBy(MARGIN_RATIO_RED_ZONE),
  );
}

function calcPnl(position: Position, currentMarginRatio: BN) {
  return currentMarginRatio
    .multipliedBy(position.notional)
    .minus(position.collateral);
}
