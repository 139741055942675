import React, { useState } from 'react';

import { zero, BN } from 'utils/bigNumber';
import { block } from 'utils/classname';

import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';

import { Button, Preloader, Modal, CurrencyInput } from 'components';

import { Cards } from './Cards';
import { useForm } from './useForm';
import { useWeb3API } from 'contracts/apiHooks/useWeb3API';

import { useInsurance } from 'graphqlAPI';
import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('stake-insurance-modal');

function StakeInsuranceModal() {
  const { areRequestsLocked, connect } = useAccount();
  const { busdAPI, insuranceAPI } = useWeb3API();
  const { enqueueSnackbar } = useSnackbar();
  const { insurance } = useInsurance();

  const { addTransaction } = useTransaction();

  const [isStaking, setStaking] = useState(false);
  const { pop } = useModal();
  const {
    insuranceStake,
    balance,
    changeInsurance,
    setMaxBalance,
    makeSubmitHandler,
    error,
    isValid,
    dirty,
    clearAmount,
  } = useForm();

  const handleFormSubmit = makeSubmitHandler(() => {
    if (areRequestsLocked) {
      return connect();
    }

    if (insuranceStake) {
      return stake(insuranceStake);
    }
  });

  const stake = (insuranceStake: BN) =>
    Promise.resolve()
      .then(() => setStaking(true))
      .then(() => busdAPI.approve(insuranceAPI.address, insuranceStake))
      .then(tx => {
        tx !== '' && addTransaction(tx, `Approving BUSD`);
      })
      .then(() => insuranceAPI.stake(insuranceStake))
      .then(tx => {
        addTransaction(tx, `Stake insurance`);
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setStaking(false))
      .finally(pop);

  const buttonContent = areRequestsLocked ? 'Connect' : 'Stake insurance';

  return (
    <Modal title="Stake insurance" size="medium">
      <div className={b()}>
        <form onSubmit={handleFormSubmit}>
          <div className={b('input')}>
            <CurrencyInput.Component
              label="Insurance"
              value={insuranceStake?.toString() ?? null}
              onChange={changeInsurance}
              error={dirty ? error : undefined}
              rightPart={
                <CurrencyInput.Limiter
                  currency="busd"
                  value={balance}
                  onButtonClick={setMaxBalance}
                  disabled={
                    !!insuranceStake && !!balance?.isEqualTo(insuranceStake)
                  }
                />
              }
              onClear={clearAmount}
            />
          </div>
          <div className={b('cards')}>
            <Cards
              insurance={insuranceStake}
              poolSize={insurance?.liquidity ?? zero}
            />
          </div>
          <Button
            className={b('stake-insurance-button')}
            variant="primary"
            disabled={!isValid}
          >
            {isStaking ? <Preloader /> : buttonContent}
          </Button>
        </form>
      </div>
    </Modal>
  );
}

const Component = React.memo(StakeInsuranceModal);
export { Component as StakeInsuranceModal };
