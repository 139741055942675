import React from 'react';

import styled, { css } from 'styled-components';

type Props = {
  size?: 'small' | 'medium' | 'large' | 'huge';
  children?: any;
  onClick?: (e: any) => void;
  className?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'clear';
  block?: boolean;
  isVisible?: boolean;
  rounded?: boolean;
  outlined?: boolean;
} & Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'disabled' | 'color'
>;

//@todo: I keep them as param because we don't have any styled option in the project so it's easier to use this file as template for now
const sizeOptions = (theme: any) => {
  return {
    small: {
      fontSize: '0.7rem',
      padding: '0 8px',
      borderRadius: '8px',
    },
    medium: {
      fontSize: '0.8rem',
      height: '56px',
      padding: '0 14px',
      borderRadius: '12px',
    },
    large: {
      fontSize: '0.9rem',
      height: '64px',
      padding: '0 15px',
      borderRadius: '12px',
    },
    huge: {
      fontSize: '0.9rem',
      height: '66px',
      padding: '0 15px',
      borderRadius: '16px',
    },
  };
};

const ButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.2s background-color, 0.2s color;
  user-select: none;
  ${({ variant }) =>
    css`
      color: ${({ theme }) => theme.colors[variant].color};
      background-color: ${({ theme }) => theme.colors[variant].base};
      border: 8px solid transparent;
      :hover {
        background-color: ${({ theme }) => theme.colors[variant].light};
        border: ${({ theme }) => `solid 8px ${theme.colors[variant].light}}`};
      }
      :focus {
        border: solid 8px #4e4b66;
      }
    `}

  ${({ variant, outlined }) =>
    outlined &&
    css`
      color: ${({ theme }) => theme.colors[variant].base};
      border: 1px solid ${({ theme }) => theme.colors[variant].base};
      background-color: transparent;
      :hover {
        background-color: ${({ theme }) => theme.colors[variant].light};
        border: ${({ theme }) => `solid 1px ${theme.colors[variant].light}}`};
      }
      :focus {
        border: solid 1px #4e4b66;
      }
    `}
    

  ${({ size }) =>
    css`
      font-size: ${({ theme }) => sizeOptions(theme)[size].fontSize};
      height: ${({ theme }) => sizeOptions(theme)[size].height};
      padding: ${({ theme }) => sizeOptions(theme)[size].padding};
      border-radius: ${({ theme }) => sizeOptions(theme)[size].borderRadius};
    `}

    ${({ variant }) =>
    variant === 'clear' &&
    css`
      border: 0;
      height: 20px;
      :hover {
        background-color: ${({ theme }) => theme.colors[variant].light};
        border: 0;
      }
      :focus {
        border: 0;
      }
    `}

    ${({ block }) =>
    block &&
    css`
      display: block;
      width: 100%;
    `}
    ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50px;
    `}
    ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
    ${({ variant, disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: auto;
      :hover {
        background-color: ${({ theme }) => theme.colors[variant].default};
        border: ${({ theme }) => `solid 8px ${theme.colors[variant].default}}`};
      }
    `}
`;

const Button = ({
  size = 'medium',
  children,
  onClick,
  className,
  variant = 'primary',
  block,
  rounded,
  isVisible = true,
  outlined = false,
  ...restProps
}: Props) => {
  return (
    <ButtonStyled
      size={size}
      variant={variant}
      block={block}
      rounded={rounded}
      isVisible={isVisible}
      onClick={onClick}
      outlined={outlined}
      {...restProps}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
