import Chip from '@material-ui/core/Chip';

import { Button } from 'components';

import styled from 'styled-components';

interface Position {
  marketName: string;
  market: string;
  rate: string;
  floatingRate: string;
  notional: string;
  side: 'long' | 'short';
  avgEntryPrice: string;
  marginRatio: string;
  unreleasedPnl: string;
}

const SAMPLE: Position[] = [
  {
    marketName: 'USD-lending',
    market: 'AAVE',
    rate: '26.90%',
    floatingRate: '23.20%',
    notional: 'US$23.99187',
    side: 'long',
    avgEntryPrice: '21.60%',
    marginRatio: '16.29%',
    unreleasedPnl: '53.09 USDC',
  },
  {
    marketName: 'USD-lending',
    market: 'AAVE',
    rate: '26.90%',
    floatingRate: '23.20%',
    notional: 'US$23.99187',
    side: 'long',
    avgEntryPrice: '21.60%',
    marginRatio: '16.29%',
    unreleasedPnl: '53.09 USDC',
  },
  {
    marketName: 'USD-lending',
    market: 'AAVE',
    rate: '26.90%',
    floatingRate: '23.20%',
    notional: 'US$23.99187',
    side: 'long',
    avgEntryPrice: '21.60%',
    marginRatio: '16.29%',
    unreleasedPnl: '53.09 USDC',
  },
  {
    marketName: 'USD-lending',
    market: 'AAVE',
    rate: '26.90%',
    floatingRate: '23.20%',
    notional: 'US$23.99187',
    side: 'long',
    avgEntryPrice: '21.60%',
    marginRatio: '16.29%',
    unreleasedPnl: '53.09 USDC',
  },
];

const StyledChip = styled(Chip)`
  border-radius: 8px !important;
  background-color: ${({ theme, label }) =>
    label !== 'long'
      ? theme.colors.danger.base
      : theme.colors.success.base} !important;
`;

const StyledTable = styled.table`
  border-collapse: collapse;

  tr {
    height: 52px;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 22px;
  }

  thead tr {
    background: ${({ theme }) => theme.colors.offBlack.ash};
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    text-align: left;
    font-weight: 700;
  }

  th:first-child {
    border-radius: 6px 0 0 6px;
    padding: 3px;
  }

  th:last-child {
    border-radius: 0 6px 6px 0;
    padding: 3px;
  }

  button {
    height: 32px;
  }
`;

export const OpenPositionsTable = ({
  positions,
}: {
  positions: Position[];
}) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th key={0}>Market</th>
          <th key={1}>Market Rate</th>
          <th key={2}>Floating Rate</th>
          <th key={3}>Notional</th>
          <th key={4}>Side</th>
          <th key={5}>Avg. Entry Price</th>
          <th key={6}>Margin Ratio</th>
          <th key={7}>Unreleased Pnl</th>
          <th key={8}></th>
        </tr>
      </thead>
      <tbody>
        {positions.map((item, index) => (
          <tr key={index}>
            <td key={index}>{item.market}</td>
            <td key={index}>{item.rate}</td>
            <td key={index}>{item.floatingRate}</td>
            <td key={index}>{item.notional}</td>
            <td key={index}>
              <StyledChip label={item.side} />
            </td>
            <td key={index}>{item.avgEntryPrice}</td>
            <td key={index}>{item.marginRatio}</td>
            <td key={index}>{item.unreleasedPnl}</td>
            <td key={index}>
              <Button variant="danger" size="small" outlined={true}>
                Close
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const OpenPositionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoBannerStyled = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */

    letter-spacing: 0.25px;

    /* Gray/7 */

    color: #aeafc2;

    margin: 0 0 24px 0;
  }
`;

export const OpenPositions = () => {
  return (
    <OpenPositionsStyled>
      <h2>Open positions</h2>
      <InfoBannerStyled>
        <span>last updated 23:12:00</span>
        <span>{SAMPLE.length} Open Positions</span>
      </InfoBannerStyled>
      <OpenPositionsTable positions={SAMPLE} />
    </OpenPositionsStyled>
  );
};
