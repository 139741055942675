import { useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { BN, zero, formatPercent } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useTransaction } from 'hooks/useTransaction';

import { CurrencyInput, MarketName, Preloader, Text, Button } from 'components';

//@todo: va;idate this, but the form is the same, just in case still keep the oldone
import { useFormState } from '../../openPosition/views/Form/useForm';
import { Leverage } from './Leverage';
import { StakeButtons } from './StakeButtons';
import { StakesRatio } from './StakesRatio';
import { TotalDeposits } from './TotalDeposits';
import { useWeb3API } from 'contracts/apiHooks';

import block from 'bem-cn-lite';
import { Market, PositionKind } from 'model';
import { useQuery } from 'react-query';

import './style.scss';

const b = block('market-board');

type Props = {
  market: Market;
};

export const MarketBoard: React.FC<Props> = ({ market }) => {
  const { web3, marketAPI, busdAPI, stripsAPI } = useWeb3API();
  const { showError } = useErrorHandler(web3);
  const { data: longStakes = zero } = useQuery(['long'], () =>
    marketAPI.getLongStakes(market.id),
  );
  const { data: shortStakes = zero } = useQuery(['short'], () =>
    marketAPI.getShortStakes(market.id),
  );
  const { account, areRequestsLocked, isConnecting, connect } = useAccount();
  const {
    positionSize,
    leverage,
    collateral,
    dirty,
    errors,
    balance,
    isValid,
    isValidating,
    fee,
    handlePositionSizeChange,
    handlePositionSizeFocus,
    handlePositionSizeBlur,
    handleCollateralChange,
    handleCollateralFocus,
    handleCollateralBlur,
    handleLeverageChange,
    clearCollateral,
    clearPositionSize,
    setMaxBalance,
  } = useFormState(market);

  const { addTransaction } = useTransaction();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleFormSubmit = (positionKind: PositionKind) => {
    Promise.resolve()
      .then(() => setSubmitting(true))
      .then(() =>
        collateral && fee
          ? openPosition(
              positionKind,
              market.id,
              new BN(collateral),
              fee.feeWithSlippage,
            )
          : Promise.reject('Collateral or Fee is not provided'),
      )
      .then(tx => {
        addTransaction(
          tx,
          `Open ${positionKind} Position (Collateral: ${collateral})`,
        );
      })
      .catch(error => showError(error))
      .finally(() => setSubmitting(false));
  };

  const openPosition = (
    positionKind: PositionKind,
    marketAddress: string,
    collateral: BN,
    fee: BN,
  ) => {
    return busdAPI
      .approve(stripsAPI.address, collateral.plus(fee))
      .then(tx => {
        tx !== '' && addTransaction(tx, `Approving BUSD`);
      })
      .then(() =>
        stripsAPI.openPosition(
          positionKind,
          marketAddress,
          collateral,
          leverage,
        ),
      );
  };

  const ConnectButton = () => (
    <Button variant="primary" onClick={connect} disabled={isConnecting} rounded>
      {isConnecting ? <Preloader /> : 'Connect'}
    </Button>
  );

  return (
    <div className={b()}>
      <div className={b('header')}>
        <MarketName marketID={market.id} withAssetSymbol />
        <TotalDeposits total={longStakes.plus(shortStakes)} />
      </div>
      <div className={b('ratio')}>
        <Text color="primary">APY: {formatPercent(market.fixedRate)}%</Text>
        <StakesRatio longStakes={shortStakes} shortStakes={longStakes} />
      </div>
      <div className={b('form')}>
        <CurrencyInput.Component
          label="Position Size"
          value={positionSize?.toString() ?? null}
          onChange={handlePositionSizeChange}
          rightPart={<CurrencyInput.Simple currency="busd" />}
          onFocus={handlePositionSizeFocus}
          onBlur={handlePositionSizeBlur}
          disabled={!account}
          onClear={clearPositionSize}
          error={dirty ? errors.positionSize : undefined}
        />
        <Leverage
          error={dirty ? errors.leverage : undefined}
          value={leverage}
          onChange={handleLeverageChange}
          disabled={!account}
        />
        <CurrencyInput.Component
          label="Collateral"
          value={collateral?.toString() ?? null}
          error={dirty ? errors.collateral : undefined}
          onChange={handleCollateralChange}
          onFocus={handleCollateralFocus}
          onBlur={handleCollateralBlur}
          disabled={!account}
          onClear={clearCollateral}
          rightPart={
            <CurrencyInput.Limiter
              value={balance}
              onButtonClick={setMaxBalance}
              currency="busd"
              disabled={!market}
            />
          }
        />
      </div>
      <div className={b('footer')}>
        <Text color="secondary" align="center">
          Will the APY go higher or lower?
        </Text>
        {areRequestsLocked ? (
          <ConnectButton />
        ) : isSubmitting ? (
          <CircularProgress size={20} />
        ) : (
          <StakeButtons
            onSubmit={handleFormSubmit}
            disabled={!account || !isValid || isValidating}
            submitting={isSubmitting}
          />
        )}
      </div>
    </div>
  );
};
