import React, { useState } from 'react';

import { BN } from 'utils/bigNumber';

import { useAccount } from 'hooks/useAccount';
import useModal from 'hooks/useModal';
import { useTransaction } from 'hooks/useTransaction';

import {
  Button,
  Preloader,
  CurrencyInput,
  Select,
  MarketName,
  Text,
} from 'components';
import Modal from 'components/Modal';

import { Cards } from './Cards';
import { useForm } from './useForm';
import { useWeb3API } from 'contracts/apiHooks/useWeb3API';

import block from 'bem-cn-lite';
import { useMarkets } from 'graphqlAPI';
import { Market } from 'model';
import { useSnackbar } from 'notistack';

import './style.scss';

const b = block('add-liquidity-modal');

function AddLiquidityModal() {
  const [isStaking, setStaking] = useState(false);
  const { busdAPI, marketAPI } = useWeb3API();
  const { markets } = useMarkets();
  const { connect, areRequestsLocked } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { pop } = useModal();
  const { addTransaction } = useTransaction();

  const {
    market,
    liquidity,
    balance,
    isValid,
    error,
    dirty,
    setMarket,
    changeLiquidity,
    setMaxBalance,
    clearAmount,
    makeSubmitHandler,
  } = useForm();

  const stake = (marketAddress: string, liquidity: BN) =>
    Promise.resolve()
      .then(() => setStaking(true))
      .then(() => busdAPI.approve(marketAddress, liquidity))
      .then(tx => {
        tx !== '' && addTransaction(tx, `Approving BUSD`);
      })
      .then(() => marketAPI.stake(marketAddress, liquidity))
      .then(tx => {
        addTransaction(tx, `Add liquidity`);
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setStaking(false))
      .finally(pop);

  const handleFormSubmit = makeSubmitHandler(() => {
    if (areRequestsLocked) {
      return connect();
    }

    if (liquidity && market?.id) {
      return stake(market.id, liquidity);
    }
  });

  const buttonContent = areRequestsLocked ? 'Connect' : 'Add liquidity';

  return (
    <Modal title="Add liquidity" size="medium">
      <form className={b()} onSubmit={handleFormSubmit}>
        <Select.Component<Market>
          activeOption={market}
          options={markets}
          onSelect={setMarket}
          Option={({ option }) =>
            option && <MarketName marketID={option.id} withAssetSymbol />
          }
          ActiveOption={({ option }) =>
            option ? (
              <MarketName marketID={option.id} withAssetSymbol />
            ) : (
              <Text color="secondary">Select Market</Text>
            )
          }
        />
        <div className={b('input')}>
          <CurrencyInput.Component
            label="Liquidity"
            value={liquidity?.toString() ?? null}
            onChange={changeLiquidity}
            disabled={!market}
            error={dirty ? error : undefined}
            rightPart={
              <CurrencyInput.Limiter
                currency="busd"
                value={balance}
                disabled={
                  !market || (!!liquidity && balance?.isEqualTo(liquidity))
                }
                onButtonClick={setMaxBalance}
              />
            }
            onClear={clearAmount}
          />
        </div>
        <div className={b('cards')}>
          <Cards
            liquidity={liquidity}
            marketLiquidity={market?.liquidity ?? null}
          />
        </div>
        <Button
          className={b('add-liquidity-button')}
          variant="primary"
          type="submit"
          disabled={!isValid || isStaking}
        >
          {isStaking ? <Preloader /> : buttonContent}
        </Button>
      </form>
    </Modal>
  );
}

const Component = React.memo(AddLiquidityModal);
export { Component as AddLiquidityModal };
