import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { NoEthereumProviderError } from '@web3-react/injected-connector';
import { UserRejectedRequestError } from '@web3-react/walletconnect-connector';

import { injected } from '../connectors';
import { AccountContext, Status } from '../contexts/AccountContext';

import { config } from 'config';
import { useSnackbar } from 'notistack';
import { numberToHex } from 'web3-utils';

const ethereum = window.ethereum;
const WALLET_ERC_APPROVED = 'WALLET_ERC_APPROVED';

export const AccountProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { account, deactivate, active, activate, error } = useWeb3React();
  const [status, setStatus] = useState<Status>('disconnected');
  const [isConnecting, setConnecting] = useState(false);
  const [approvedState, setApprovedState] = useState<object>({});
  const firstRender = useRef(true);

  const updateApproved = () => {
    let approvedStore: Object = {};
    const isApprovedJson = localStorage.getItem(WALLET_ERC_APPROVED);
    if (isApprovedJson) {
      approvedStore = JSON.parse(isApprovedJson);
    }
    setApprovedState(approvedStore);
  };

  useEffect(() => {
    if (error instanceof NoEthereumProviderError) {
      setStatus('no-provider');
      enqueueSnackbar(
        'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.',
        {
          variant: 'error',
        },
      );
    } else if (error instanceof UnsupportedChainIdError) {
      setStatus('wrong-network');
      enqueueSnackbar('Unsupported network', {
        variant: 'error',
      });
    } else if (!account) {
      setStatus('disconnected');
      !firstRender.current &&
        enqueueSnackbar('Wallet disconnected', {
          variant: 'warning',
        });
    } else {
      updateApproved();
      setStatus('connected');
      enqueueSnackbar('Wallet connected', {
        variant: 'success',
      });
    }
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [account, error]);

  /**
   * Adds a network saved in the config file and throws an error if the provider is not connected to the current chain
   */
  const addNetwork = useCallback(async () => {
    setConnecting(true);

    try {
      if (ethereum?.isConnected()) {
        return ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              rpcUrls: [config.network.url],
              chainId: numberToHex(config.chainID),
              chainName: config.network.networkName,
              nativeCurrency: {
                name: config.network.symbol,
                symbol: config.network.symbol,
                decimals: 18,
              },
              blockExplorerUrls: [config.network.explorerUrl],
            },
          ],
        });
      } else {
        throw new Error('No Ethereum provider');
      }
    } catch (error: any) {
      throw error;
    } finally {
      setConnecting(false);
    }
  }, []);

  /**
   * Adds a network and activates the selected connector
   * @param connector selected connector (Metamask or WalletConnect)
   */
  const addNetworkAndConnect = async (connector: AbstractConnector) => {
    try {
      await addNetwork();
      await activate(connector, undefined, true);
    } catch (error: any) {
      enqueueSnackbar('Switch to a correct network', {
        variant: 'error',
      });
    }
  };

  /**
   * First tries to activate the selected connector. If not successful, it checks whether an incorrect network
   * is selected. If not, it adds it and tries activating the selector again with the correct network. If the user
   * rejects activating the selector, we just show the "Wallet connection rejected" error, otherwise we show a
   * generic error.
   */
  const connect = useCallback(
    async (connector: AbstractConnector = injected) => {
      setConnecting(true);
      try {
        // Activate the connector
        await activate(connector, undefined, true);
      } catch (error: any) {
        // If wrong network, add the correct network and try activating the connector again
        if (error instanceof UnsupportedChainIdError) {
          await addNetworkAndConnect(connector);
        }
        // If the user rejected activating the connector
        else if (error instanceof UserRejectedRequestError) {
          enqueueSnackbar('Wallet connection rejected', { variant: 'warning' });
        }
        // Generic error
        else {
          error.code === -32002
            ? enqueueSnackbar(error.message, { variant: 'warning' })
            : enqueueSnackbar(error.message, { variant: 'error' });
        }
      } finally {
        setConnecting(false);
      }
    },
    [addNetwork, enqueueSnackbar, account, status],
  );

  const disconnect = useCallback(() => {
    deactivate();
  }, []);

  const isTokenApproved = useCallback(
    (wallet: string, token: string): boolean => {
      return (
        approvedState && approvedState[wallet] && approvedState[wallet][token]
      );
    },
    [approvedState],
  );

  const updateTokenApproved = useCallback(
    (wallet: string, token: string, value: boolean) => {
      let newApprovedState = { ...approvedState };

      let newWalletApproved = newApprovedState[wallet]
        ? { ...newApprovedState[wallet] }
        : {};

      newWalletApproved[token] = value;
      newApprovedState[wallet] = newWalletApproved;

      setApprovedState(newApprovedState);
      localStorage.setItem(
        WALLET_ERC_APPROVED,
        JSON.stringify(newApprovedState),
      );
    },
    [approvedState],
  );

  const value = useMemo(
    () => ({
      account,
      status,
      isConnecting,
      areRequestsLocked: !active,
      connect,
      disconnect,
      isTokenApproved,
      updateTokenApproved,
      approvedState,
    }),
    [
      account,
      connect,
      disconnect,
      active,
      isConnecting,
      status,
      isTokenApproved,
      updateTokenApproved,
      approvedState,
    ],
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};
