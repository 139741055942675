import React from 'react';

import { Currency } from 'components';

import block from 'bem-cn-lite';
import { CurrencyID } from 'model';

import './style.scss';

const b = block('simple-right-part');

type Props = {
  currency: CurrencyID;
};

function Simple({ currency }: Props) {
  return (
    <div className={b()}>
      <Currency.Component currency={currency} />
    </div>
  );
}

export const Component = React.memo(Simple);
export { Component as Simple };
