import styled from 'styled-components';

const MarketSelectorStyled = styled.div`
  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.9999998807907104px;
    text-align: left;
    margin: 5px 5px 5px 0;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */

    letter-spacing: 0.25px;

    /* Monochrome/Line */

    color: ${({ theme }) => theme.colors.offBlack.line};

    /* Inside Auto Layout */
    margin: 0px 0px;
  }
`;

export const MarketSelector = () => {
  return (
    <MarketSelectorStyled className="market-selector">
      <h2>USDC lending</h2>
      <span>AAVE</span>
    </MarketSelectorStyled>
  );
};
