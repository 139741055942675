import React from 'react';

import { BN } from 'utils/bigNumber';
import { formatCurrency } from 'utils/formatters';

import { Text, Button, Currency } from 'components';

import block from 'bem-cn-lite';
import { CurrencyID } from 'model';

import './style.scss';

const b = block('limiter-right-part');

type Props = {
  currency: CurrencyID;
  value: BN | null;
  label?: string;
  onButtonClick(): void;
  disabled?: boolean;
};

function Limiter(props: Props) {
  const {
    currency,
    value,
    label = 'Balance',
    onButtonClick,
    disabled = false,
  } = props;
  return (
    <div className={b()}>
      <div className={b('top')}>
        <Text typography="label-m" color="secondary">
          {label} {value ? formatCurrency(value.toNumber()) : '—'}
        </Text>
      </div>
      <div className={b('bottom')}>
        <div className={b('max-button')}>
          <Button
            type="button"
            size="small"
            onClick={onButtonClick}
            disabled={value === null || disabled}
          >
            MAX
          </Button>
        </div>
        <Currency.Component currency={currency} />
      </div>
    </div>
  );
}

const Component = React.memo(Limiter);

export { Component as Limiter };
