import React from 'react';

import { BN, format } from 'utils/bigNumber';
import { block } from 'utils/classname';

import useModal from 'hooks/useModal';

import { Button, ExpansionPanel, NumberTooltip, Text } from 'components';

import { RemoveLiquidityModal } from '../../RemoveLiquidityModal';

import { Market } from 'model';

import './style.scss';

export type MarketWithStakes = Market & {
  stakedLiquidity: BN;
  slpTotal: BN;
  initialStakedPnl: BN;
};

const b = block('your-liquidity-ep-content');

export function makeExpansionPanelContent() {
  function ExpansionPanelContent({
    data,
  }: ExpansionPanel.ContentProps<MarketWithStakes>) {
    const poolShare = data.slpTotal.div(data.totalSupply).times(100);
    const stakePNL = poolShare.times(
      data.stakedPNL.minus(data.initialStakedPnl),
    );
    const rows = [
      [
        'Staked liquidity:',
        format(data.stakedLiquidity, 2),
        data.stakedLiquidity.toString(),
      ],
      ['SLP Total:', format(data.slpTotal, 2), data.slpTotal.toString()],
      ['Pool share:', poolShare.isFinite() ? `${format(poolShare, 2)}%` : '-'],
      [
        'Staked profit:',
        !stakePNL.isZero() ? format(stakePNL, 2) : '-',
        stakePNL.toString(),
      ],
    ];

    const modal = useModal();

    return (
      <div className={b('content')}>
        <div className={b('data')}>
          {rows.map(([label, shortValue, fullValue], index) => (
            <div className={b('row')} key={index}>
              <div className={b('label')}>{label}</div>
              <div className={b('value')}>
                {fullValue ? (
                  <NumberTooltip.Component
                    title={<Text typography="text-s">{fullValue}</Text>}
                    placement="left"
                  >
                    <span>{shortValue}</span>
                  </NumberTooltip.Component>
                ) : (
                  shortValue
                )}
              </div>
            </div>
          ))}
        </div>
        <Button
          className={b('remove-button')}
          onClick={() => modal.present(<RemoveLiquidityModal market={data} />)}
        >
          Remove
        </Button>
      </div>
    );
  }

  return React.memo(ExpansionPanelContent);
}
