import { useEffect, useState } from 'react';

type Params = { [key: string]: string };

type WindowProps = {
  url: string;
  width: number;
  height: number;
};

type Props = {
  width?: number;
  height?: number;
  onParamsGet?(params: Params): void;
};

const snakeToCamelCase = (str: string) => {
  return str.replace(/_(.)/gi, (_, b) => b.toUpperCase());
};

const getAllQueryParams = (queryString: URLSearchParams): Params =>
  Array.from(queryString.keys()).reduce((acc, key) => {
    const value = queryString.get(key);
    acc[snakeToCamelCase(key)] = value ?? '';
    return acc;
  }, {});

const createPopup = ({ url, height, width }: WindowProps) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  return window.open(
    url,
    '',
    `width=${width},height=${height},left=${left},top=${top}`,
  );
};

export const useOpenWindow = ({
  width = 600,
  height = 600,
  onParamsGet,
}: Props = {}) => {
  const [childWindow, setChildWindow] = useState<Window | null>(null);

  const open = (url: string) => {
    if (childWindow === null) {
      setChildWindow(createPopup({ url, width, height }));
    }
  };

  const close = () => {
    if (childWindow) {
      childWindow.close();
    }
  };

  useEffect(() => {
    if (childWindow && onParamsGet) {
      const handleMessage = (event: MessageEvent<string>) => {
        if (event.source === childWindow) {
          const params = getAllQueryParams(new URLSearchParams(event.data));
          onParamsGet(params);
        }
      };

      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childWindow]);

  useEffect(() => {
    if (childWindow) {
      const timer = setInterval(function () {
        if (childWindow.closed) {
          clearInterval(timer);
          setChildWindow(null);
        }
      }, 1000);

      return () => {
        childWindow.close();
        clearInterval(timer);
      };
    }
  }, [childWindow]);

  return {
    childWindow,
    open,
    close,
  } as const;
};
