import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react';

import { BN } from 'utils/bigNumber';

type FormState = {
  amount: BN | null;
  defaultAmount: BN | null;
};

type Errors = Partial<{
  [error in keyof FormState]: string;
}>;

const ZERO = new BN(0);

const validateAmount = (amount: BN | null, maxAmount: BN) => {
  if (amount === null || amount.isLessThanOrEqualTo(ZERO)) {
    return 'Field is required';
  }
  if (maxAmount && amount.isGreaterThan(maxAmount)) {
    return 'Max amount exceeded';
  }
  return undefined;
};

export function useFormState() {
  const [formState, setFormState] = useState<FormState & { isDirty: boolean }>({
    amount: null,
    defaultAmount: null,
    isDirty: false,
  });

  const [errors, setErrors] = useState<Errors>({});
  const isValid = !Object.values(errors).some(Boolean);
  const createSubmitHandler =
    (onSubmit: (formState: FormState) => void) => (e: React.FormEvent) => {
      e.preventDefault();
      isValid && onSubmit(formState);
    };

  const handleAmountChange = useCallback((value: string | null) => {
    setFormState(prevState => ({
      ...prevState,
      amount: value ? new BN(value) : null,
      isDirty: calcIsDirty({
        ...prevState,
        amount: value ? new BN(value) : null,
      }),
    }));
  }, []);

  const clearAmount = useCallback(
    () =>
      setFormState(prevState => ({
        ...prevState,
        amount: null,
      })),
    [],
  );

  const maxAmount = useMemo(() => {
    return new BN(1000);
  }, []);

  useEffect(() => {
    setErrors(prevErrors => ({
      ...prevErrors,
      amount: validateAmount(formState.amount, maxAmount),
    }));
  }, [formState.amount]);

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      isDirty: calcIsDirty(formState),
    }));
  }, [formState.amount, formState.defaultAmount]);

  useLayoutEffect(() => {
    if (maxAmount && formState.amount?.isGreaterThan(maxAmount)) {
      setFormState(prevState => ({
        ...prevState,
        amount: maxAmount,
      }));
    }
  }, [formState.amount, maxAmount]);

  return {
    isDirty: formState.isDirty,
    isValid,
    errors,
    amount: formState.amount,
    defaultAmount: formState.defaultAmount,
    maxAmount,
    clearAmount,
    handleAmountChange,
    createSubmitHandler,
  } as const;
}

function calcIsDirty(formState: FormState) {
  let isDirty: boolean;
  if (formState.defaultAmount && formState.amount) {
    isDirty = !formState.defaultAmount.isEqualTo(formState.amount);
  } else {
    isDirty = formState.defaultAmount !== formState.amount;
  }
  return isDirty;
}
