import { BN } from 'utils/bigNumber';

export type PositionKind = 'long' | 'short';
export type CurrencyID = 'busd' | 'slp' | 'sip' | 'usdc';

export type Market = {
  id: string;
  slpAddress: string;
  assetSymbol: string;
  liquidity: BN;
  fixedRate: BN;
  floatingRate: BN;
  stakedPNL: BN;
  totalSupply: BN;
};

export type Position = {
  id: string;
  marketAddress: string;
  fixedRate: BN;
  floatingRate: BN;
  positionIndex: number;
  positionType: PositionKind;
  notional: BN;
  collateral: BN;
  leverage: number;
};

export type MarketStake = {
  collateralTotal: BN;
  initialStakedPnl: BN;
  slpTotal: BN;
};

export type InsuranceStake = {
  collateralTotal: BN;
  initialStakedPnl: BN;
  sipTotal: BN;
};

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  BSC = 56,
  BSC_TESTNET = 97,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  LOCAL = 31337,
}
