import { useCallback, useEffect, useRef } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { BN, to256, from256, EBNToBN } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';
import { calcGas } from 'utils/gas';

import { useErrorHandler } from 'hooks/useErrorHandler';

import { IrsMarket, IrsMarket__factory } from '../types';

export type MarketAPI = ReturnType<typeof useMarketAPI>;

function createContract(address: string, web3: Web3Provider) {
  return getContract<IrsMarket>(IrsMarket__factory, address, web3);
}

export function useMarketAPI(web3: Web3Provider, account: string) {
  const { showError } = useErrorHandler(web3);
  const saveAccount = useRef(account);

  useEffect(() => {
    saveAccount.current = account;
  }, [account]);

  const stake = useCallback(
    async (market: string, amount: BN) => {
      const contract = createContract(market, web3);

      try {
        const gasLimitEstimated = await contract.estimateGas.stake(
          to256(amount).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.stake(to256(amount).toString(), {
          gasLimit,
        });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  const unstake = useCallback(
    async (market: string, amount: BN) => {
      const contract = createContract(market, web3);

      try {
        const gasLimitEstimated = await contract.estimateGas.unstake(
          to256(amount).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.unstake(to256(amount).toString(), {
          gasLimit,
        });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  const calcUnstakeProfit = useCallback(
    async (market: string, account: string, amount: BN) => {
      const contract = createContract(market, web3);

      try {
        const ret = await contract.calcUnstakeProfit(
          account,
          to256(amount).toString(),
        );
        return convertUnstackeProfit({
          profit: ret.profit.toString(),
          unstake_amount: ret.unstake_amount.toString(),
          fee: ret.fee.toString(),
          periodLeft: ret.periodLeft.toString(),
        });
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  const maxNotionalCached = useCallback(
    async (market: string) => {
      const contract = createContract(market, web3);

      try {
        const data = await contract.maxNotionalCached();
        return { ...data, maxNotional: from256(EBNToBN(data[0])) };
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  const getPricesCached = useCallback(
    async (market: string) => {
      const contract = createContract(market, web3);

      try {
        const ret = await contract.getPricesCached();
        return ret;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  const getLongStakes = useCallback(
    async (market: string) => {
      const contract = createContract(market, web3);

      try {
        const ret = await contract.getLongs();
        return from256(EBNToBN(ret));
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  const getShortStakes = useCallback(
    async (market: string) => {
      const contract = createContract(market, web3);

      try {
        const ret = await contract.getShorts();
        return from256(EBNToBN(ret));
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3],
  );

  return {
    stake,
    unstake,
    maxNotionalCached,
    getPricesCached,
    calcUnstakeProfit,
    getShortStakes,
    getLongStakes,
  } as const;
}

export type MarketUnstackeProfitInput = {
  profit: string;
  unstake_amount: string;
  fee: string;
  periodLeft: string;
};

export type MarketUnstackeProfit = {
  profit: BN;
  fee: BN;
  unstakeAmount: BN;
  periodLeft: number;
};

function convertUnstackeProfit(
  params: MarketUnstackeProfitInput,
): MarketUnstackeProfit {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      const newKey = key === 'unstake_amount' ? 'unstakeAmount' : key;
      return [newKey, key === 'periodLeft' ? Number(value) : from256(value)];
    }),
  ) as MarketUnstackeProfit;
}
