import React from 'react';

import MUITooltip from '@material-ui/core/Tooltip';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  children: React.ReactNode;
  fontSize?: string;
} & Omit<TooltipProps, 'children'>;

const Tooltip = withStyles(() => ({
  tooltip: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
}))(MUITooltip);

const NumberTooltip: React.FC<Props> = props => {
  const { children, fontSize, ...restProps } = props;
  return (
    <Tooltip placement="left" arrow {...restProps}>
      <div>{children}</div>
    </Tooltip>
  );
};

export const Component = React.memo(NumberTooltip);
export type { Props as TooltipProps };
