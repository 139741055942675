import { useCallback, useMemo, useState, useEffect } from 'react';

import { TransactionReceipt } from '@ethersproject/providers';

import { useWeb3 } from 'hooks/useWeb3';

import { TransactionContext } from '../contexts/TransactionContext';
import { retry, RetryableError } from '../utils/retry';

import { useSnackbar } from 'notistack';

export const TransactionProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [transactions, setTransactions] = useState<object>({});
  const { web3 } = useWeb3();

  const addTransaction = useCallback(
    (tx, summary) => {
      const newTrans = { ...transactions };
      newTrans[tx] = { summary };
      setTransactions(newTrans);
    },
    [transactions],
  );

  const finishTransaction = useCallback(
    (tx, receipt: TransactionReceipt) => {
      console.log('Receipt:', receipt);
      if (receipt.status) {
        enqueueSnackbar(`${transactions[tx].summary} : Success!`, {
          variant: 'success',
        });
      } else {
        const error = `Transaction is reverted while executing a contract function. TxHash: ${tx}`;
        enqueueSnackbar(`${transactions[tx].summary} : Failed (${error})`, {
          variant: 'error',
        });
      }
      const newTrans = { ...transactions };
      delete newTrans[tx];
      setTransactions(newTrans);
    },
    [transactions],
  );

  const getReceipt = useCallback(
    (hash: string) => {
      if (!web3) throw new Error('No web3');
      return retry(() =>
        web3.getTransactionReceipt(hash).then(receipt => {
          if (receipt === null) {
            console.debug('Retrying for hash', hash);
            throw new RetryableError();
          }
          return receipt;
        }),
      );
    },
    [web3],
  );

  useEffect(() => {
    if (!web3) return;

    const cancels = Object.keys(transactions).map(hash => {
      const { promise, cancel } = getReceipt(hash);
      promise
        .then(receipt => {
          if (receipt) finishTransaction(hash, receipt);
        })
        .catch(error => {
          if (!error.isCancelledError) {
            console.error(`Failed to check transaction hash: ${hash}`, error);
            setTransactions({ ...transactions });
          }
        });
      return cancel;
    });

    return () => {
      cancels.forEach(cancel => cancel());
    };
  }, [web3, transactions, getReceipt]);

  const value = useMemo(
    () => ({
      transactions,
      addTransaction,
      finishTransaction,
    }),
    [transactions, addTransaction, finishTransaction],
  );

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
};
