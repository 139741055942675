import { useEffect, useState, useCallback, useRef } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { to256, from256, EBNToBN } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';
import { calcGas } from 'utils/gas';

import { useErrorHandler } from '../../hooks/useErrorHandler';
import type { Strips } from '../types';
import { Strips__factory } from '../types';

import BN from 'bignumber.js';

export type StripsAPI = ReturnType<typeof useStripsAPI>;

export type CalcFeeWithSlippageData = {
  feeWithSlippage: BN;
  whatIfprice: BN;
};

export type RealTimeData = {
  priceData: Record<string, { fixedRate: BN; floatingRate: BN }>;
  pnlData: { pnl: BN; marginRatio: BN; market: string };
  positions: {
    id: string;
    marketAddress: string;
    fixedRate: BN;
    floatingRate: BN;
    positionType: 'long' | 'short';
    notional: BN;
    collateral: BN;
    leverage: number;
    initialPrice: BN;
  }[];
  marketStakedAmount: BN;
  insuranceStakedAmount: BN;
};

export type CloseParamsInput = {
  minimumMargin: string;
  pnl: string;
  leftMargin: string;
  fee: string;
};

export type CloseParams = Record<keyof CloseParamsInput, BN>;

function createContract(address: string, web3: Web3Provider, account: string) {
  return getContract<Strips>(Strips__factory, address, web3, account);
}

export function useStripsAPI(
  web3: Web3Provider,
  address: string,
  account: string,
) {
  const { showError } = useErrorHandler(web3);
  const saveAccount = useRef(account);

  const [contract, setContract] = useState<Strips>(
    createContract(address, web3, account),
  );

  useEffect(() => {
    saveAccount.current = account;
  }, [account]);

  useEffect(() => {
    createContract(address, web3, account);
  }, [web3, address]);

  const getRealTimeData = useCallback(
    async (account: string) => {
      try {
        const ret = await contract.getRealTimeData(account);
        //@todo: this should be updated by whoever updates the widget.
        //@ts-ignore
        return convertRealTimeData(ret);
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const calcFee = useCallback(
    async (
      market: string,
      collateral: BN,
      leverage: number,
      positionLength: 'long' | 'short',
    ) => {
      try {
        const ret = await contract.calcFeeWithSlippage(
          market,
          to256(collateral.multipliedBy(leverage)).toString(),
          positionLength === 'long',
        );
        return {
          feeWithSlippage: from256(EBNToBN(ret)),
          whatIfprice: from256('0'), //@todo: restore if we get whatIfprice from SC
        } as CalcFeeWithSlippageData;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const profitEarned = useCallback(
    (_account: string) => {
      // @todo: restore when contract is available
      return from256('0');
      // return contract.methods
      //   .profitEarned(_account)
      //   .call()
      //   .then((data) => {
      //     return from256(data[0]);
      //   })
      //   .catch(async (error) => {
      //     console.log(error.stack);
      //     await showError(error);
      //     return null;
      //   });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const openPosition = useCallback(
    async (
      position: 'short' | 'long',
      market: string,
      collateral: BN,
      leverage: number,
    ) => {
      const methodEstimate =
        position === 'short'
          ? contract.estimateGas.sell
          : contract.estimateGas.buy;

      const method = position === 'short' ? contract.sell : contract.buy;
      try {
        const gasLimitEstimated = await methodEstimate(
          market,
          to256(collateral).toString(),
          leverage,
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await method(
          market,
          to256(collateral).toString(),
          leverage,
          {
            gasLimit,
          },
        );
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const calcCloseParams = useCallback(
    async (market: string, account: string, closeRatio: BN) => {
      try {
        const ret = await contract.calcCloseParams(
          market,
          account,
          to256(closeRatio).toString(),
        );
        //@todo: this should be updated by whoever updates the widget.
        // it is no longer necessary
        return convertCloseParams({
          minimumMargin: ret.minimumMargin.toString(),
          pnl: ret.pnl.toString(),
          leftMargin: ret.leftMargin.toString(),
          fee: ret.fee.toString(),
        });
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const closePositionPartially = useCallback(
    async (market: string, closeRatio: BN) => {
      try {
        const gasLimitEstimated = await contract.estimateGas.close(
          market,
          to256(closeRatio).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.close(market, to256(closeRatio).toString(), {
          gasLimit,
        });
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const addCollateral = useCallback(
    async (market: string, collateral: BN) => {
      try {
        const gasLimitEstimated = await contract.estimateGas.addCollateral(
          market,
          to256(collateral).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.addCollateral(
          market,
          to256(collateral).toString(),
          {
            gasLimit,
          },
        );
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  const removeCollateral = useCallback(
    async (market: string, collateral: BN) => {
      try {
        const gasLimitEstimated = await contract.estimateGas.removeCollateral(
          market,
          to256(collateral).toString(),
        );
        const gasLimit = calcGas(gasLimitEstimated);

        const tx = await contract.removeCollateral(
          market,
          to256(collateral).toString(),
          {
            gasLimit,
          },
        );
        return tx.hash;
      } catch (error: any) {
        showError(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract],
  );

  return {
    address,
    calcFee,
    profitEarned,
    openPosition,
    closePositionPartially,
    calcCloseParams,
    getRealTimeData,
    addCollateral,
    removeCollateral,
  } as const;
}

function convertRealTimeData([priceData, pnlData]: [
  [string, string, string, boolean][],
  [string, string, string][],
]): RealTimeData {
  return {
    priceData: priceData.reduce<
      Record<string, { fixedRate: BN; floatingRate: BN }>
    >((acc, [marketAdders, fixedPrice, floatingPrice]) => {
      acc[marketAdders.toLowerCase()] = {
        fixedRate: from256(fixedPrice),
        floatingRate: from256(floatingPrice),
      };
      return acc;
    }, {}),
    pnlData: {
      market: pnlData[0][0],
      pnl: from256(pnlData[0][1]),
      marginRatio: from256(pnlData[0][2]),
    },
    marketStakedAmount: from256('0'),
    // @todo: restore when contract is available
    insuranceStakedAmount: from256('0'),
    // @todo: research if we can pack more values somehow
    positions: [],
  };
}

function convertCloseParams(params: CloseParamsInput): CloseParams {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, from256(value)]),
  ) as CloseParams;
}
