import React from 'react';

import Box from '@material-ui/core/Box';

import { formatCurrency } from 'utils/formatters';

import { Text } from '../Text';
import { Button, Currency } from '../index';

import debounce from 'awesome-debounce-promise';
import { CurrencyID } from 'model';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

type Props = {
  value?: string | null;
  currency: CurrencyID;
  onChange?: (value: string | null) => void;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string | null;
  label?: string;
  readOnly?: boolean;
  rightPart?: JSX.Element;
  debounceTimeout?: number;
  allowNegative?: boolean;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  Exclude<
    keyof React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'type' | 'value' | 'onChange'
  >
>;

const HeaderContainer = styled(Box)`
  justify-content: space-between;
  label {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.offBlack.white};
  }

  span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: ${({ theme }) => theme.colors.offBlack.line};
  }
`;

const BodyContainer = styled(Box)`
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.offBlack.placeHold};
  border-radius: 10px;
  padding: 5px;
  height: 44px;

  button {
    margin: 0;
    border: 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: center;
    height: 26px;
    border-radius: 8px;
    padding: 3px 8px 3px 8px;
  }

  .input-container {
    flex: 1;
    padding-right: 10px;
  }

  input {
    background: transparent;
    border: 0;
    text-align: right;
    outline: none;
    width: 100%;
    color: ${({ theme }) => theme.colors.offBlack.placeHold};
  }
`;

export const CurrencyInput = (props: Props) => {
  const {
    label,
    value,
    currency,
    onChange,
    onClear,
    rightPart,
    readOnly,
    error,
    debounceTimeout,
    disabled,
    allowNegative = false,
    ...rest
  } = props;

  // const handleClearButtonClick = () => {
  //   onClear?.();
  // };

  // const changeValue = value => onChange?.(value ?? null);
  // const changeValueDebounced = debounce(changeValue, debounceTimeout ?? 0);

  // const handleValueChange = ({ value }: { value: string }) => {
  //   if (debounceTimeout) {
  //     changeValueDebounced(value);
  //   } else {
  //     changeValue(value);
  //   }
  // };

  return (
    <div className={'content'}>
      <HeaderContainer display="flex">
        <label>{label}</label>
        <span>
          {value ? formatCurrency(value) : '—'}
          {currency}
        </span>
      </HeaderContainer>
      <BodyContainer display="flex">
        <Currency.Component currency={currency} showLabel={false} />

        <Button
          size="small"
          variant="dark"
          onClick={() => {}}
          disabled={value === null || disabled}
        >
          MAX
        </Button>

        <div className={'right-part-container'}>{rightPart}</div>
        <div className={'input-container'}>
          <NumberFormat
            className={'input'}
            decimalScale={18}
            value={value === null ? '' : value}
            // onValueChange={(handleValueChange)}
            thousandSeparator
            isNumericString
            allowNegative={allowNegative}
            readOnly={readOnly}
            disabled={disabled}
            {...rest}
          />
          {/* {onClear && (
              <Button
                variant="clear"
                type="button"
                onClick={handleClearButtonClick}
                className={'clear-button'}
                isVisible={!!value && !disabled}
              >
                <FontAwesomeIcon
                  className={'clear-button-icon'}
                  icon={faTimesCircle}
                  size="1x"
                />
              </Button>
            )} */}
        </div>
      </BodyContainer>
      {!readOnly && error && (
        <Text className={'error'} typography="text-s" color="error">
          {error}
        </Text>
      )}
    </div>
  );
};

export default CurrencyInput;
