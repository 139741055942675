import { Web3Provider } from '@ethersproject/providers';

import { from256, EBNToBN } from 'utils/bigNumber';
import { getContract } from 'utils/contracts';

import { useErrorHandler } from 'hooks/useErrorHandler';

import { SLPToken, SLPToken__factory } from '../types';

import BN from 'bignumber.js';

export type SLPTokenAPI = ReturnType<typeof useSLPTokenAPI>;

export function useSLPTokenAPI(web3: Web3Provider) {
  const { showError } = useErrorHandler(web3);
  const balanceOf = async (account: string, address: string) => {
    const contract = getContract<SLPToken>(
      SLPToken__factory,
      address,
      web3,
      account,
    );

    try {
      const ret = await contract.balanceOf(account);
      const bn = EBNToBN(ret);
      return from256(bn);
    } catch (error: any) {
      showError(error);
      return new BN(0);
    }
  };

  return {
    balanceOf,
  } as const;
}
