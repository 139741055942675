import { ThemeProvider } from "styled-components";

const theme = {
    
    fontFamily: ["Open Sans", "sans-serif", "Roboto"].join(','),
    
    colors: {
      offBlack:{
        default:'#14142B',
        ash:'#262338',
        body:'#4E4B66',
        label:'#6E7191',
        placeHold:'#A0A3BD',
        line:'#D9DBE9',
        input:'#EFF0F6',
        bg:'#F1F2F4',
        white:'#FCFCFC',

      },
      
      text: '#ffffff',
      link:{color:'#546e7a'},
      primary: {
        base: '#FFD145',
        dark: '#836200',
        default: '#FFC107',
        light: '#FFE083',
        bg: '#FFF0C1',
        color:'#262338',
      },
      secondary: {
        base: '#32C8FA',
        dark: '#003CAA',
        default: '#005BD4',
        light: '#8DE9FF',
        bg: '#E3FEFF',
        color:'#262338',
      },
      success: {
        base: '#8CF6B6',
        dark: '#005637',
        default: '#008A58',
        light: '#C6F3E0',
        bg: '#DEF9E4',
        color:'#262338',
      },
      warning: {
        base: '#FFDF9A',
        dark: '#946300',
        default: '#EAAC30',
        light: '#FFE6B0',
        bg: '#FFF8E9',
        color:'#262338',
      },
      danger: {
        base: '#F6527A',
        dark: '#7E061A',
        default: '#B6142E',
        light: '#F8768A',
        bg: '#FFE9ED',
        color:'#262338',
      },
      dark: {
        base: '#6E7191',
        dark: '#6E7191',
        default: '#6E7191',
        light: '#6E7191',
        bg: '#FFE9ED',
        color:'#FCFCFC',
      },
      clear: {
        dark: 'transparent',
        default: 'transparent',
        light: 'transparent',
        bg: 'transparent',
        background: 'transparent',
        color:'#fff',
      },
    } 
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
