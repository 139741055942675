import React from 'react';

import { block } from 'utils/classname';

import { Widget } from 'components';

import { Form } from './views';

import { Position } from 'graphqlAPI';

import './style.scss';

type Props = {
  position: Position;
};

const b = block('close-position');

function ClosePosition({ position }: Props) {
  return (
    <div className={b('container')}>
      <Widget.Component className={b()}>
        <Form position={position} />
      </Widget.Component>
    </div>
  );
}

const Component = React.memo(ClosePosition);
export { Component as ClosePosition };
