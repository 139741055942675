import block from 'bem-cn-lite';
import {Text} from 'components';
import {IVestingObject} from 'contracts/apiHooks';
import {showVestingValue, VestingClaimCardClass} from '../index';

export default function VestingAvailableSTRP(props: Pick<IVestingObject, 'strpAvailable'>) {
    const bem = block(VestingClaimCardClass);
    return (
        <div className={bem('column', {'available-strp': true})}>
            <Text
                as='span'
                typography='heading-xl'
                align='center'
            >
                Available STRP
            </Text>
            <Text
                as='span'
                typography='heading-xl'
                align='center'
            >
                {showVestingValue(props.strpAvailable)}
            </Text>
        </div>
    );
}
