import * as Sentry from '@sentry/react';

import { ErrorOverlay } from './ErrorOverlay';
import Header from './Header';
import Sidebar, { SidebarNavItems } from './Sidebar';
import SocialLinks from './SocialLinks';

import block from 'bem-cn-lite';
import { config } from 'config';

import './style.scss';

interface Props {
  children?: any;
  navItems: SidebarNavItems;
}

const b = block('layout');

const Layout = ({ children, navItems }: Props) => {
  return (
    <div className={b('container')}>
      <Header className={b('header')} />
      <div className={b('main')}>
        {config.features.showSidebar ? (
          <Sidebar navItems={navItems} />
        ) : (
          <SocialLinks />
        )}
        <div className={b('main-content')}>
          {
            //@ts-ignore
            <Sentry.ErrorBoundary fallback={ErrorOverlay}>
              {children}
            </Sentry.ErrorBoundary>
          }
        </div>
      </div>
    </div>
  );
};

export { Layout };
