import { createContext, useContext } from 'react';

import { User } from 'feedbackContestAPI/api';

type FeatureStore = {
  user: User | null;
  isUserLoading: boolean;
  isUserLoaded: boolean;
  setUser: (user: User) => void;
};

export const FeatureContext = createContext<FeatureStore | null>(null);

export const useFeatureStore = () => {
  const store = useContext(FeatureContext);
  if (store) {
    return store;
  }

  throw new Error('Feature context is not provided');
};
